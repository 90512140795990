import axios from "axios";
import {API_BASE_URL, SIGNATURES} from "./APICONSTANTS";

export const getSignatures = () => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('sd-access');
            // do db call or API endpoint axios call here and return the promise.
            axios.get(API_BASE_URL + SIGNATURES, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((res) => {
                    console.log("Signatures loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get Signatures error: ", err);
                    reject("Get packages error!");
                });
        } catch (error) {
            console.error("Get Signatures error!==", error);
            reject("Get Signatures error!");
        }
    });
};