import {useEffect, useState} from 'react';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Col, Container, Row, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { setAdminInvoicesState, updateInvoice } from "../../../redux/actions/admin.invoices.actions";
import { getAccounts } from '../../../redux/actions/accounts.actions';
import { createInvoice } from '../../../redux/actions/admin.invoices.actions';
import { parse, isDate } from "date-fns";

export const NewEditInvoiceForm = (props) => {
    const dispatch = useDispatch();

    const [selectedAccountID, setSelectedAccountID] = useState('');
    const [searchAccount, setSearchAccount] = useState('');
    const accounts = useSelector (state => state.accounts.accountsList);

    const parseDateString = (value, originalValue) => {
        const parsedDate = isDate(originalValue)
            ? originalValue
            : parse(originalValue, "MM/dd/yyyy", new Date());

        return parsedDate;
    }

    const updateSearchResult = (e) => {
        dispatch(getAccounts({
            page: 1,
            limit: 10,
            filter: searchAccount,
            order: null,
            sort: null
        }));
    };

    const selectAccount = (e, setFieldValue) => {
        setFieldValue('fullName', e.value.fullName);
        setFieldValue('email', e.value.email);
        setSelectedAccountID(e.value.id);
    };

    const onChangeSearchCustomer = (e, setFieldValue) => {
        setSelectedAccountID('');
        setFieldValue('fullName', '');
        setFieldValue('email', '');
        setSearchAccount(e.value);
    };

    const initialValues = {
        fullName: props.isEditInvoice ? props.editInvoiceData.customerName : '',
        email: '',
        amount: props.isEditInvoice ? props.editInvoiceData.total : '',
        dueDate: props.isEditInvoice ? new Date(props.editInvoiceData.due_date).toLocaleDateString() : '',
    };

    useEffect(() => {
        if (props.isEditInvoice) {
            setSelectedAccountID(props.editInvoiceData.owner_account_id);
            setSearchAccount(props.editInvoiceData.customerName);
        }
    }, []);

    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required("Please search and select the customer"),
        amount: Yup
            .number()
            .required(),
        dueDate: Yup.date().transform(parseDateString)
            .typeError("Please enter a valid due date. Example: MM/DD/YYYY")
            .required("Please enter a valid due date. Example: MM/DD/YYYY"),
    });

    const itemTemplate = (item) => {
        return (
            <div className="account-item">
                {item.fullName} <small>[{item.email}]</small>
            </div>
        );
    };

    const onFormSubmit = (values, formikBag) => {
        const invoice = {
            due_date: new Date(values.dueDate),
            total: values.amount,
            owner: selectedAccountID,
        }
        if (props.isEditInvoice) {
            dispatch(updateInvoice(props.editInvoiceData.invoiceId, invoice));
        } else {
            dispatch(createInvoice(invoice));
        }
    };

    const onCancel = () => {
        dispatch(setAdminInvoicesState({
            newEditInvoiceModal: {
                open: false,
            }
        }));
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikBag) => onFormSubmit(values, formikBag)}
            >
            {({
                errors,
                touched,
                handleChange,
                setFieldValue
            }) => {
                return (
                    <Form>
                        <Container xs={12} md={6}>
                            <Row>
                                <Col xs={12} sm={12} className="mb-4">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-search"></i>
                                        </span>
                                        <AutoComplete inputClassName="form-control" value={searchAccount} minLength={2} suggestions={accounts} itemTemplate={itemTemplate}
                                            completeMethod={updateSearchResult} onChange={(e) => onChangeSearchCustomer(e, setFieldValue)} placeholder="Search customer" field="fullName"
                                            onSelect={(e) => selectAccount(e, setFieldValue)} autoFocus
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6}>
                                    <FormGroup>
                                        <Label for="firstName">Customer Name</Label>
                                        <Input className="form-control" name="fullName" id="fullName" readOnly type="text" tag={Field} onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} sm={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input className="form-control" name="email" id="email" readOnly type="text" tag={Field} onChange={handleChange} invalid={'email' in errors && touched.email}
                                        />
                                        <FormFeedback>
                                            {errors.email}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={6} sm={6}>
                                    <FormGroup>
                                        <Label for="amount">Amount</Label>
                                        <Input className="form-control" name="amount" id="amount" placeholder="$0.00"
                                            onChange={handleChange} type="text" invalid={'amount' in errors && touched.amount} tag={Field}
                                        />
                                        <FormFeedback>
                                            {errors.amount}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs={6} sm={6}>
                                    <FormGroup>
                                        <Label for="dueDate">Due Date</Label>
                                        <Input className="form-control" name="dueDate" id="dueDate" placeholder="--/--/----"
                                            onChange={handleChange} type="text" invalid={'dueDate' in errors && touched.dueDate} tag={Field}
                                        />
                                        <FormFeedback>
                                            {errors.dueDate}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col xs={6} sm={6} className="pr-5">
                                    <button type="button" className="cancel-button" onClick={onCancel}>Cancel</button>
                                </Col>
                                <Col xs={6} sm={6} className="d-flex flex-row-reverse pl-5">
                                    <button type="submit" className="submit-button">{props.isEditInvoice ? 'Update Invoice' : 'Create Invoice'}</button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default NewEditInvoiceForm;
