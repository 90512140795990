import Provider from "../../components/shipment/Provider";
import React, {useEffect, useState} from "react";
import {Container, Spinner} from "reactstrap";
import '../../styles/shipment.css';
import {ProgressBar} from "../../components/shipment/ProgressBar";
import {
    getShipment,
    resetShipmentRequestState,
    resetShipmentResponseState
} from "../../redux/actions/shipments.actions";
import {resetReceiverAddress, resetSenderAddress} from "../../redux/actions/addresses.actions";
import {resetQuote, resetQuoteRequestState} from "../../redux/actions/quotes.actions";
import {resetCommercialInvoice} from "../../redux/actions/commercial.invoices.action";
import {useDispatch, useSelector} from "react-redux";
import CardBooked from "../../components/shipment/CardBooked";

export const CurrentShipment = (props) => {

    const dispatch = useDispatch();
    const [shipmentId, setShipmentId] = useState('');
    const [percentage, setPercentage] = useState(90);
    const [shipment, setShipment] = useState(null);
    const currentShipment = useSelector(state => state.shipmentResponse.shipment);


    useEffect(() => {
        setShipmentId(props.match.params.id);
        dispatch(resetShipmentRequestState());
        dispatch(resetShipmentResponseState());
        dispatch(resetSenderAddress());
        dispatch(resetReceiverAddress());
        dispatch(resetQuoteRequestState());
        dispatch(resetQuote());
        dispatch(resetCommercialInvoice());
    }, []);

    useEffect(() => {
        if (shipmentId) {
            dispatch(getShipment({id: shipmentId}));
        }
    }, [shipmentId]);

    useEffect(() => {
        console.log('CURRENT SHIPMENT:', currentShipment);
        setShipment(currentShipment);
        if (currentShipment.id && currentShipment.state === 'JUSDA_SCRAPER_INVOKED') {
            setPercentage(90);
            const interval = setInterval(() => {
                dispatch(getShipment({id: currentShipment.id}));
            }, 30000);
            return () => clearInterval(interval);
        } else {
            setPercentage(100);
        }
    }, [currentShipment])

    useEffect(() => {
        return () => {
            dispatch(resetShipmentRequestState());
            dispatch(resetShipmentResponseState());
            dispatch(resetSenderAddress());
            dispatch(resetReceiverAddress());
            dispatch(resetQuoteRequestState());
            dispatch(resetQuote());
            dispatch(resetCommercialInvoice());
        }
    }, []);

    return (
        <Provider>
            <div className="page-content shipment">
                <Container fluid>
                    <div>
                        <div className={`row ship-head pl-3 borderC   ${percentage >= 100 ? "head-red" : "test "}`}>
                            <div className="col-sm-4 pt-3 ">
                                <div
                                    className={`h2 font-weight-bold mt-2  ${percentage >= 100 ? " head-red" : " "}`}>New
                                    Shipment
                                </div>
                                <div className={`ship-order ${percentage >= 100 ? "head-red" : " "}`}>ID#: {shipmentId}
                                </div>
                            </div>
                            <div className={`col-sm-3 pt-4 date-ship ${percentage >= 100 ? " head-red" : " "}`}>
                                Shipping Date: {currentShipment.shipment_date}
                                <ProgressBar percentage={percentage}/>
                            </div>
                            <div
                                className={`col-sm-2 pt-4 ship-progress ${percentage >= 100 ? " head-red" : " "}`}> {percentage} %
                                Progress
                            </div>
                            <div className="col-sm-3 pt-4 item-dx">
                                {percentage < 100 &&
                                <div>
                                    <span
                                        className="ship-progress">Please wait... We are progressing the shipment</span>
                                    <div>
                                        <Spinner size="sm" color="danger"
                                                 className="fp-spinner"
                                                 style={{width: '1rem', height: '1rem'}}/>

                                    </div>
                                </div>
                                }
                                {percentage >= 100 &&
                                <div>
                                    {shipment.state === 'CONFIRMED_IN_PROVIDER' &&
                                    <span className={`ship-progress ${percentage >= 100 ? " head-red" : " "}`}>The shipment was created with success!</span>
                                    }
                                    {currentShipment.state === 'FAILED_IN_JUSDA' &&
                                    <span className={`ship-progress ${percentage >= 100 ? " head-red" : " "}`}>The shipment failed!</span>
                                    }
                                    {currentShipment.state === 'VOIDED' &&
                                    <span className={`ship-progress ${percentage >= 100 ? " head-red" : " "}`}>The shipment was voided!</span>
                                    }
                                    {currentShipment.state === 'VOID_REQUESTED_IN_JUSDA' &&
                                    <span className={`ship-progress ${percentage >= 100 ? " head-red" : " "}`}>The shipment was voided!</span>
                                    }
                                    <div>
                                        {shipment.state === 'CONFIRMED_IN_PROVIDER' &&
                                        <span><i className="pi pi-check-circle" style={{'fontSize': '1em', color: 'white'}}></i></span>
                                        }
                                        {currentShipment.state === 'FAILED_IN_JUSDA' &&
                                        <span className="ship-progress">
                                            <i className="pi pi-exclamation-triangle" style={{'fontSize': '1em', color: 'white'}}></i>
                                        </span>
                                        }
                                        {currentShipment.state === 'VOIDED' &&
                                        <span className="ship-progress">
                                            <i className="pi pi-ban" style={{'fontSize': '1em', color: 'white'}}></i>
                                        </span>
                                        }
                                        {currentShipment.state === 'VOID_REQUESTED_IN_JUSDA' &&
                                        <span className="ship-progress">
                                            <i className="pi pi-ban" style={{'fontSize': '1em', color: 'white'}}></i>
                                        </span>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <CardBooked courier={currentShipment.courier} shipment={currentShipment}/>
                    </div>
                </Container>
            </div>
        </Provider>
    )
}

export default CurrentShipment;
