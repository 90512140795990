import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../actions/auth.actions";
import {GET_USER_SUCCESS, UPDATE_USER_PROFILE_SUCCESS} from "../actions/users.actions";

const initialAuthState = {accessToken: null, refreshToken: null, userLogged: null};

export const AuthReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {...state, accessToken: action.payload.access, refreshToken: action.payload?.refresh};
        case GET_USER_SUCCESS:
            return {...state, userLogged: action.payload};
        case LOGOUT_SUCCESS:
            return initialAuthState;
        case UPDATE_USER_PROFILE_SUCCESS:
            return {...state, userLogged: action.payload}
        default:
            return state;
    }
}
