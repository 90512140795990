import { convertInvoiceRowToUI } from "../../utils";
import { GET_INVOICES_SUCCESS, SET_INVOICES_STATE } from "../actions/invoices.actions";

const initialInvoicesState = {
  invoicesList: [],
  next: null,
  previous: null,
  count: 0,
  selectedType: 'unpaid',
  payInvoiceModal: {
    open: false,
  },
  pdfInvoiceModal: {
    open: false,
  }
};

export const InvoicesReducer = (state = initialInvoicesState, action) => {
  switch (action.type) {
    case SET_INVOICES_STATE:
      return { ...state, ...action.payload };
    case GET_INVOICES_SUCCESS:
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      let invoicesList = [];
      // Injecting fields not retured by backend yet
      // Renaming fields
      for (let i in action.payload.results) {
        invoicesList.push(convertInvoiceRowToUI(action.payload.results[i]));
      }
      state.invoicesList = invoicesList;
      return state;
    default:
      return state;
  }
}
