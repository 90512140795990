import {combineReducers} from "redux";
import {AuthReducer} from "./reducers/auth.reducer";
import {applyMiddleware, createStore} from "@reduxjs/toolkit";
import {composeWithDevTools} from 'redux-devtools-extension';
import {createForms} from "react-redux-form";
import {UIReducer} from "./reducers/ui.reducer";
import {ErrorReducer} from "./reducers/error.reducer";
import {
    CreateUserForm,
    LoginForm,
    PackagingDetailsForm,
    ResetPasswordConfirmationForm,
    ResetPasswordMailForm,
    UtilForm
} from "./forms/forms.types";
import {LayoutReducer} from "./reducers/layout.reducer";
import createSagaMiddleware from "redux-saga";
import {logger} from "redux-logger";
import appSaga from "./saga";
import {AccountsReducer} from "./reducers/accounts.reducers";
import {CustomersReducer} from "./reducers/customers.reducer";
import {UsersReducer} from "./reducers/users.reducer";
import {InvoicesReducer} from "./reducers/invoices.reducer";
import {AdminInvoicesReducer, AdminInvoiceReducer} from "./reducers/admin.invoices.reducers"
import {QuoteReducer, QuoteRequestReducer, QuotesReducer} from "./reducers/quotes.reducers";
import {ServicesReducer} from "./reducers/services.reducers";
import {PackagesReducer} from "./reducers/packages.reducers";
import {ShipmentReducer, ShipmentRequestReducer, ShipmentsReducer, ShipmentLabelReducer} from "./reducers/shipments.reducers";
import {receiverAddressReducer, returnAddressReducer, senderAddressReducer, verifiedAddressReducer} from "./reducers/address.reducers";
import {CouriersReducer} from "./reducers/couriers.reducers";
import {SignaturesReducer} from "./reducers/signatures.reducers";
import {CommercialInvoiceReducer} from "./reducers/commercial.invoice.reducers";
import { AdminSettingsReducer } from "./reducers/admin.settings.reducers";

const appReducer = combineReducers({
    // list of reducers
    accounts: AccountsReducer,
    auth: AuthReducer,
    ui: UIReducer,
    errors: ErrorReducer,
    layout: LayoutReducer,
    customers: CustomersReducer,
    users: UsersReducer,
    invoices: InvoicesReducer,
    adminInvoices: AdminInvoicesReducer,
    adminInvoice: AdminInvoiceReducer,
    adminSettings: AdminSettingsReducer,
    quote: QuoteReducer,
    quotes: QuotesReducer,
    quoteRequest: QuoteRequestReducer,
    shipments: ShipmentsReducer,
    shipmentResponse: ShipmentReducer,
    shipmentRequest: ShipmentRequestReducer,
    shipmentLabel: ShipmentLabelReducer,
    services: ServicesReducer,
    packages: PackagesReducer,
    senderAddress: senderAddressReducer,
    receiverAddress: receiverAddressReducer,
    returnAddress: returnAddressReducer,
    verifiedAddress: verifiedAddressReducer,
    couriers: CouriersReducer,
    signatures: SignaturesReducer,
    commercialInvoiceStore: CommercialInvoiceReducer,
    ...createForms({
        login: LoginForm,
        util: UtilForm,
        createUser: CreateUserForm,
        resetPasswordMail: ResetPasswordMailForm,
        resetPasswordConfirmation: ResetPasswordConfirmationForm,
        packagingDetails: PackagingDetailsForm
    }),
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
}
export const store = createStore(
    appReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
);

sagaMiddleware.run(appSaga);
