import {FormGroup, UncontrolledTooltip} from 'reactstrap';
import {Control, Errors, Form} from 'react-redux-form';
import '../../App.scss';
import '../../styles/OnBoarding.css';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useSelector} from "react-redux";


export const CreateUserCredentialsComponent = (props) => {
    const maxLength = (len) => (val) => !(val) || (val.length <= len);
    const minLength = (len) => (val) => val && (val.length >= len);
    const validEmail = (val) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val) && (val?.length > 1) && (val?.length <= 150);
    const validUsername = (val) => /^[A-Za-zÀ-ȕ0-9,-_.@+]+$/i.test(val) && (val?.length > 1) && (val?.length <= 150);
    let passwordChecked ;

    const formIsValid = useSelector(state => state.forms.createUser.$form.valid);
    const passwordField = useSelector(state => state.forms.createUser.password.value);
    const confirmPasswordField = useSelector(state => state.forms.createUser.confirm_password.value);

    function onSubmit(data, event) {
        event.preventDefault();
        props.onSubmit(data, event);
    }

    passwordChecked = passwordField === confirmPasswordField;

    return (
        <div className="mt-5">
            <Form className="mt-5 justify-content-center" model="createUser"
                  onSubmit={(formModelData, event) => onSubmit(formModelData, event)}>
                <FormGroup className="row justify-content-center">
                    <Control.text model=".username" id="username" name="username"
                                  placeholder="Enter username"
                                  className="form-control ship-input custom-input-margin"
                                  validators={{
                                      validUsername,
                                  }}
                    />
                    <span>
                        <InfoOutlinedIcon fontSize="small" id="usernameTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="usernameTooltip">
                        Please ensure username contains only letters, digit and @/./+/-/_
                    </UncontrolledTooltip>
                    <Errors
                        className="text-center ship-input-error"
                        model=".username"
                        show="touched"
                        messages={{
                            validUsername: 'Invalid username',
                        }}
                    />
                </FormGroup>
                <FormGroup className="row justify-content-center">
                    <Control.text model=".email" id="email" name="email"
                                  placeholder="Enter email address"
                                  className="form-control ship-input custom-input-margin"
                                  validators={{
                                      validEmail,
                                  }}
                    />
                    <span>
                        <InfoOutlinedIcon fontSize="small" id="mailTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="mailTooltip">
                        Please enter a unique email address
                    </UncontrolledTooltip>
                    <Errors
                        className="text-center ship-input-error"
                        model=".email"
                        show="touched"
                        messages={{
                            validEmail: 'Invalid email',
                        }}
                    />
                </FormGroup>
                <FormGroup className="row justify-content-center">
                    <Control.input model=".password" id="password" name="password"
                                   placeholder="Password"
                                   className="form-control ship-input custom-input-margin"
                                   type="password"
                                   validators={{
                                       minLength: minLength(8),
                                       maxLength: maxLength(128)
                                   }}
                    />
                    <span>
                        <InfoOutlinedIcon fontSize="small" id="passwordTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="passwordTooltip">
                        Please enter password for account
                    </UncontrolledTooltip>
                    <Errors
                        className="ship-input-error text-center"
                        model=".password"
                        show="touched"
                        messages={{
                            minLength: 'Password must be greater than 8 characters',
                            maxLength: 'Password be 150 characters or less'
                        }}
                    />
                </FormGroup>
                <FormGroup className="row justify-content-center">
                    <Control.input model=".confirm_password" id="confirm_password" name="confirm_password"
                                   placeholder="Confirm password"
                                   className="form-control ship-input custom-input-margin"
                                   type="password"
                                   validators={{
                                       minLength: minLength(8),
                                       maxLength: maxLength(128)
                                   }}
                    />
                    <span className="cursor-pointer">
                        <InfoOutlinedIcon fontSize="small" id="passwordConfirmTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="passwordConfirmTooltip">
                        Please confirm password for account
                    </UncontrolledTooltip>
                    <Errors
                        className="ship-input-error text-center"
                        model=".confirm_password"
                        show="touched"
                        messages={{
                            minLength: 'Password must be greater than 8 characters',
                            maxLength: 'Password be 150 characters or less'
                        }}
                    />
                </FormGroup>
                <div className="row justify-content-center">
                    <button className="w-100 ship-primary-custom-button"
                            disabled={!formIsValid || !passwordChecked}
                            type="submit"
                            style={{maxWidth: "333px"}}>
                        Next
                    </button>
                </div>
            </Form>
        </div>

    )
}


export default CreateUserCredentialsComponent;
