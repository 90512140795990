import { createAction } from "@reduxjs/toolkit";

// Invoices action types
export const GET_INVOICES = '[INVOICES] Getting invoices';
export const GET_INVOICES_SUCCESS = '[INVOICES] Get invoices success!'
export const SET_INVOICES_STATE = '[INVOICES] Set New State'
export const DOWNLOAD_INVOICE_PDF = '[INVOICES] Download Invoice Pdf'

// Invoices actions
export const getInvoices = createAction(GET_INVOICES);
export const getInvoicesSuccess = createAction(GET_INVOICES_SUCCESS);

export const setInvoicesState = (data) => ({
    type: SET_INVOICES_STATE,
    payload: data
});

export const downloadPdfAction = (invoiceId, url) => ({
  type: DOWNLOAD_INVOICE_PDF,
  payload: {url, invoiceId}
})
