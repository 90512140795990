import axios from "axios";
import {API_BASE_URL, COMMERCIAL_INVOICE} from "./APICONSTANTS";

export const postCommercialInvoice = (commercialInvoice) => { 
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.post(API_BASE_URL + COMMERCIAL_INVOICE, commercialInvoice)
                .then((res) => {
                    console.log("Commercial invoice added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post commercial invoice error: ", err);
                    reject("signin error!");
                });
        } catch (error) {
            console.error("Post commercial invoice error!==", error);
            reject("Post commercial invoice error!");
        }
    });
};
