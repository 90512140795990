import {
    UPDATE_RECEIVER_ADDRESS,
    UPDATE_SENDER_ADDRESS,
    RESET_RECEIVER_ADDRESS,
    RESET_SENDER_ADDRESS,
    INITIALIZE_SENDER_ADDRESS,
    UPDATE_RETURN_ADDRESS,
    RESET_RETURN_ADDRESS,
    VERIFY_ADDRESS,
    UPDATE_VERIFIED_ADDRESS
} from "../actions/addresses.actions";

let initialSenderAddress = {}

export const senderAddressReducer = (state = initialSenderAddress, action) => {
    switch(action.type) {
        case UPDATE_SENDER_ADDRESS:
            return {...state, ...action.payload}
        case RESET_SENDER_ADDRESS:
            return initialSenderAddress;
        case INITIALIZE_SENDER_ADDRESS:
            initialSenderAddress = action.payload;
            return {...state, ...action.payload}
        default:
            return state;
    }
}

const initialReceiverAddress = {}

export const receiverAddressReducer = (state = initialReceiverAddress, action) => {
    switch(action.type) {
        case UPDATE_RECEIVER_ADDRESS:
            return {...state, ...action.payload}
        case RESET_RECEIVER_ADDRESS:
            return initialReceiverAddress;
        default:
            return state;
    }
}


export const returnAddressReducer = (state = initialSenderAddress, action) => {
    switch(action.type) {
        case UPDATE_RETURN_ADDRESS:
            return {...state, ...action.payload}
        case RESET_RETURN_ADDRESS:
            return initialSenderAddress;
        default:
            return state;
    }
}


const initialVerifiedAddress = {isResidential: null}

export const verifiedAddressReducer = (state = initialVerifiedAddress, action) => {
    switch(action.type) {
        case VERIFY_ADDRESS:
            return initialVerifiedAddress;
        case UPDATE_VERIFIED_ADDRESS:
            return {...state, ...action.payload}
        default:
            return state;
    }
}
