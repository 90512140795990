import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {Button} from "primereact/button";
import {updateUserProfile} from "../../redux/actions/users.actions";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {PROFILE} from "../../navigation/ROUTECONSTANTS";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {StatesUS} from "../../assets/shipment/StatesUS"
import {StatesCA} from "../../assets/shipment/StatesCA";
import {countries} from '../../assets/shipment/countries';
import { phoneRegExp } from "../../utils/numbers-helper";

export const EditProfile = () => {

    const user = JSON.parse(localStorage.getItem('sd-user'));
    const dispatch = useDispatch();
    const history = useHistory();

    function goBack(event) {
        event.preventDefault();
        history.push(PROFILE);
    }

    const formik = useFormik({
        initialValues: {
            first_name: user.first_name ? user.first_name : '',
            last_name: user.last_name ? user.last_name : '',
            email: user.email ? user.email : '',
            title: user.title ? user.title : '',
            username: user.username ? user.username : '',
            phone: user.phone ? user.phone : '',
            // dob: format(new Date(), 'yyyy-MM-dd'),
            address: user.address ? user.address : '',
            country: user.country ? user.country : '',
            state: user.state ? user.state : '',
            zip: user.zip ? user.zip : '',
            city: user.city ? user.city : '',
            residential: user.residential ? true : false,
        },
        validationSchema: Yup.object({
            first_name: Yup.string()
                .max(150, 'Must be 150 characters or less')
                .required('Required'),
            last_name: Yup.string()
                .max(150, 'Must be 150 characters or less')
                .required('Required'),
            title: Yup.string()
                .max(30, 'Must be 30 characters or less')
                .notRequired(),
            email: Yup.string().email('Invalid email address')
                .required('Required'),
            zip: Yup.string().min(1, 'Must be at least 1 character')
                .max(10, 'Must be 10 characters or less')
                .required('Required'),
            city: Yup.string().min(1, 'Must be at least 1 character')
                .max(150, 'Must be 150 characters or less')
                .required('Required'),
            state: Yup.string().min(1, 'Must be at least 1 character')
                .max(20, 'Must be 20 characters or less')
                .required('Required'),
            address: Yup.string()
                .max(255, 'Must be 255 characters or less')
                .required('Required'),
            phone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .notRequired(),
            }),

        onSubmit: values => {
            dispatch(updateUserProfile(values, history, true));
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <div className="h2 font-weight-bold mt-2">My Account</div>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="h-100">
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <Row>
                                        <Button icon="pi pi-angle-left" className="mr-2 p-button-danger ml-2 h-25"
                                                onClickCapture={(e) => goBack(e)}/>
                                        <div className="h4 font-weight-bold mt-2">Back to Profile</div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row className="w-100">
                                        <Col xl={12}>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-9">
                                                    <form className="mt-3 justify-content-center"
                                                          onSubmit={formik.handleSubmit}>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="first_name"
                                                                           className="ship-input-label">First Name</Label>
                                                                    <Input id="first_name" name="first_name"
                                                                           placeholder="First Name"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.first_name}
                                                                           type="text"
                                                                           invalid={'first_name' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.first_name}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="last_name" className="ship-input-label">Last Name</Label>
                                                                    <Input id="last_name" name="last_name"
                                                                           placeholder="Last Name"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.last_name}
                                                                           type="text"
                                                                           invalid={'last_name' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.last_name}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="username"
                                                                           className="ship-input-label">Username</Label>
                                                                    <Input id="username" name="username"
                                                                           placeholder="Username"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.username}
                                                                           readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="company_title"
                                                                           className="ship-input-label">Company Title</Label>
                                                                    <Input id="company_title" name="title"
                                                                           placeholder="Company Title"
                                                                           className="form-control ship-input-nl"
                                                                           type="text"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.title}
                                                                           invalid={'title' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.title}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="email"
                                                                           className="ship-input-label">Email Address</Label>
                                                                    <Input id="email" name="email"
                                                                           placeholder="Email"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.email}
                                                                           type="email"
                                                                           invalid={'email' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.email}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="phone"
                                                                           className="ship-input-label">Phone Number</Label>
                                                                    <Input id="phone" name="phone"
                                                                           placeholder="Phone Number"
                                                                           type="phone"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.phone}
                                                                           invalid={'phone' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.phone}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={9}>
                                                                <FormGroup>
                                                                    <Label for="address" className="ship-input-label">Address</Label>
                                                                    <Input type="text" id="address" name="address"
                                                                           placeholder="Address"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.address}
                                                                           invalid={'address' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.address}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                <FormGroup check inline className="pl-2" style={{paddingTop: '2em'}}>
                                                                    <Label check for="residential" className="mt-2 ship-input-label">Residential Address?</Label>
                                                                    <Input type="checkbox" id="residential" name="residential"
                                                                        className="ml-2 mt-2"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.residential}
                                                                        defaultChecked={formik.values.residential}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="country"
                                                                           className="ship-input-label">Country</Label>
                                                                    <Input id="country" name="country" type="select"
                                                                            placeholder="Country"
                                                                            className="form-control ship-input-nl"
                                                                            onChange={(e) => {
                                                                                formik.values.state = '';
                                                                                formik.handleChange(e);
                                                                            }}
                                                                            value={formik.values.country}
                                                                    >
                                                                        {countries.map(countryOption =>
                                                                        <option value={countryOption.code}
                                                                                key={countryOption.code}>{countryOption.name}</option>)
                                                                        }
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="city"
                                                                           className="ship-input-label">City</Label>
                                                                    <Input id="city" name="city"
                                                                           type="text"
                                                                           placeholder="City"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.city}
                                                                           invalid={'city' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.city}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="state"
                                                                           className="ship-input-label">State</Label>
                                                                        {formik.values.country == 'US' &&
                                                                            <Input type="select" className="form-control" value={formik.values.state}
                                                                                    id="state" name="state"
                                                                                    onChange={formik.handleChange}
                                                                                    invalid={'state' in formik.errors}
                                                                                >
                                                                                <option value="">Select State</option>
                                                                                {StatesUS.map(stateOption =>
                                                                                    <option value={stateOption.abbreviation}
                                                                                            key={stateOption.abbreviation}>{stateOption.name}</option>)}
                                                                            </Input>
                                                                        }
                                                                        {formik.values.country == 'CA' &&
                                                                            <Input type="select" className="form-control" value={formik.values.state}
                                                                                    id="state" name="state"
                                                                                    onChange={formik.handleChange}
                                                                                    invalid={'state' in formik.errors}
                                                                                >
                                                                                <option value="">Select State</option>
                                                                                {StatesCA.map(stateOption =>
                                                                                    <option value={stateOption.abbreviation}
                                                                                            key={stateOption.abbreviation}>{stateOption.name}</option>)}
                                                                            </Input>
                                                                        }
                                                                        {!['US', 'CA'].includes(formik.values.country) &&
                                                                            <Input model=".state" id="state" name="state"
                                                                                placeholder="State"
                                                                                className="form-control ship-input-nl"
                                                                                type="text"
                                                                                value={formik.values.state}
                                                                                onChange={formik.handleChange}
                                                                                invalid={'state' in formik.errors}
                                                                            />
                                                                        }
                                                                        <FormFeedback>
                                                                            {formik.errors.state}
                                                                        </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="zip"
                                                                           className="ship-input-label">Zip</Label>
                                                                    <Input id="zip" name="zip"
                                                                           placeholder="Zip"
                                                                           className="form-control ship-input-nl"
                                                                           onChange={formik.handleChange}
                                                                           value={formik.values.zip}
                                                                           invalid={'zip' in formik.errors}
                                                                    />
                                                                    <FormFeedback>
                                                                        {formik.errors.zip}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <div className="row justify-content-center mb-3">
                                                            <Col md={6}>
                                                                <button className="w-100 ship-secondary-custom-button"
                                                                        disabled={false}
                                                                        onClickCapture={(e) => goBack(e)}>
                                                                    Do Later
                                                                </button>
                                                            </Col>
                                                            <Col md={6}>
                                                                <button className="w-100 ship-primary-custom-button"
                                                                        disabled={false}
                                                                        type="submit">
                                                                    Next
                                                                </button>
                                                            </Col>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditProfile;
