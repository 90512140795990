import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
   GET_PACKAGES,
   getPackagesSuccess
} from "../actions/packages.actions";
import {disableOverlay, enableOverlay} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getPackages} from "../../services/packagesServices";

/**
 * getPackagesEffect
 */
 function* getPackagesEffetcs() {
    try {
        yield put(enableOverlay('Loading packages...'));
        const packagesList = yield call(getPackages);
        yield put(getPackagesSuccess(packagesList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchGetPackages() {
    yield takeEvery(GET_PACKAGES, getPackagesEffetcs);
}

function* packagesSaga() {
    yield all([
        fork(watchGetPackages)
    ]);
}

export default packagesSaga;