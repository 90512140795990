// development endpoint
// export const API_BASE_URL = 'https://shipdistrict-dev.us-west-2.elasticbeanstalk.com/api';
// prod endpoint
export const API_BASE_URL = 'https://api.shipdistrict.com/api';
export const LOGIN = '/users/token/create';
export const TOKEN_REFRESH = '/users/token/refresh';
export const USERS_API = '/users';
export const GET_CUSTOMERS = '/contacts';
export const CONTACTS_ACTIONS = '/contactsactions';
export const CONTACTS_BULK_DELETE = '/bulk-delete';
export const API_URL_VERIFY_ADDRESS = '/verify_address';
export const CHECK_USER_REGISTRATION = '/register';
export const USER_UPDATE = '/update';
export const PASSWORD_RESET_BY_EMAIL = '/password_reset';
export const PASSWORD_RESET = '/password_reset/confirm';
export const ACCOUNTS = '/accounts';
export const INVOICES = '/invoices';
export const QUOTES = '/quotes';
export const SERVICES = '/configuration/services';
export const PACKAGES = '/configuration/packages';
export const SHIPMENTS = '/shipments';
export const SHIPMENTS_BASIC_STAT = '/basic-stat';
export const COURIERS = '/configuration/couriers';
export const SIGNATURES = '/configuration/signatures';
export const COMMERCIAL_INVOICE = '/commercialinvoices';
export const VOID = '/void';
export const LABEL = '/label';
export const API_URL_PDF = '/pdf';
export const API_URL_EXPORT_PDF = '/export-to-pdf';
export const API_URL_EMAIL_LABEL = '/send-email-label';
export const AMOUNTS_PER_STATUS = '/amountsperstatus';

// Admin settings
export const API_URL_ADMIN_SETTINGS_PROCESSING_DAY = '/configure/processingday';
export const API_URL_ADMIN_SETTINGS_AUTOMATIC_PROCESSING = '/configure/automaticprocessing';
export const API_URL_ADMIN_SETTINGS_FEE_LABEL = '/configure/feelabel';
export const API_URL_ADMIN_SETTINGS_FEE_PERCENTAGE = '/configure/feepercentage';

// Stat codes
export const STAT_CODE_BY_CARRIER = 'by-carrier';
export const STAT_CODE_BY_STATE = 'by-state';
export const STAT_CODE_AMOUNT_BY_CARRIER = 'amount-by-carrier';
