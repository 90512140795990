import { all } from 'redux-saga/effects'
import accountsSaga from "./effects/accounts.saga";
import addressesSaga from "./effects/addresses.saga";
import layoutSaga from "./effects/layout.saga";
import authSaga from "./effects/auth.saga";
import customersSaga from "./effects/customers.saga";
import usersSaga from "./effects/users.saga";
import invoicesSagas from "./effects/invoices.saga"
import adminInvoicesSagas from "./effects/admin.invoices.saga";
import adminSettingsSagas from "./effects/admin.settings.saga";
import quotesSaga from './effects/quotes.saga';
import servicesSaga from './effects/services.saga';
import packagesSaga from './effects/packages.saga';
import shipmentsSaga from './effects/shipments.saga';
import couriersSaga from './effects/couriers.saga';
import signaturesSaga from './effects/signatures.saga';
import commercialInvoiceSaga from './effects/commercial.invoice.saga';

//public

export default function* appSaga() {
    yield all([
        accountsSaga(),
        addressesSaga(),
        authSaga(),
        layoutSaga(),
        customersSaga(),
        usersSaga(),
        invoicesSagas(),
        adminInvoicesSagas(),
        adminSettingsSagas(),
        quotesSaga(),
        servicesSaga(),
        packagesSaga(),
        shipmentsSaga(),
        couriersSaga(),
        signaturesSaga(),
        commercialInvoiceSaga()
    ])
}
