import React , { useRef , useEffect} from 'react';
import { useDispatch, useSelector} from "react-redux";
import { Dialog } from "primereact/dialog";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { useReactToPrint } from 'react-to-print';
import { getShipmentLabel } from "../../redux/actions/shipments.actions";


const ModalTitle = ({shipmentId}) => {
  return (
    <div className="modal-title">
      <div className="p-grid p-justify-between pdf-invoice-title">
        <h3>Print Label - Shipment - #{shipmentId} </h3>
      </div>
    </div>
  );
};


const PdfInObject = ({pdfUrl}) => {
  return (
    <object data={pdfUrl} type="application/pdf" className="pdf-object-rendering" id="label-pdf-in-object">
      <p className="h4 text-white">
        Your browser does not support in-page pdf rendering. <br/>
        <a href={pdfUrl} target="_blank" className="submit-button">click here to download and print the label</a>
      </p>
    </object>
  );
};


const LabelPrintModal = (props) => {

  const shipmentLabel = useSelector(state => state.shipmentLabel);
  const componentRef = useRef();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
  });
  const onHide = (name) => {console.log(name)}

  const printObjectTag = (e) => {
    console.log(e);
    var el = document.getElementById('label-pdf-in-object');
    el.focus();
    el.print();
  }

  useEffect(() => {
    dispatch(getShipmentLabel(props.shipmentId));
  }, []);

  return (
    <Dialog
      closable={false}
      visible
      className="pdf-invoice"
      header={
        <ModalTitle
        shipmentId={props.shipmentId}
        />
      }
      onHide={() => onHide('displayBasic')}
    >

    <div className="pdf-container" ref={componentRef}>
        {shipmentLabel.returnType == 'pdf'
          ? <Document file={shipmentLabel.fileContent} >
              <Page pageNumber={1} />
            </Document>
          : <PdfInObject pdfUrl={shipmentLabel.labelUrl} />
        }
    </div>

    {shipmentLabel.returnType == 'pdf'
      ? (
      <div className="form-footer p-grid p-justify-between">
        <div className="p-col-4">
          <button className="cancel-button" onClick={props.open}>Cancel</button>
        </div>
        <div className="p-col-4">
          <button className="submit-button" onClick={handlePrint}>Print Label</button>
        </div>
      </div>
      )
      : (
        <div className="form-footer p-grid p-justify-center">
          <div className="p-col-4">
            <button className="cancel-button" onClick={props.open}>Close</button>
          </div>
        </div>
      )
    }

    </Dialog>
  );
};

export default LabelPrintModal;
