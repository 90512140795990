import React, {useEffect} from "react";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme
} from "../../../redux/actions/layout.actions";
import Footer from "./Footer";

export const Layout = (props) => {

    // const layoutSetting = useSelector(state => state.layout)
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    /*  toggleMenuCallback = () => {
        if (this.props.leftSideBarType === "default") {
          this.props.changeSidebarType("condensed", this.state.isMobile);
        } else if (this.props.leftSideBarType === "condensed") {
          this.props.changeSidebarType("default", this.state.isMobile);
        }
      };*/

    const themeState = useSelector(state => state.layout);
    const dispatch = useDispatch();

    useEffect(() => {
        if (themeState.leftSideBarTheme) {
            dispatch(changeSidebarTheme(themeState.leftSideBarTheme));
        }

        if (themeState.layoutWidth) {
            dispatch(changeLayoutWidth(themeState.layoutWidth));
        }

        if (themeState.leftSideBarType) {
            dispatch(changeSidebarType(themeState.leftSideBarType));
        }
        if (themeState.topbarTheme) {
            dispatch(changeTopbarTheme(themeState.topbarTheme));
        }
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header/>
                <Sidebar
                    theme="light"
                    type="logo"
                    isMobile={isMobile}
                />
                <div className="main-content">
                    {props.children}
                    <Footer/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Layout;

