import {createAction} from "@reduxjs/toolkit";

// Customers action types
export const GET_CUSTOMERS = '[CUSTOMERS] Getting customers';
export const GET_CUSTOMERS_SUCCESS = '[CUSTOMERS] Getting customers success!';
export const SEARCH_CUSTOMERS = '[CUSTOMERS] Searching customers';
export const SEARCH_CUSTOMERS_SUCCESS = '[CUSTOMERS] Searching customers success!';
export const CREATE_CUSTOMER = '[CUSTOMERS] Creating new customer...';
export const CREATE_CUSTOMER_SUCCESS = '[CUSTOMERS] New customer create with success!';
export const DELETE_CUSTOMER = '[CUSTOMERS] Deleting customer';
export const DELETE_MULTIPLE_CUSTOMERS = '[CUSTOMERS] Deleting multiple customers';
export const UPDATE_CUSTOMER = '[CUSTOMERS] Updating customer...';
export const UPDATE_CUSTOMER_SUCCESS = '[CUSTOMERS] Customer updated with success!';
export const RESET_CUSTOMER_STATE = '[CUSTOMER] Reset customer state'
export const RESET_CUSTOMERS_FOUND = '[CUSTOMER] Reset customers found'

// Customers actions
export const getCustomers = createAction(GET_CUSTOMERS);
export const getCustomersSuccess = createAction(GET_CUSTOMERS_SUCCESS);
export const searchCustomers = createAction(SEARCH_CUSTOMERS);
export const searchCustomersSuccess = createAction(SEARCH_CUSTOMERS_SUCCESS);
export const createCustomerSuccess = createAction(CREATE_CUSTOMER_SUCCESS);
export const updateCustomerSuccess = createAction(UPDATE_CUSTOMER_SUCCESS);
export const resetCustomerState = createAction(RESET_CUSTOMER_STATE);
export const resetCustomersFound = createAction(RESET_CUSTOMERS_FOUND);

export const createCustomer = createAction(CREATE_CUSTOMER);
export const updateCustomer = createAction(UPDATE_CUSTOMER);
export const deleteCustomer = createAction(DELETE_CUSTOMER);
export const deleteMultipleCustomers = createAction(DELETE_MULTIPLE_CUSTOMERS);
