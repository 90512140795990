import {Col, FormGroup, Label, Row} from 'reactstrap';
import {Control, Errors, Form} from 'react-redux-form';
import '../../App.scss';
import '../../styles/OnBoarding.css';
import {useSelector} from "react-redux";
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import {StatesUS} from "../../assets/shipment/StatesUS"
import {StatesCA} from "../../assets/shipment/StatesCA";
import {countries} from '../../assets/shipment/countries';
import { useState } from 'react';
import { phoneRegExp } from "../../utils/numbers-helper";

export const CreateUserDetailsComponent = (props) => {

    const required = (val) => val && val.length;
    const maxLength = (len) => (val) => !(val) || (val.length <= len);
    const minLength = (len) => (val) => val && (val.length >= len);
    const validatePhone = (val) => val && phoneRegExp.test(val);
    const formIsValid = useSelector(state => state.forms.createUser.$form.valid);
    const history = useHistory();
    const [selectedCountry, setSelectedCountry] = useState('US');

    function onSubmit(data, event) {
        event.preventDefault();
        const updateUserData = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            title: data.title,
            dob: format(new Date(), 'yyyy-MM-dd'),
            phone: data.phone,
            address: data.address,
            country: data.country,
            zip: data.zip,
            city: data.city,
            state: data.state,
            residential: data.residential
        }
        props.onSubmit(updateUserData, event);
    }

    function later(event) {
        event.preventDefault();
        history.push('/dashboard');
    }

    function formOnChange(modelValues) {
        setSelectedCountry(modelValues.country);
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-9">
                <Form className="mt-5 justify-content-center" model="createUser" onChange={formOnChange}
                      onSubmit={(formModelData, event) => onSubmit(formModelData, event)}>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="first_name" className="ship-input-label">First Name</Label>
                                <Control.text model=".first_name" id="first_name" name="first_name"
                                              placeholder="First Name"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  required,
                                                  minLength: minLength(3),
                                                  maxLength: maxLength(150)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".first_name"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        minLength: 'The first name must be greater than 3 character. ',
                                        maxLength: 'The first name must be smaller than 150 character. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="last_name" className="ship-input-label">Last Name</Label>
                                <Control.text model=".last_name" id="last_name" name="last_name"
                                              placeholder="Last Name"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  required,
                                                  minLength: minLength(3),
                                                  maxLength: maxLength(150)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".last_name"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        minLength: 'The last name must be greater than 3 character. ',
                                        maxLength: 'The last name must be smaller than 150 character. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="username" className="ship-input-label">Username</Label>
                                <Control.text model=".username" id="username" name="username"
                                              placeholder="Username"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  required
                                              }} readOnly={true}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".title"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="company_title" className="ship-input-label">Company Title</Label>
                                <Control.text model=".title" id="company_title" name="company_title"
                                              placeholder="Company Title"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  maxLength: maxLength(30)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".title"
                                    show="touched"
                                    messages={{
                                        maxLength: 'Company title must be smaller than 30 characters. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="company_size" className="ship-input-label">Company size</Label>
                                <Control.text model=".company_size" id="company_size" name="company_size"
                                              placeholder="Company size"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  maxLength: maxLength(10)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".company_size"
                                    show="touched"
                                    messages={{
                                        maxLength: 'The last name must be smaller than 10 digits. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="phone" className="ship-input-label">Phone Number</Label>
                                <Control.input model=".phone" id="phone" name="phone"
                                               placeholder="Phone Number"
                                               type="number"
                                               className="form-control ship-input-nl"
                                               validators={{
                                                   required,
                                                   validPhone: validatePhone
                                               }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".phone"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        validPhone: 'Phone number is not valid. ',
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={9}>
                            <FormGroup>
                                <Label for="address" className="ship-input-label">Address</Label>
                                <Control.text model=".address" id="address" name="address"
                                              placeholder="Address"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  required,
                                                  minLength: minLength(3),
                                                  maxLength: maxLength(255)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".address"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        minLength: 'The address must be greater than 3 characters. ',
                                        maxLength: 'The address must be smaller than 255 characters. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup style={{'padding-top': '2.5em'}}>
                                <Control.checkbox model=".residential" id="residential" name="residential" />
                                <Label for="residential" className="ship-input-label ml-2">Residencial Address?</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="country" className="ship-input-label">Country</Label>
                                <Control.select model=".country" id="country" name="country"
                                    className="form-control ship-input-nl"
                                    >
                                    <option value="">Select country</option>
                                    {countries.map(countryOption =>
                                        <option value={countryOption.code}
                                                key={countryOption.code}>{countryOption.name}</option>)
                                        }
                                </Control.select>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="city" className="ship-input-label">City</Label>
                                <Control.text model=".city" id="city" name="city"
                                              placeholder="City"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  required,
                                                  minLength: minLength(3),
                                                  maxLength: maxLength(150)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".city"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        minLength: 'The city must be greater than 3 characters. ',
                                        maxLength: 'The city must be smaller than 150 characters. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="state" className="ship-input-label">State</Label>
                                {!['US', 'CA'].includes(selectedCountry) &&
                                <Control.text model=".state" id="state" name="state"
                                        placeholder="State"
                                        className="form-control ship-input-nl"
                                        validators={{
                                            minLength: minLength(2),
                                            maxLength: maxLength(50)
                                        }}
                                />
                                }
                                {selectedCountry == 'US' &&
                                <Control.select model=".state" id="state" name="state"
                                        className="form-control ship-input-nl"
                                        validators={{
                                            required,
                                            minLength: minLength(3),
                                            maxLength: maxLength(150)
                                        }}
                                    >
                                        <option value="">Select State</option>
                                    {StatesUS.map(stateOption =>
                                        <option value={stateOption.code}
                                                key={stateOption.code}>{stateOption.name}</option>)
                                        }
                                </Control.select>
                                }
                                {selectedCountry == 'CA' &&
                                <Control.select model=".state" id="state" name="state"
                                        className="form-control ship-input-nl"
                                        validators={{
                                            required,
                                            minLength: minLength(3),
                                            maxLength: maxLength(150)
                                        }}
                                    >
                                        <option value="">Select State</option>
                                    {StatesCA.map(stateOption =>
                                        <option value={stateOption.code}
                                                key={stateOption.code}>{stateOption.name}</option>)
                                        }
                                </Control.select>
                                }
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".state"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        minLength: 'The state must be greater than 2 characters. ',
                                        maxLength: 'The state must be smaller than 50 characters. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="zip" className="ship-input-label">Zip</Label>
                                <Control.text model=".zip" id="zip" name="zip"
                                              placeholder="Zip"
                                              className="form-control ship-input-nl"
                                              validators={{
                                                  required,
                                                  minLength: minLength(2),
                                                  maxLength: maxLength(10)
                                              }}
                                />
                                <Errors
                                    className="text-center ship-input-error"
                                    model=".zip"
                                    show="touched"
                                    messages={{
                                        required: 'The field is required. ',
                                        minLength: 'The zip must be greater than 2 characters. ',
                                        maxLength: 'The zip must be smaller than 10 characters. '
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="row justify-content-center">
                        <Col md={6}>
                            <button className="w-100 ship-secondary-custom-button"
                                    disabled={false} onClickCapture={(e) => later(e)}>
                                Do Later
                            </button>
                        </Col>
                        <Col md={6}>
                            <button className="w-100 ship-primary-custom-button"
                                    disabled={!formIsValid}
                                    type="submit">
                                Next
                            </button>
                        </Col>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default CreateUserDetailsComponent;
