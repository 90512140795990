import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    GET_LIST_COURIERS,
    getCouriersSuccess,
    UPDATE_COURIER,
 } from "../actions/couriers.actions";

import {disableOverlay, enableOverlay} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getCouriers, updateCourier} from "../../services/couriersService";

/**
 * getCouriersEffect
 */
 function* getCouriersEffect() {
    try {
        yield put(enableOverlay('Loading couriers...'));
        const couriersList = yield call(getCouriers);
        yield put(getCouriersSuccess(couriersList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * updateCourierEffect
 * @param payload
 */
 function* updateCourierEffect(payload) {
    try {
        yield put(enableOverlay('Updating courier...'));
        const courier = yield call(updateCourier, payload.id, payload.bodyRequest);
        if(courier) {
            const couriersList = yield call(getCouriers);
            if(couriersList) {
                yield put(getCouriersSuccess(couriersList));
                yield put(disableOverlay());
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * Watchers
 */
 export function* watchGetCouriers() {
    yield takeEvery(GET_LIST_COURIERS, getCouriersEffect);
}

export function* watchUpdateCourier() {
    yield takeEvery(UPDATE_COURIER, updateCourierEffect);
}

function* couriersSaga() {
    yield all([
        fork(watchGetCouriers),
        fork(watchUpdateCourier)
    ]);
}

export default couriersSaga;
