import {Sidebar} from "primereact/sidebar";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import '../../styles/tracking.scss';
import CardDetails from "../shipment/TrackingDetails";
import {setTrackingSidebarState} from "../../redux/actions/shipments.actions";

export const TrackingSidebar = () => {

    const dispatch = useDispatch();
    const {openSideBar = {}} = useSelector((state) => ({
        openSideBar: state.shipments.openSideBarTracking
    }));

    useEffect(() => {
        if (document.getElementsByClassName("p-sidebar-tracking-mask")?.length > 0) {
            document.getElementsByClassName("p-sidebar-tracking-mask")[0].style.backdropFilter = "none"
        }
    }, []);

    const setSideBarHidden = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: false,
                selectedShipment: {},
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: false,
                openLinkTrackingModal: false
            }
        }));
    }

    return (
        <Sidebar
            visible={openSideBar.open}
            position="right"
            onHide={() => setSideBarHidden(false)}
            className={`tracking-right-sidebar`}
            dismissable
            showCloseIcon={false}
            blockScroll
            baseZIndex={2002}
        >
            {openSideBar.open &&
            <CardDetails selectedShipment={openSideBar.selectedShipment}/>
            }
        </Sidebar>
    );
}

export default TrackingSidebar;
