import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_COMMERCIAL_INVOICE,
    createCommercialInvoiceSucess
 } from "../actions/commercial.invoices.action";
 import {postCommercialInvoice} from "../../services/commercial.invoice.service"; 
import { disableOverlay, enableOverlay } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";

 /**
 * createCommercialInvoiceEffect
 * @param {*} param0
 */
  function* createCommercialInvoiceEffect({payload}) {
    try {
        yield put(enableOverlay('Creating commercial invoice...'));
        const commercialInvoice = yield call(postCommercialInvoice, payload);
        if(commercialInvoice) {
            console.log('[COMMERCIAL INVOICE SAGA] Commercial invoice created with success');
            yield put(createCommercialInvoiceSucess(commercialInvoice));
            yield put(disableOverlay());
        }
        else {
            console.log("[COMMERCIAL INVOICE SAGA] Commercial invoice creation: something went wrong!");
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchCreateCommercialInvoiceEffect() {
    yield takeEvery(CREATE_COMMERCIAL_INVOICE, createCommercialInvoiceEffect);
}

function* commercialInvoiceSaga() {
    yield all([
        fork(watchCreateCommercialInvoiceEffect)
    ]);
}

export default commercialInvoiceSaga;