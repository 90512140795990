import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import AppSpinner from "./components/ui/AppSpinner";
import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "./components/ui/NotificationModal";
import "./theme.scss";
import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";
import { addSessionInterceptor, handleAppDataInterceptor, invalidTokenInterceptor, networkErrorInterceptor, otherErrorsInterceptor } from "./interceptors/axiosInterceptors"
import { getUserSuccess } from "./redux/actions/users.actions";
import { store } from "./redux/configureStore"

axios.defaults.baseURL = process.env.REACT_APP_DEFAULT_ENDPOINT;
axios.interceptors.request.use(addSessionInterceptor);
// Order of interceptors is important
axios.interceptors.response.use(null, invalidTokenInterceptor);
axios.interceptors.response.use(null, networkErrorInterceptor);
axios.interceptors.response.use(null, otherErrorsInterceptor);
axios.interceptors.response.use(null, (error) => handleAppDataInterceptor(error, store));

function App() {

    const dispatch = useDispatch();
    const overlay = useSelector(state => state.ui.overlay);
    const notificationModal = useSelector(state => state.ui.notificationModal);
    const toastSelector = useSelector(state => state.ui.toast);
    const toast = useRef(null);

    useEffect(() => {
        if (toastSelector && toastSelector.severity && toastSelector.summary && toastSelector.detail) {
            toast.current.clear();
            toast.current.show(toastSelector);
        }
    }, [toastSelector])

    // RElOAD USER LOGGED IN STORE
    const userLogged = JSON.parse(localStorage.getItem('sd-user'));
    useEffect(() => {
        if (userLogged) {
            dispatch(getUserSuccess(userLogged));
        }
    }, [userLogged]);

    let appSpinner;
    let appNotificationModal;

    if (overlay.loading) {
        appSpinner = <AppSpinner message={overlay.message} />
    }

    if (notificationModal.isOpen) {
        appNotificationModal = <NotificationModal title={notificationModal.title}
            message={notificationModal.message}
            icon={notificationModal.icon}
            isOpen={notificationModal.isOpen} />
    } else {
        appNotificationModal = null;
    }

    return (
        <React.Fragment>
            <BrowserRouter>
                <RouterConfig />
                {appNotificationModal}
                {appSpinner}
                <Toast ref={toast} life={2000} baseZIndex={100000} />
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
