import React, {useState} from 'react';
import {Card} from 'primereact/card';
import DialogEmailShip from './DialogEmailShip';
import PdfInvoiceModal from './DialogPrint';
import LabelPrintModal from './DialogLabelPrint';

import icondhl from '../../assets/icons/shipment/dhl.png';
import iconfedex from '../../assets/images/fedex.png';
import iconups from '../../assets/icons/shipment/ups.png';
import iconusps from '../../assets/icons/shipment/usps.png';

import {useDispatch, useSelector} from 'react-redux';
import {CardBody, Col, Row} from "reactstrap";
import DialogVoid from "./DialogVoid";
import {setTrackingSidebarState, voidShipmentRequest} from "../../redux/actions/shipments.actions";
import {setToast} from "../../redux/actions/ui.actions";
import TrackingSidebar from "../tracking/TrackingSidebar";
import {useHistory} from 'react-router-dom';


export const CardBooked = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [emailLabel, setEmailLabel] = useState(false);
    const [printLabel, setPrintLabel] = useState(false);
    const [printInvoice, setPrintInvoice] = useState(false);
    const openVoidModal = useSelector(state => state.shipments.openSideBarTracking.openVoidShipmentModal);

    const header = (courier) => {
        switch (courier) {
            case "FEDEX":
                return <img alt="Card" className="m" style={{width: '30%'}} src={iconfedex}
                            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
            case "UPS":
                return <img alt="Card" className="m" style={{width: '30%'}} src={iconups}
                            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
            case "USPS":
                return <img alt="Card" className="m" style={{width: '30%'}} src={iconusps}
                            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
            case "DHL":
                return <img alt="Card" className="m" style={{width: '30%'}} src={icondhl}
                            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
            default:
                return <img alt="Card" className="m" style={{width: '30%'}} src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png' />
        }
    }

    const openPrintInvoice = () => {
        setPrintInvoice(!printInvoice)
    }

    const openPrintLabel = () => {
        setPrintLabel(!printLabel)
    }

    const OnClickPopup = () => {
        setEmailLabel(!emailLabel)
    }

    const openTrackingSidebar = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: true,
                selectedShipment: props.shipment,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: false,
                openLinkTrackingModal: false
            }
        }));
    }

    const closeFunctionalModal = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: false,
                selectedShipment: null,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: false,
                openLinkTrackingModal: false
            }
        }));
    }

    const voidShipment = () => {
        dispatch(voidShipmentRequest(props.shipment.id, history));
        closeFunctionalModal();
    }

    const copyTrackingToClipboard = () => {
        navigator.clipboard.writeText(props.shipment.tracking_number)
            .then(() => {
                dispatch(setToast({
                    severity: 'success',
                    summary: 'Copied!',
                    detail: 'Tracking number copied to clipboard.',
                    life: 5000
                }));
            })
            .catch(() => {
                dispatch(setToast({
                    severity: 'error',
                    summary: 'Failed!',
                    detail: 'Failed to copy tracking number.',
                    life: 5000
                }));
            });
    }

    return (
        <>
            <Row>
                <Col sm={12} className="pl-0 pr-0">
                    <Card>
                        <CardBody className="pt-0 pb-0">
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={4}>
                                    <Row className="justify-content-center align-items-center">
                                        {header(props.courier)}
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        {props.shipment.state === 'CONFIRMED_IN_PROVIDER' &&
                                        <span className="card-title h3">Shipment Booked!</span>
                                        }
                                        {props.shipment.state === 'FAILED_IN_JUSDA' &&
                                        <span className="card-title h3">Shipment Failed!</span>
                                        }
                                        {props.shipment.state === 'VOIDED' &&
                                        <span className="card-title h3">Shipment Voided!</span>
                                        }
                                        {props.shipment.state === 'VOID_REQUESTED_IN_JUSDA' &&
                                        <span className="card-title h3">Shipment Voided!</span>
                                        }
                                        {props.shipment.state === 'JUSDA_SCRAPER_INVOKED' &&
                                        <span className="card-title h3">Shipment is under process...</span>
                                        }

                                        </Row>
                                    {/* Return label not implemented yet */}
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship" disabled={true || props.shipment.state !== 'CONFIRMED_IN_PROVIDER'}>
                                            Schedule A Pickup
                                        </button>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship"
                                                onClick={openPrintLabel} disabled={props.shipment.state !== 'CONFIRMED_IN_PROVIDER'}>
                                            Print Label
                                        </button>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship"
                                                onClick={OnClickPopup} disabled={props.shipment.state !== 'CONFIRMED_IN_PROVIDER'}>
                                            Email Label
                                        </button>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship"
                                                onClick={openPrintInvoice} disabled={props.shipment.state !== 'CONFIRMED_IN_PROVIDER' || props.shipment.commercial_invoice.length == 0}>
                                            Print Commercial Invoice
                                        </button>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship"
                                                disabled={props.shipment.state !== 'CONFIRMED_IN_PROVIDER' || props.shipment.commercial_invoice.length == 0}>
                                            Email Commercial Invoice
                                        </button>
                                    </Row>
                                    {/* Return label not implemented yet */}
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship" disabled={true || props.shipment.state !== 'CONFIRMED_IN_PROVIDER'}>
                                            Create Return Label
                                        </button>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship"
                                                onClick={copyTrackingToClipboard} disabled={props.shipment.state !== 'CONFIRMED_IN_PROVIDER' || !props.shipment.tracking_number}>
                                            Copy Tracking - {props.shipment.tracking_number ? props.shipment.tracking_number : 'n/d'}
                                        </button>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                        <button type="button" className="btn-outline-secondary-ship" onClick={openTrackingSidebar}>
                                            View Shipment Details
                                        </button>
                                    </Row>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {openVoidModal && <DialogVoid open={closeFunctionalModal} accept={voidShipment}/>}
            <DialogEmailShip displayDialog={emailLabel} closeCallback={OnClickPopup} shipmentId={props.shipment.id} />
            {printInvoice && <PdfInvoiceModal open={openPrintInvoice} shipmentId={props.shipment.id} commercialInvoiceId={props.shipment.commercial_invoice[0]} />}
            {printLabel && <LabelPrintModal open={openPrintLabel} shipmentId={props.shipment.id} />}
            <TrackingSidebar/>
        </>
    )
}

export default CardBooked;
