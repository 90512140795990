import {ServiceCouriersByID} from "../assets/shipment/ServiceCarrier";

export const cleanSession = () => {
    localStorage.removeItem('sd-access');
    localStorage.removeItem('sd-refresh');
    localStorage.removeItem('sd-user');
}

export const courierMainServiceIdToString = (courier, id) => {
    return ServiceCouriersByID.filter(elem => elem.courier === courier && elem.id === id)[0];
}

export const checkCommercialInvoice = (commercialInvoice) => {
    return !!(commercialInvoice.date &&
        commercialInvoice.destination_country &&
        commercialInvoice.shipper &&
        commercialInvoice.shipper_address &&
        // commercialInvoice.shipper_email &&
        // commercialInvoice.shipper_phone_number &&
        commercialInvoice.receiver_address &&
        // commercialInvoice.receiver_email &&
        // commercialInvoice.receiver_phone_number &&
        commercialInvoice.carrier
    );
}

export const buildPaginationUrlParams = ({page, limit, filter, order, sortBy, status, owner_id})  => {
    let url = new URLSearchParams();

    if (page) {
        url.append('page', page);
    }
    if (limit) {
        url.append('pageSize', limit);
    }
    if (filter) {
        url.append('search', filter);
    }
    if (order && sortBy && order == 'desc') {
        sortBy = '-' + sortBy;
    }
    if (sortBy) {
        url.append('ordering', sortBy);
    }
    if (status) {
        url.append('status', status);
    }
    if (owner_id) {
        url.append('owner_id', owner_id);
    }

    let queryString = url.toString();
    if (queryString) {
        queryString = '?' + queryString;
    }

    return queryString;
};

export const convertBasicStatFormat = (apiResponse)  => {
    let categories = [];
    let series = [];
    if (apiResponse.length > 0) {
        categories = new Set();
        for (let row of apiResponse) {
            categories.add(row.time_period);
        }
        categories = Array.from(categories);

        let groups = new Set();
        for (let row of apiResponse) {
            groups.add(row.group);
        }

        for (let group of groups) {
            let serie = {
                name: group,
                data: Array.from({length: categories.length}, () => null),
            }
            series.push(serie);
        }

        for (let row of apiResponse) {
            let groupIndex = series.findIndex((item) => item.name == row.group);
            let periodIndex = categories.indexOf(row.time_period);
            series[groupIndex].data[periodIndex] = row.value;
        }

        categories = categories.map((time_period) =>
            new Date(time_period).toLocaleString('default', { month: 'short'})
        );
    }

    return {categories, series};
}

export const convertInvoiceRowToUI =  (result) => {
    return {
        // Renamed
        invoiceId: result.id,
        company: result.owner_title,
        customerName: result.owner_first_name + ' ' + result.owner_last_name,

        total: +result.total,
        paid: 0, // TODO
        balance: (result.total - 0), // TODO

        invoice_date: result.invoice_date,
        due_date: result.due_date,
        status: result.status,
        owner_account_id: result.owner_account_id,
    }
}

export const currencyFormatter = (decimalPositions) => new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPositions,
    maximumFractionDigits: decimalPositions,
});

export const joinAccountAddress = (account) => {
    let addressItems = [];
    for (let item of ['address', 'address1', 'address2', 'city', 'state', 'zip', 'country']) {
        if (item in account && account[item]) {
            addressItems.push(account[item]);
        }
    }
    return addressItems.join(', ');
}
