import { createAction } from "@reduxjs/toolkit";

// Commercial invoices action types
export const UPDATE_COMMERCIAL_INVOICE_REQUEST = '[COMMERCIAL_INVOICE] Updating commercial invoice request';
export const RESET_COMMERCIAL_INVOICE_REQUEST = '[COMMERCIAL_INVOICE] Commercial invoice request reset!';
export const CREATE_COMMERCIAL_INVOICE = '[COMMERCIAL_INVOICE] Creating commercial invoice...';
export const CREATE_COMMERCIAL_INVOICE_SUCCESS = '[COMMERCIAL_INVOICE] Commercial invoice created with success!';
export const ADD_COMMERCIAL_INVOICE_PACKAGES = '[COMMERCIAL_INVOICE] Adding Commercial invoice packages.';
export const REMOVE_COMMERCIAL_INVOICE_PACKAGES = '[COMMERCIAL_INVOICE] Removing Commercial invoice packages.';

//Commercial invoices actions
export const updateCommercialInvoiceRequest = (commercialInvoiceRequest) => ({
    type: UPDATE_COMMERCIAL_INVOICE_REQUEST,
    payload: commercialInvoiceRequest
});

export const resetCommercialInvoice = createAction(RESET_COMMERCIAL_INVOICE_REQUEST);

export const createCommercialInvoice = (commercialInvoiceRequest) => ({
    type: CREATE_COMMERCIAL_INVOICE,
    payload: commercialInvoiceRequest
});

export const createCommercialInvoiceSucess = createAction(CREATE_COMMERCIAL_INVOICE_SUCCESS);

export const addCommercialInvoicePackages = (pack) => ({
    type: ADD_COMMERCIAL_INVOICE_PACKAGES,
    payload: pack
})

export const removeCommercialInvoicePackages = (id) => ({
    type: REMOVE_COMMERCIAL_INVOICE_PACKAGES,
    payload: id
})
