import axios from "axios";
import {buildPaginationUrlParams} from '../utils/index';
import { AMOUNTS_PER_STATUS, API_BASE_URL, INVOICES } from "./APICONSTANTS";

export const getInvoices = (paginationParams) => {
  paginationParams = paginationParams ? paginationParams : {};

  // Translate UI field names to API names
  if ('sortBy' in paginationParams) {
    switch (paginationParams.sortBy) {
      case 'invoiceId':
        paginationParams.sortBy = 'id';
      break;
    }
  }

  // Translate UI invoice status names to API names
  if ('status' in paginationParams) {
    switch (paginationParams.status) {
      case 'paid':
        paginationParams.status = 'PAID';
      break;
      case 'unpaid':
        paginationParams.status = 'CREATED';
      break;
      default:
        paginationParams.status = null;
    }
  } else {
    paginationParams.status = null;
  }

  const url = API_BASE_URL + INVOICES + buildPaginationUrlParams(paginationParams);

  return new Promise((resolve, reject) => {
      try {
          axios.get(url)
              .then((res) => {
                  console.log("Invoices loaded: ", res);
                  resolve(res.data);
              })
              .catch((err) => {
                  console.log("Get invoices error: ", err);
                  reject("Get invoices error");
              });
      } catch (error) {
          console.error("Get invoices error: ", error);
          reject("Get invoices error");
      }
  });
};

export const getAmountsPerStatus = () => {
  return new Promise((resolve, reject) => {
      try {
          axios.get(API_BASE_URL + INVOICES + AMOUNTS_PER_STATUS)
              .then((res) => {
                  console.log('Invoices amounts per status loaded: ', res);
                  resolve(res.data);
              })
              .catch((err) => {
                  console.log("Get invoices amounts per status error: ", err);
                  reject("Get invoices amounts per status error!");
              });
      } catch (error) {
          console.error("Get invoices amounts per status error!==", error);
          reject("Get invoices amounts per status error!");
      }
  });
};
