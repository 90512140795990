import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import {setAdminInvoicesState} from "../../../../redux/actions/admin.invoices.actions"
import { updateAccount } from "../../../../redux/actions/accounts.actions";
import "./style.scss"

const EnableAccountModal = () => {
  const dispatch = useDispatch()
  const { enableAccountModal = {} } = useSelector((state) => ({
    enableAccountModal: state.adminInvoices.enableAccountModal,
  }));

  const selectedInvoiceAccount = useSelector((state) => state.adminInvoices.selectedInvoiceAccount);

  const onEnable = () => {
    dispatch(updateAccount({accountId: selectedInvoiceAccount.id, data: {is_active: true}}));
    dispatch(setAdminInvoicesState({
      enableAccountModal: {
        open: false
      }
    }))
  }

  const onCancel = () => {
    dispatch(setAdminInvoicesState({
      enableAccountModal: {
        open: false
      }
    }))
  }

  return (
    <Dialog
      closable={false}
      visible={enableAccountModal.open}
      onHide={onCancel}
      dismissableMask={true}
      className="enable-account-modal"
    >
      <div className="enable-account-modal-content">
      <h1>Enable Customer</h1>
      <p>Are you sure you want to enable this customer?</p>
      <div className="enable-footer">
      <div className="enable-footer p-grid p-justify-between">
        <div className="p-col-6">
          <button className="cancel-button" onClick={() => onCancel()}>Cancel</button>
        </div>
        <div className="p-col-6">
          <button className="enable-button" onClick={() => onEnable()}>Enable User</button>
        </div>
      </div>
      </div>
      </div>
    </Dialog>
  );
};

export default EnableAccountModal;
