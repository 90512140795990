import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { getInvoices, setInvoicesState } from "../../redux/actions/invoices.actions";
import arrowLeft from "../../assets/images/arrow.alt.left.png";
import arrowRight from "../../assets/images/arrow.alt.right.png";
import { currencyFormatter } from "../../utils";
import { Button } from "primereact/button";

const FilesColumn = ({ rowData }) => {
  const dispatch = useDispatch()
  const openPayModalInvoice = ()=>{
    dispatch(setInvoicesState({
      payInvoiceModal: {
        open: true,
        invoice: rowData
      }
    }))
  }

  const openPdfModalInvoice = ()=>{
    dispatch(setInvoicesState({
      pdfInvoiceModal: {
        open: true,
        invoice: rowData
      }
    }))
  }
  return (
    <div>
      <Button className="p-button-secondary p-button-text" title="View Invoice" icon="pi pi-file" onClick={(e) => openPdfModalInvoice(e)} />
      <Button className="p-button-secondary p-button-text" title="Pay Invoice" icon="pi pi-credit-card" onClick={(e) => openPayModalInvoice(e)} />
    </div>
  );
};

const InvoicesBody = () => {
  const dispatch = useDispatch();

  const { invoicesList = [], totalRecords = 0, selectedType } = useSelector((state) => ({
    invoicesList: state.invoices.invoicesList,
    totalRecords: state.invoices.count,
    selectedType: state.invoices.selectedType,
  }));

  const rowsPerPage = 10;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rowsPerPage,
    page: 0,
    sortField: 'invoiceId',
    sortOrder: -1,
    filters: {},
    selectedType,
  });

  useEffect(() => {
      loadLazyData();
  }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    dispatch(getInvoices({
      'page': lazyParams.page + 1,
      'limit': lazyParams.rows,
      'sortBy': lazyParams.sortField,
      'order': lazyParams.sortOrder < 0 ? 'desc' : 'asc',
      'filter': lazyParams.selectedAccountId,
      'status': lazyParams.selectedType,
    }));
  }

  const onPage = (event) => {
    setLazyParams({...lazyParams, ...event});
  }

  const onSort = (event) => {
    setLazyParams({...lazyParams, ...event});
  }

  useEffect(() => {
    if (selectedType != lazyParams.selectedType) {
      setLazyParams({
        ...lazyParams,
        selectedType,
      });
    }
  }, [selectedType])

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams({...lazyParams, ...event});
  }

  const formatCurrency = currencyFormatter(2);

  const template1 = {
    layout: "PrevPageLink CurrentPageReport NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img src={arrowLeft} alt="arrowLeft" />
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img src={arrowRight} alt="arrowRight" />
          <Ripple />
        </button>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          className="p-mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          <span className="page-number-button">{options.currentPage}</span>
          <span className="page-of-button"> of </span>
          <span className="page-number-button">{options.totalPages}</span>
        </span>
      );
    },
  };

  return (
    <div className="invoices-body">
      <DataTable
        paginator
        paginatorTemplate={template1}
        rows={rowsPerPage}
        onPage={onPage}
        onSort={onSort}
        lazy filterDisplay="row"
        first={lazyParams.first}
        totalRecords={totalRecords}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        value={invoicesList}
        removableSort
        className="p-datatable-striped"
        dataKey="invoiceId"
      >
        <Column
          className="invoiceId"
          field="invoiceId"
          header="Invoice #"
          sortable
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="invoice_date"
          header="Invoice Date"
          body={(rowData) => new Date(rowData.invoice_date).toLocaleDateString()}
          headerClassName="text-right"
          bodyClassName="text-right"
          sortable
        ></Column>
        <Column
          field="total"
          header="Total Amount"
          sortable
          body={(rowData) => formatCurrency.format(rowData.total)}
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="paid"
          header="Paid"
          body={(rowData) => formatCurrency.format(rowData.paid)}
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="balance"
          header="Balance"
          body={(rowData) => formatCurrency.format(rowData.balance)}
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="actions"
          header="Actions"
          body={(rowData) => <FilesColumn rowData={rowData} />}
        ></Column>
      </DataTable>
    </div>
  );
};

export default InvoicesBody;
