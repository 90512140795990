import {all, call, delay, fork, put, takeEvery} from "redux-saga/effects";
import {
    initializeSenderAddress,
    resetReturnAddress,
    RETRIEVE_USER_ADDRESS,
    updateVerifiedAddress,
    VERIFY_ADDRESS,
} from "../actions/addresses.actions";
import {disableOverlay, enableOverlay, openNotificationModal, closeNotificationModal} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {verifyAddress} from "../../services/addressesService";
import successIcon from "../../assets/icons/shared/success.svg"

/**
 * verifyAddressEffect
 */
 function* verifyAddressEffect({payload}) {
    try {
        yield put(enableOverlay('Verifying address ...'));
        const verifyAddressResult = yield call(verifyAddress, payload);
        yield put(disableOverlay());
        if (verifyAddressResult) {
            yield put(updateVerifiedAddress(verifyAddressResult.data));

            let residentialFieldChanged = '';
            if ('data' in verifyAddressResult && verifyAddressResult.data.isResidential != payload.residential) {
                residentialFieldChanged = ' Residential address checkbox was changed.';
            }
            if (verifyAddressResult.result == 'OK') {
                yield put(openNotificationModal({
                    title: 'Verified!',
                    message: 'Address validated as correct!' + residentialFieldChanged,
                    icon: successIcon
                }));
            } else {
                yield put(openNotificationModal({
                    title: 'Please check message',
                    message: verifyAddressResult.message + residentialFieldChanged,
                    icon: successIcon
                }));
            }
            yield delay(5000);
            yield put(closeNotificationModal());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * retrieveUserAddressEffect
 */
 function* retrieveUserAddressEffect() {
    try {
        const userResult = JSON.parse(localStorage.getItem('sd-user'));
        if (userResult) {
            const senderAddress = {
                address1: userResult.address,
                address2: "",
                address3: "",
                city: userResult.city,
                companyName: userResult.title,
                country: userResult.country,
                email: userResult.email,
                fullName: userResult.first_name + " " + userResult.last_name,
                phone: userResult.phone,
                state: userResult.state,
                zip: userResult.zip,
                residential: userResult.residential,
            }
            yield put(initializeSenderAddress(senderAddress));
            yield put(resetReturnAddress());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchVerifyAddress() {
    yield takeEvery(VERIFY_ADDRESS, verifyAddressEffect);
}

export function* watchRetrieveUserAddress() {
    yield takeEvery(RETRIEVE_USER_ADDRESS, retrieveUserAddressEffect);
}

function* addressesSaga() {
    yield all([
        fork(watchVerifyAddress),
        fork(watchRetrieveUserAddress),
    ]);
}

export default addressesSaga;