import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import '../../styles/datatables.scss'
import '../../styles/AddressBook.scss'
import {
    createCustomer,
    deleteMultipleCustomers,
    getCustomers, resetCustomerState,
    updateCustomer
} from "../../redux/actions/customers.actions";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import {ConfirmDialog} from "primereact/confirmdialog";
import {deleteCustomer as deleteCustomerAction} from "../../redux/actions/customers.actions"
import CreateEditContactDialog from "../../components/addressbook/CreateEditContactDialog";
import arrowLeft from "../../assets/images/arrow.alt.left.png";
import {Ripple} from "primereact/ripple";
import arrowRight from "../../assets/images/arrow.alt.right.png";
import { debounce } from '@material-ui/core';


export const AddressBook = () => {

    const dispatch = useDispatch();
    const customers = useSelector(state => state.customers.customersList);
    const customerCount = useSelector(state => state.customers.count);

    const rowsPerPage = 10;

    const [customerDialog, setCustomerDialog] = useState(false);
    const [customerEditDialog, setCustomerEditDialog] = useState(false);
    const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
    const [deleteCustomersDialog, setDeleteCustomersDialog] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [customersData, setCustomersData] = useState([]);

    const [tableState, setTableState] = useState({
        first: 0,
        limit: rowsPerPage,
        page: 1,
        lastPageLoaded: 1,
        sortField: null,
        sortOrder: null,
        filter: []
    });

    useEffect(() => {
        dispatch(resetCustomerState());
        // Initial load of customers list is done by onFilter()
    }, []);

    useEffect(() => {
        reorderItems(tableState.first);
    }, [customers]);

    useEffect(() => {
        onFilter();
    }, [globalFilter]);

    const reorderItems = (first) => {
        let tmp = customers;
        tmp = tmp.slice(first, first + rowsPerPage);
        setCustomersData([...tmp]);
    }

    const openNew = () => {
        setCustomerDialog(true);
    }

    const hideDeleteCustomerDialog = () => {
        setDeleteCustomerDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteCustomersDialog(false);
    }

    const confirmDeleteSelected = () => {
        if(selectedCustomers.length > 1) {
            setDeleteCustomersDialog(true);
        } else {
            setDeleteCustomerDialog(true);
        }
    }

    const exportCSV = () => {
        // api call here
    }

    const deleteCustomer = () => {
        setDeleteCustomerDialog(false);
        dispatch(deleteCustomerAction(selectedCustomers[0]));
        setSelectedCustomers(null);
    }

    const customersBulkDelete = () => {
        const idsList = [];
        selectedCustomers.map(customer => idsList.push(customer.id));
        setDeleteCustomersDialog(false);
        const customersIds = {ids: idsList};
        dispatch(deleteMultipleCustomers(customersIds));
        setSelectedCustomers(null);
    }

    const saveCustomer = (customer) => {
        dispatch(createCustomer(customer))
        setCustomerDialog(false);
    }

    const patchCustomer = (customer) => {
        dispatch(updateCustomer({...customer, id: selectedCustomers[0].id}));
        setCustomerEditDialog(false);
    }

    const onFilter = () => {
        dispatch(resetCustomerState());
        let _lazyParams = tableState;
        _lazyParams.first = 0;
        _lazyParams.page = 1;
        _lazyParams.lastPageLoaded = 1;
        let order = _lazyParams.sortOrder === 1 ? 'asc' : 'desc';
        let filterFields = [];
        if (globalFilter) {
            filterFields.push(globalFilter);
        }
        _lazyParams.filter = filterFields.join(',');
        dispatch(getCustomers({
            page: _lazyParams.page,
            limit: tableState.limit,
            filter: _lazyParams.filter,
            order: order,
            sort: _lazyParams.sortField
        }));
        setTableState(_lazyParams);
    }

    const onSort = (event) => {
        dispatch(resetCustomerState());
        let _lazyParams = tableState;
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        _lazyParams.first = 0;
        _lazyParams.page = 1;
        _lazyParams.lastPageLoaded = 1;
        let order = event.sortOrder === 1 ? 'asc' : 'desc';
        dispatch(getCustomers({
            page: _lazyParams.page,
            limit: tableState.limit,
            filter: _lazyParams.filter,
            order: order,
            sort: _lazyParams.sortField
        }));
        setTableState(_lazyParams);
    }

    const onPage = (event) => {
        let _lazyParams = tableState;
        _lazyParams.first = event.first;
        _lazyParams.page = event.page + 1;
        let order = _lazyParams.sortOrder === 1 ? 'asc' : 'desc';
        if (tableState.lastPageLoaded < _lazyParams.page) {
            dispatch(getCustomers({
                page: _lazyParams.page,
                limit: tableState.limit,
                filter: _lazyParams.filter,
                order: order,
                sort: _lazyParams.sortField
            }));
            _lazyParams.lastPageLoaded = _lazyParams.page;
        } else {
            reorderItems(_lazyParams.first);
        }
        setTableState(_lazyParams);
    }

    const createModalTitle = () => {
        return (
            <span className="functional-modal-title">Create new contact</span>
        );
    }

    const editModalTitle = () => {
        return (
            <span className="functional-modal-title">Edit contact</span>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="total-address-count">Customers {customerCount}</span>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Import" icon="import-logo" className="customer-button ml-1" disabled/>
                <Button label="Export" icon="export-logo" className="customer-button ml-1" onClick={exportCSV}
                        disabled/>
            </React.Fragment>
        )
    }

    const paginatorLeft = () => {
        return (
            <React.Fragment>
                <Button label="Delete" icon="pi pi-trash" className="customer-button mr-1"
                        onClick={confirmDeleteSelected}
                        disabled={!selectedCustomers || !selectedCustomers.length}/>
                <Button label="Edit" icon="pi pi-user-edit" className="customer-button mr-1"
                        onClick={() => setCustomerEditDialog(true)}
                        disabled={!selectedCustomers || selectedCustomers.length !== 1}/>
                <span className="p-input-icon-left mr-1">
                <i className="pi pi-search"/>
                <InputText type="search" className="filter-tag-input"
                           onInput={(e) => setGlobalFilter(e.target.value)}
                           placeholder="Filter by Tags"/>
            </span>
                <Button icon="filter-logo" className="customer-button ml-1" disabled={true}/>

            </React.Fragment>
        )
    }

    const paginator = {
        layout: "PrevPageLink CurrentPageReport NextPageLink",
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={options.className}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={arrowLeft} alt="arrowLeft"/>
                    <Ripple/>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={options.className}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={arrowRight} alt="arrowRight"/>
                    <Ripple/>
                </button>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{color: "var(--text-color)", userSelect: "none"}}>
                    <span className="page-number-button">{options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <Row className="align-items-center">
                                        <Col xl={3} md={3} xs={3} className="d-flex justify-content-start">
                                            <div className="h2 font-weight-bold mt-2 float-left">Address Book</div>
                                        </Col>
                                        <Col xl={7} md={7} xs={7} className="d-flex justify-content-between">
                                            <span className="p-input-icon-left mr-1 w-100">
                                            <i className="pi pi-search"/>
                                             <InputText type="search"
                                                        className="w-100 filter-tag-input"
                                                        onInput={debounce((e) => setGlobalFilter(e.target.value), 600)}
                                                        placeholder="Search"/>
                                            </span>
                                            <Button icon="search-option"
                                                    className="customer-button float-right"/>
                                        </Col>
                                        <Col xl={2} md={2} xs={2} className="d-flex justify-content-end">
                                            <Button label="New Customer"
                                                    className="add-customer-button p-mr-1 float-right"
                                                    onClick={openNew}/>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="datatable-crud-demo">
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Toolbar className="p-mb-1 customer-toolbar pl-0 pr-0"
                                             right={rightToolbarTemplate} left={leftToolbarTemplate}></Toolbar>
                                    <DataTable value={customersData}
                                               lazy
                                               dataKey="id" rows={rowsPerPage}
                                               paginator
                                               paginatorPosition={'top'}
                                               paginatorTemplate={paginator}
                                               paginatorLeft={paginatorLeft}
                                               totalRecords={customerCount}
                                               onPage={onPage}
                                               onSort={onSort}
                                               sortField={tableState.sortField}
                                               sortOrder={tableState.sortOrder}
                                               first={tableState.first}
                                               selection={selectedCustomers}
                                               onSelectionChange={(e) => setSelectedCustomers(e.value)}
                                    >
                                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                                        <Column field="fullName" header="Customer" sortable></Column>
                                        <Column field="companyName" header="Company" sortable></Column>
                                        <Column field="address1" header="Address" sortable></Column>
                                        <Column field="city" header="City" sortable></Column>
                                        <Column field="state" header="State" sortable></Column>
                                        <Column field="zip" header="Zip" sortable></Column>
                                        <Column field="country" header="Country" sortable></Column>
                                        <Column field="phone" header="Phone" sortable></Column>
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CreateEditContactDialog open={customerDialog} title={createModalTitle}
                                     close={() => setCustomerDialog(false)}
                                     submit={(customer) => saveCustomer(customer)}/>
            <CreateEditContactDialog open={customerEditDialog} title={editModalTitle}
                                     addressToEdit={selectedCustomers && selectedCustomers.length === 1 ? selectedCustomers[0] : null}
                                     close={() => setCustomerEditDialog(false)}
                                     submit={(customer) => patchCustomer(customer)}/>

            <ConfirmDialog visible={deleteCustomerDialog}
                           onHide={() => setDeleteCustomerDialog(false)}
                           className='delete-confirm-dialog'
                           message="Are you sure you want to delete this customer?"
                           header="Delete Customer"
                           acceptLabel="Delete User"
                           rejectLabel="Cancel"
                           accept={deleteCustomer} reject={hideDeleteCustomerDialog} closable={false}/>
            <ConfirmDialog visible={deleteCustomersDialog}
                           className='delete-bulk-confirm-dialog'
                           onHide={() => setDeleteCustomerDialog(false)}
                           message="Are you sure you want to delete the entire selection?"
                           header="Delete All Selections"
                           acceptLabel="Delete All"
                           rejectLabel="Cancel"
                           accept={customersBulkDelete} reject={hideDeleteProductsDialog} closable={false}/>
        </React.Fragment>
    );
}

export default AddressBook;
