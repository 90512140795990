export const LoginForm = {
    username: '',
    password: ''
};

export const UtilForm = {}

export const CreateUserForm = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    title: '',
    dob: '',
    address: '',
    country: '',
    zip: '',
    city: '',
    state: '',
    password: '',
    confirm_password: '',
    company_size: '',
    phone: ''
}

export const ResetPasswordMailForm = {
    email: '',
}

export const ResetPasswordConfirmationForm = {
    new_password: '',
    confirm_new_password: ''
}

export const PackagingDetailsForm = {
    units: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    inputOunces: '',
    inputInsurance: '',
    inputDescription: '',
}
