import {createAction} from "@reduxjs/toolkit";
//Address type
export const UPDATE_SENDER_ADDRESS = '[ADDRESS] Address sender updated ';
export const UPDATE_RECEIVER_ADDRESS = '[ADDRESS] Address receiver updated ';
export const UPDATE_RETURN_ADDRESS = '[ADDRESS] Address return updated ';
export const RESET_SENDER_ADDRESS = '[ADDRESS] Address sender reset';
export const RESET_RECEIVER_ADDRESS =  '[ADDRESS] Address receiver reset ';
export const RESET_RETURN_ADDRESS =  '[ADDRESS] Address return reset ';
export const INITIALIZE_SENDER_ADDRESS = '[ADDRESS] Address sender initialized ';
export const RETRIEVE_USER_ADDRESS = '[ADDRESS] Retrieve user address ';
export const VERIFY_ADDRESS =  '[ADDRESS] Verify address ';
export const UPDATE_VERIFIED_ADDRESS =  '[ADDRESS] Update verified address ';


export const resetSenderAddress = createAction(RESET_SENDER_ADDRESS);
export const resetReceiverAddress = createAction(RESET_RECEIVER_ADDRESS);
export const resetReturnAddress = createAction(RESET_RETURN_ADDRESS);
export const retrieveUserAddress = createAction(RETRIEVE_USER_ADDRESS);
export const verifyAddress = createAction(VERIFY_ADDRESS);

// Address type actions
export const updateSenderAddress = (senderAddress) => ({
    type: UPDATE_SENDER_ADDRESS,
    payload: senderAddress
})

export const updateReceiverAddress = (receiverAddress) => ({
    type: UPDATE_RECEIVER_ADDRESS,
    payload: receiverAddress
})

export const updateReturnAddress = (returnAddress) => ({
    type: UPDATE_RETURN_ADDRESS,
    payload: returnAddress
})

export const initializeSenderAddress = (senderAddress) => ({
    type: INITIALIZE_SENDER_ADDRESS,
    payload: senderAddress
})

export const updateVerifiedAddress = (verifiedAddress) => ({
    type: UPDATE_VERIFIED_ADDRESS,
    payload: verifiedAddress
})
