import React, {Component} from 'react';

    export const ProgressInTracker = (props) => {
    
        const progress  =  props.percentage ;
      return (
        <div className={`progressInTracker ${props.percentage ===100 ? " ship-bg-white" : ""}`} style={{ width: progress + '%' }}  > 
       </div>
      );
     
    }
   

    export const ProgressBar = (props) => {
        const percentage = props.percentage;
     const   percentageLimits=(min,value,max)=>{
            return Math.min(Math.max(min,value),max);
        }

    
    return(
    
        <div className='tracker' >            
            <ProgressInTracker percentage={percentageLimits(0,percentage,100)} />
        </div>

    )


}
export default ProgressBar ;