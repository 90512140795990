import { all, call, debounce, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
    CREATE_CUSTOMER,
    createCustomerSuccess,
    DELETE_CUSTOMER, DELETE_MULTIPLE_CUSTOMERS,
    GET_CUSTOMERS,
    getCustomersSuccess, resetCustomerState, UPDATE_CUSTOMER, SEARCH_CUSTOMERS, searchCustomersSuccess
} from "../actions/customers.actions";
import { closeNotificationModal, disableOverlay, enableOverlay, openNotificationModal } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import {
    deleteCustomer,
    deleteCustomers,
    getCustomers,
    patchCustomer,
    postCustomer,
    searchCustomers
} from "../../services/customersService";
import successIcon from "../../assets/icons/shared/success.svg";
import { updateReceiverAddress } from "../actions/addresses.actions";

/**
 * getCustomersEffect
 * @param {*} param0
 */
function* getCustomersEffect({ payload }) {
    try {
        yield put(enableOverlay('Loading customers...'));
        const customersList = yield call(getCustomers, payload.page, payload.limit, payload.filter, payload.order, payload.sort);
        yield put(getCustomersSuccess(customersList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * searchCustomersEffect
 */
function* searchCustomersEffect({ payload }) {
    try {
        const customersFound = yield call(searchCustomers, payload);
        yield put(searchCustomersSuccess(customersFound));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * createCustomerEffect
 * @param {*} param0
 */
function* createCustomerEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating new contect...'));
        const customer = yield call(postCustomer, payload);
        if (customer) {
            yield put(createCustomerSuccess(customer));
            yield put(updateReceiverAddress(customer));
            yield put(disableOverlay());
            yield put(enableOverlay('Loading contacts...'));
            yield put(resetCustomerState());
            const customersList = yield call(getCustomers, 1, 10);
            if (customersList) {
                yield put(getCustomersSuccess(customersList));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Success!',
                    message: 'Contact successfully created!',
                    icon: successIcon
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}


/**
 * updateCustomerEffect
 * @param {*} param0
 */
function* updateCustomerEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating contact...'));
        const customer = yield call(patchCustomer, payload);
        if (customer) {
            yield put(updateReceiverAddress(customer));
            yield put(disableOverlay());
            yield put(enableOverlay('Loading contacts...'));
            yield put(resetCustomerState());
            const customersList = yield call(getCustomers, 1, 10);
            if (customersList) {
                yield put(getCustomersSuccess(customersList));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Success!',
                    message: 'Contact successfully Updated!',
                    icon: successIcon
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}


/**
 * deleteCustomerEffect
 * @param {*} param0
 */
function* deleteCustomerEffect({ payload }) {
    try {
        let response;
        yield put(enableOverlay('Deleting customer in...'));
        response = yield call(deleteCustomer, payload);
        if (response) {
            yield put(resetCustomerState());
            const customersList = yield call(getCustomers, 1, 10);
            if (customersList) {
                yield put(getCustomersSuccess(customersList));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Success!',
                    message: 'Customer successfully deleted!',
                    icon: successIcon
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * deleteMultipleCustomersEffect
 * @param {*} param0
 */
function* deleteMultipleCustomersEffect({ payload }) {
    try {
        let response;
        yield put(enableOverlay('Deleting customers...'));
        response = yield call(deleteCustomers, payload);
        if (response) {
            yield put(resetCustomerState());
            const customersList = yield call(getCustomers, 1, 10);
            if (customersList) {
                yield put(getCustomersSuccess(customersList));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Success!',
                    message: 'Customers successfully deleted!',
                    icon: successIcon
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * Watchers
 */
export function* watchGetCustomers() {
    yield takeEvery(GET_CUSTOMERS, getCustomersEffect);
}

export function* watchSearchCustomers() {
    yield debounce(600, SEARCH_CUSTOMERS, searchCustomersEffect);
}

export function* watchCreateCustomer() {
    yield takeEvery(CREATE_CUSTOMER, createCustomerEffect);
}

export function* watchUpdateCustomer() {
    yield takeEvery(UPDATE_CUSTOMER, updateCustomerEffect);
}

export function* watchDeleteCustomer() {
    yield takeEvery(DELETE_CUSTOMER, deleteCustomerEffect);
}

export function* watchDeleteMultipleCustomers() {
    yield takeEvery(DELETE_MULTIPLE_CUSTOMERS, deleteMultipleCustomersEffect);
}

function* customersSaga() {
    yield all([
        fork(watchGetCustomers),
        fork(watchSearchCustomers),
        fork(watchCreateCustomer),
        fork(watchUpdateCustomer),
        fork(watchDeleteCustomer),
        fork(watchDeleteMultipleCustomers)
    ]);
}

export default customersSaga;
