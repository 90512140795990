import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { setInvoicesState, downloadPdfAction } from "../../../redux/actions/invoices.actions";
import { API_BASE_URL, API_URL_EXPORT_PDF, INVOICES } from "../../../services/APICONSTANTS";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../../utils";

const ModalTitle = ({ title, invoiceId, totalDue = 0 }) => {
  const formatCurrency = currencyFormatter(2);
  return (
    <div className="modal-title">
      <h2>{title}</h2>
      <div className="p-grid p-justify-between pdf-invoice-title">
        <h3>Total Due: {formatCurrency.format(totalDue)}</h3>
        <h3>Invoice - {invoiceId} </h3>
      </div>
    </div>
  );
};

const PdfInvoiceModal = () => {
  const dispatch = useDispatch()
  const { pdfInvoiceModal = {} } = useSelector((state) => ({
    pdfInvoiceModal: state.invoices.pdfInvoiceModal,
  }));
  const token = localStorage.getItem('sd-access');

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (pdfInvoiceModal.invoice && pdfInvoiceModal.invoice.invoiceId) {
      setPdfUrl(API_BASE_URL + INVOICES + '/' + pdfInvoiceModal.invoice.invoiceId + API_URL_EXPORT_PDF);
    }
  }, [pdfInvoiceModal.invoice]);

  const closePdfModalInvoice = ()=>{
    dispatch(setInvoicesState({
      pdfInvoiceModal: {
        open: false,
        invoice: {}
      }
    }))
  }

  const downloadPdf = () => {
    dispatch(downloadPdfAction(pdfInvoiceModal.invoice.invoiceId, pdfUrl));
  }

  return (
    <Dialog
      closable={false}
      visible
      className="pdf-invoice"
      header={
        <ModalTitle
          title={pdfInvoiceModal.invoice.company}
          invoiceId={pdfInvoiceModal.invoice.invoiceId}
          totalDue={pdfInvoiceModal.invoice.total}
        />
      }
      onHide={() => closePdfModalInvoice()}
    >
    <div className="pdf-container">
      <Document file={{url: pdfUrl, httpHeaders: { Authorization: 'Bearer ' + token }}} >
        <Page pageNumber={1} />
      </Document>
    </div>
    <div className="form-footer p-grid p-justify-between">
      <div className="p-col-4">
        <button className="cancel-button" onClick={closePdfModalInvoice}>Close</button>
      </div>
      <div className="p-col-4">
        <button className="submit-button" onClick={downloadPdf}>Download</button>
      </div>
      <div className="p-col-4">
        <button className="submit-button" onClick={closePdfModalInvoice}>Pay Invoice</button>
      </div>
    </div>
    </Dialog>
  );
};

export default PdfInvoiceModal;
