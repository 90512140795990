import React from 'react';
import {ConfirmDialog} from "primereact/confirmdialog";
import '../../styles/tracking.scss'


export const DialogVoid = (props) => {
    return(
    <ConfirmDialog visible={true}
                   className='void-confirm-dialog'
                   message="Are you sure you want to void this shipment?"
                   header="Void shipment"
                   acceptLabel="Void shipment"
                   rejectLabel="Cancel"
                   accept={props.accept} reject={props.open}
                   closable={false}/>
    );
}

export default DialogVoid;
