import logo from '../../assets/icons/on-boarding/ShipDistrict logo horiz_blk_red_1797 1.svg';
import '../../styles/OnBoarding.css';
import {Link} from 'react-router-dom';
import {CREATE_ACCOUNT, LOGIN, RESET_PASSWORD} from "../../navigation/ROUTECONSTANTS";
import {Card, CardBody, Container} from "reactstrap";
import '../../App.scss';
import {cleanSession} from "../../utils/index"
import {useEffect} from "react";


export const OnBoarding = () => {

    useEffect(() => {
        cleanSession();
    }, []);

    return (
        <Container fluid className="custom-container-padding">
            <Card className="w-100 h-100 page-content">
                <CardBody className="w-100">
                    <div className="col-12">
                        <div className="row on-boarding-section">
                            <div className="row w-100 justify-content-center mb-5">
                                <img src={logo} alt="ShipDistrict" height="142" width="320"/>
                            </div>
                            <div className="row w-100 justify-content-center mt-5">
                                <Link to={LOGIN} type="button" className="w-100 ship-primary-custom-button"
                                      style={{maxWidth: "333px"}}>Login</Link>
                            </div>
                            <div className="row w-100 justify-content-center mt-3">
                                <Link to={CREATE_ACCOUNT}
                                      className="w-100 ship-secondary-custom-button font-weight-bold"
                                      style={{maxWidth: "333px"}}>Create new account</Link>
                            </div>
                            <div className="row w-100 justify-content-center mt-3">
                                <Link className="forgot-password" to={RESET_PASSWORD}>I forgot my password</Link>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Container>
    )
}

export default OnBoarding;
