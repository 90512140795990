import { GET_QUOTES_SUCCESS, CREATE_QUOTE_SUCCESS, UPDATE_QUOTE_REQUEST, RESET_QUOTE, RESET_QUOTE_REQUEST_STATE, UPDATE_QUOTE_REQUEST_COURIER } from "../actions/quotes.actions";

const initialQuotesState = { quotesList: [], next: null, previous: null, count: 0 };

export const QuotesReducer = (state = initialQuotesState, action) => {
    switch (action.type) {
        case GET_QUOTES_SUCCESS:
            return {
                ...state, quotesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count
            };
        default:
            return state;
    }
}

const initialQuoteState = {
    id: null,
    sender: {
        country: "",
        city: "",
        state: "",
        zip: "",
    },
    receiver: {
        country: "",
        city: "",
        state: "",
        zip: "",
        email: ""
    },
    pieces: [
        {
            weight: "",
            length: "",
            width: "",
            height: "",
            insurance_amount: "",
            declaredValue: 0,
            quote: 0
        }
    ],
    quotation: {
        id: null,
        surcharges: [
            {
                description: "",
                amount: ""
            },
            {
                description: "",
                amount: ""
            }
        ],
        currency: "",
        customsCurrency: "",
        totalAmount: ".",
        baseAmount: "",
        zone: null,
        quotedWeight: "",
        quotedWeightType: ""
    },
    carrierCode: "",
    serviceCode: "",
    packageTypeCode: "",
    residential: false,
    signatureOptionCode: "",
    uspsExpressAmDelivery: false,
    saturdayDelivery: false,
    contentDescription: "f",
    weightUnit: "",
    dimUnit: "",
    currency: "",
    customsCurrency: ""
}

export const QuoteReducer = (state = initialQuoteState, action) => {
    switch (action.type) {
        case CREATE_QUOTE_SUCCESS:
            return { ...state, ...action.payload }
        case RESET_QUOTE:
            return initialQuoteState;
        default:
            return state;
    }
}

const initialQuoteRequestState = {
    sender: null,
    receiver: null,
    pieces: [],
    residential: true,
    contentDescription: "",
    service_type: NaN,
    package_type: NaN,
    weightUnit:"lb",
    dimUnit:"in",
    billing: {
        party: "sender"
    }
}

export const QuoteRequestReducer = (state = initialQuoteRequestState, action) => {
    switch (action.type) {
        case UPDATE_QUOTE_REQUEST:
            return { ...state, ...action.payload }
        case UPDATE_QUOTE_REQUEST_COURIER:
            return {...state, courier: action.payload}
        case RESET_QUOTE_REQUEST_STATE:
            return initialQuoteRequestState;
        default:
            return state;
    }
}
