import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import '../../styles/NotifationModal.css';
import Typography from '@material-ui/core/Typography';



const NotificationModal = ({title, message, icon, isOpen}) => {


    const [open, setOpen] = useState(isOpen)
    let iconDisplay;
    let titleDisplay;

    const styles = () => ({
        root: {
            margin: 0,
            padding: 0,
            backdropFilter: "blur(3px)",
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h5" className="font-weight-bold">{children}</Typography>
            </MuiDialogTitle>
        );
    });

    const handleClose = () => {
        setOpen(!isOpen);
    };

    if (icon) {
        iconDisplay = <img src={icon} alt="Icon" height="168" width="168"/>
    }

    if (title) {
        titleDisplay = <DialogTitle>{title}</DialogTitle>
    }



    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title"  open={open} disableBackdropClick={true}>
            <DialogTitle className="font-weight-bold" id="simple-dialog-title">
                <div className="row justify-content-center text-center p-0">
                    {iconDisplay}
                </div>
                <div className="row justify-content-center text-center ">
                    {titleDisplay}
                </div>
            </DialogTitle>
            <DialogContent className="pt-0">
                <p className="notification-modal-content">
                    {message}
                </p>
            </DialogContent>
            {/*                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>*/}
        </Dialog>
    );
}

export default NotificationModal;
