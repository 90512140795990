import axios from "axios";
import {API_BASE_URL, QUOTES} from "./APICONSTANTS";

export const getQuotes = () => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('sd-access');
            // do db call or API endpoint axios call here and return the promise.
            axios.get(API_BASE_URL + QUOTES, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((res) => {
                    console.log("Quotes loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get quotes error: ", err);
                    reject("Get quotes error!");
                });
        } catch (error) {
            console.error("Get quotes error!==", error);
            reject("Get quotes error!");
        }
    });
};

export const postQuote = (quote) => {
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('sd-access');
            // do db call or API endpoint axios call here and return the promise.
            axios.post(API_BASE_URL + QUOTES, quote, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((res) => {
                    console.log("Quote added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post quote error: ", err);
                    reject("signin error!");
                });
        } catch (error) {
            console.error("Post quote error!==", error);
            reject("Post quote error!");
        }
    });
};
