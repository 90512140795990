import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInvoicesState } from "../../redux/actions/invoices.actions";
import { getAmountsPerStatus } from "../../services/invoicesService";
import { currencyFormatter } from "../../utils";

const InvoicesHeader = () => {
  const dispatch = useDispatch();
  const selectedType = useSelector(state => state.invoices.selectedType);

  const [recivableAmount, setRecivableAmount] = useState(0);

  const formatCurrency = currencyFormatter(2);

  useEffect(() => {
    getAmountsPerStatus().then((responseData) => {
        let recivableStat = responseData.find((stat) => stat.status === 'CREATED');
        if (recivableStat) {
          setRecivableAmount(recivableStat.amount);
        } else {
          setRecivableAmount(0);
        }
    });
  }, [])

  const changeType = (type) => {
    dispatch(setInvoicesState({ selectedType: type }));
  };

  return (
    <div className="invoices-header">
      <div className="d-flex justify-content-between align-items-center">
        <div className="title-container">
          <div className="title">Invoices</div>
          <div className="account-total">
            Accounts Receivable: <span>{formatCurrency.format(recivableAmount)}</span>
          </div>
        </div>
        <div className="d-flex button-container">
          <button
            onClick={() => changeType("unpaid")}
            className={selectedType === "unpaid" ? "selected" : ""}
          >
            Unpaid Invoices
          </button>
          <button
            onClick={() => changeType("paid")}
            className={selectedType === "paid" ? "selected" : ""}
          >
            Paid Invoices
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicesHeader;
