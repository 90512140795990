import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import iconfedex from '../../assets/icons/shipment/fedex.png';
import icondhl from '../../assets/icons/shipment/dhl.png';
import iconups from '../../assets/icons/shipment/ups.png';
import iconusps from '../../assets/icons/shipment/usps.png';
import {ServiceCarrierFedex, ServiceCarrierUsps} from "../../assets/shipment/ServiceCarrier";
import {SelectPackagingDetails} from "./SelectPackagingDetails";
import AdditionalInformation from './AdditionalInformation';
import {AppContext} from './Provider';
import {
    resetQuoteRequestState,
    updateCourierQuoteRequest,
    updateQuoteRequest
} from "../../redux/actions/quotes.actions";
import {getServices} from "../../redux/actions/services.actions";
import {
    resetShipmentRequestState,
    updateCourierShipmentRequest,
    updateShipmentRequest
} from "../../redux/actions/shipments.actions";
import {Col, Row} from "reactstrap";
import Select, {components} from 'react-select';
import {getSignatures} from "../../redux/actions/signatures.actions";
import {getCouriers} from "../../redux/actions/couriers.actions";
import DialogUSPSServicesInformation from '../../components/shipment/DialogUSPSServicesInformation';

export default function ShipmentDetails(props) {
    const [activeIndex, setActiveIndex] = useState(NaN);
    const [serviceActiveCarrier, setServiceActiveCarrier] = useState([]);
    const [stato, setStato] = useContext(AppContext);
    const [serviceID, setServiceID] = useState(null);
    const quoteRequest = useSelector(state => state.quoteRequest);
    const shipmentRequest = useSelector(state => state.shipmentRequest);
    const services = useSelector(state => state.services);
    const couriersActive = useSelector(state => state.couriers);
    const [showUSPSServiceInformation, setShowUSPSServiceInformation] = useState(false);
    const dispatch = useDispatch();

    const couriers = couriersActive.map((courier) => courier.courier);
    const iconCouriers = {
        'FEDEX': iconfedex,
        'UPS': iconups,
        'USPS': iconusps,
        'DHL': icondhl
    };

    const customStylesSearchble = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '0px',
            backgroundColor: 'white',
            color: state.isSelected ? '#1F2D3D' : '#9ea3b0',
            fontWeight: state.isSelected ? 'bold' : 'normal',
            borderRadius: '10px',
            fontSize: "14px !important",
            fontWeight: "500 !important",
            '&:hover': {
                backgroundColor: '#F6F7FB',
                fontWeight: 'bold',
                color: '#1F2D3D'
            }
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            paddingLeft: '10px',
        }),
        indicatorsContainer: () => ({
            backgroundColor: '#F6F7FB',
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            display: 'inline'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: 'none'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: 'clear',
            borderWidth: '0px',
            boxShadow: 'none',
            padding: '0px',
            outline: 'none',
            backgroundColor: '#F6F7FB',
            borderRadius: '8px'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0 : 1;
            const transition = 'opacity 300ms';
            const fontSize = "14px !important";
            const fontWeight = "600 !important";
            return {...provided, opacity, transition, fontWeight, fontSize};
        },
        menuList: (base) => ({
            ...base,
            "::-webkit-scrollbar": {
                width: "9px"
            },
            "::-webkit-scrollbar-track": {
                background: "white"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#D4DAE9",
                borderRadius: '100px'
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#D4DAE9"
            }
        })
    }

    const customStylesSelectedService = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '0px',
            backgroundColor: 'white',
            color: state.isSelected ? '#1F2D3D' : '#9ea3b0',
            cursor: 'pointer',
            fontWeight: state.isSelected ? 'bold' : 'normal',
            borderRadius: '10px',
            fontSize: "14px !important",
            fontWeight: "500 !important",
            '&:hover': {
                backgroundColor: '#F6F7FB',
                fontWeight: 'bold',
                color: '#1F2D3D'
            }
        }),
        container: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            borderColor: '#D4DAE9',
            borderWidth: '1px',
            borderRadius: '10px'
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            paddingLeft: '10px',
        }),
        indicatorsContainer: () => ({
            backgroundColor: 'white',
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            display: state.menuIsOpen ? 'inline' : 'none'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: state.menuIsOpen ? 'none' : 'inline'
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            border: '1px solid #D4DAE9',
            boxShadow: 'none',
            padding: '0px',
            outline: 'none',
            borderRadius: '10px',
            backgroundColor: 'white',
            '&:hover': {
                borderColor: '#D4DAE9',
                borderWidth: '1px',
                boxShadow: 'none',
            }
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0 : 1;
            const transition = 'opacity 300ms';
            const fontSize = "14px !important";
            const fontWeight = "600 !important";
            return {...provided, opacity, transition, fontWeight, fontSize};
        },
        menuList: (base) => ({
            ...base,
            cursor: 'pointer',
            "::-webkit-scrollbar": {
                width: "9px"
            },
            "::-webkit-scrollbar-track": {
                background: "white"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#D4DAE9",
                borderRadius: '100px'
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#D4DAE9"
            }
        })
    }

    const SearchIcon = () => {
        return <React.Fragment><a href="#" className="fas fa-search"></a></React.Fragment>
    };

    const Control = ({children, ...props}) => {
        return (
            <components.Control {...props}>
        <span>
          <SearchIcon/>
        </span>
                {children}
            </components.Control>
        );
    };

    useEffect(() => {
        dispatch(getServices());
        dispatch(getSignatures());
        dispatch(getCouriers());
    }, []);

    useEffect(() => {
        var activeServices = [];
        if (!Number.isNaN(activeIndex)) {
            var courier = couriersActive[activeIndex];
            switch (courier.courier) {
                case 'FEDEX':
                    activeServices = services.filter((element) => element.courier == 'FEDEX').map(serviceOption =>
                        ({label: serviceOption.name, value: serviceOption.id}));
                    break;
                case 'UPS':
                    activeServices = services.filter((element) => element.courier == 'UPS').map(serviceOption =>
                        ({label: serviceOption.name, value: serviceOption.id}));
                    break;
                case 'USPS':
                    activeServices = ServiceCarrierUsps.map(serviceOption =>
                        ({label: serviceOption.name, value: serviceOption.id}));
                    break;
                default:
                    activeServices = ServiceCarrierFedex.map(serviceOption =>
                        ({label: serviceOption.name, value: serviceOption.id}));
                    break;
            }
        }
        setServiceActiveCarrier(activeServices);
    }, [services, activeIndex]);

    useEffect(() => {
        couriersActive.sort((a, b) => a.courier.localeCompare(b.courier));

        // Select Courier if only one is active
        let countActiveCouriers = couriersActive.reduce((counter, courier) => courier.active ? counter + 1 : counter, 0);
        if (countActiveCouriers == 1) {
            let newActiveIndex = couriersActive.findIndex((courier) => courier.active);
            updateShipmentCarrierSelected(newActiveIndex);
        }
    }, [couriersActive])

    const updateShipmentCarrierSelected = (activeIndex) => {
        var courier = couriersActive[activeIndex];

        if (courier.courier == 'USPS') {
            setShowUSPSServiceInformation(true);
        }
        setServiceID('');
        setActiveIndex(activeIndex);
        setStato({...stato, selectedCarrier: courier.courier, isPackselected: false});
        dispatch(resetQuoteRequestState());
        dispatch(resetShipmentRequestState());

        dispatch(updateCourierQuoteRequest(courier.courier));
        dispatch(updateCourierShipmentRequest(courier.courier));
    }

    const updateShipmentServiceSelected = (service) => {
        setServiceID(service.value);
        quoteRequest.service_type = service.value;
        shipmentRequest.service_type = service.value;
        dispatch(updateQuoteRequest(quoteRequest));
        dispatch(updateShipmentRequest(shipmentRequest));
    }

    return (
        <div className="mt-3 mb-2 pb-4">
            <Row className="ship-row pl-2">
                <Col className="pl-0">
                    <Col xs="12" sm="12" className="pl-0">
                        <div className="h4 mt-3 pl-0 font-weight-bold">Select Shipment Carrier</div>
                        <div className="item-sx mrg-tb20">Please select your shipment carrier:</div>
                    </Col>
                    {couriersActive.length > 0 &&
                    <nav className="card-group" id="navbarMain">
                        {couriersActive.map((courier, mapIndex) => {
                            if (courier.active) {
                                return <a key={mapIndex}
                                    className={activeIndex == mapIndex ? "ship-card-carrier activeC" : "ship-card-carrier overlaygray"}
                                    onClick={() => updateShipmentCarrierSelected(mapIndex)}><img
                                    className="card-img-top"
                                    src={iconCouriers[courier.courier]} alt={courier.courier} /></a>;
                            } else {
                                return <a key={mapIndex} className="ship-card-disabled-carrier overlaygray">
                                        <img className="card-img-top" src={iconCouriers[courier.courier]} alt={courier.courier} title="Not available"/>
                                    </a>;
                            }
                        })}
                    </nav>
                    }
                    {!Number.isNaN(activeIndex) &&
                    <React.Fragment>
                        <Row>
                            <Col xs="5" sm="5">
                                <div className="h6 font-weight-bold">Choose
                                    a {serviceID ? '' : couriers[activeIndex]} Service
                                </div>
                                <div
                                    className="item-sx mrg-tb20">{serviceID ? 'How fast do you want this delivered?' : 'Please select your preferred delivery method'}</div>
                            </Col>
                            {!serviceID && <Col xs="5" sm="5">
                                <Select isClearable {...props} styles={customStylesSearchble}
                                        options={serviceActiveCarrier} placeholder="Search Services"
                                        noOptionsMessage={() => 'No services found.'} components={{Control}}
                                        onChange={updateShipmentServiceSelected}/>
                            </Col>
                            }
                        </Row>
                        {serviceID &&
                        <Row className="pl-0">
                            <Col xs="12" sm="12">
                                <Select isSearchable={false} {...props} styles={customStylesSelectedService}
                                        options={serviceActiveCarrier} placeholder="Search Services" defaultValue={
                                    serviceActiveCarrier.filter(option =>
                                        option.value === serviceID)}
                                        noOptionsMessage={() => 'No services found.'}
                                        onChange={updateShipmentServiceSelected}/>
                                {serviceID && <SelectPackagingDetails service={serviceID}
                                                                      showPackagingDetail={props.showPackagingDetail}/>}
                            </Col>
                        </Row>
                        }
                    </React.Fragment>
                    }
                </Col>
                <Col xs="12" sm="12">
                    {(!Number.isNaN(activeIndex) && serviceID) &&
                    <AdditionalInformation showCommercialInvoice={(e) => props.showCommercialInvoice(e)}
                                           isCommercialInvoiceActive={props.isCommercialInvoiceActive}
                                           showUpdates={props.updateShowUpdates} courier={couriers[activeIndex]}/>}
                </Col>
            </Row>
            <DialogUSPSServicesInformation open={showUSPSServiceInformation} close={() => setShowUSPSServiceInformation(false)}/>
        </div>
    );
}
