import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { setAdminInvoicesState } from "../../../../redux/actions/admin.invoices.actions";

const ButtonsColumn = ({rowData}) => {
  return (
    <div className={`invoice-status invoice-status_${rowData.status}`}>
      {rowData.status}
    </div>
  )
}

const InvoicesTable = ()=>{
  const dispatch = useDispatch();
  const selectedAccountInvoices = useSelector((state) => state.adminInvoices.selectedAccountInvoices);

  const openPdfModalInvoice = (value)=>{
    console.log({value});
    if (value?.status === "CANCELLED") {
      return;
    }
    dispatch(setAdminInvoicesState({
      pdfInvoiceModal: {
        open: true,
        invoice: {
          ...value,
          resend: Math.random()>0.5?true: false,
        }
      }
    }))
  }
  return (
    <div className="invoicesTableContainer">
    <DataTable
      value={selectedAccountInvoices}
      className="p-datatable-striped"
      selectionMode="single"
      onSelectionChange={(e)=> openPdfModalInvoice(e.value)}
    >
      <Column className="invoiceId" field="invoiceId" header="Past Invoices #"></Column>
      <Column
        field="status"
        header="Status"
        body={(rowData) => <ButtonsColumn rowData={rowData} />}
      ></Column>
    </DataTable>
    </div>
  )
}

export default InvoicesTable;
