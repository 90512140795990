import { GET_SERVICES_SUCCESS } from "../actions/services.actions";

const initialServicesState = [];

export const ServicesReducer = (state = initialServicesState, action) => {
    switch (action.type) {
        case GET_SERVICES_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
}