import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Container, Progress} from "reactstrap";
import logo from "../../assets/icons/on-boarding/ShipDistrict logo horiz_blk_red_1797 1.svg";
import {LOGIN} from "../../navigation/ROUTECONSTANTS";
import OnBoardingCarousel from "../../components/onboarding/OnBoardingCarouselComponent";
import {Link, useHistory} from 'react-router-dom';
import '../../App.scss';
import CreateUserCredentialsComponent from "../../components/onboarding/CreateUserCredentialsComponent";
import CreateUserDetailsComponent from "../../components/onboarding/CreateUserDetailsComponent";
import {
    createUser,
    createUserSuccess,
    updateUserProfile
} from "../../redux/actions/users.actions";
import {cleanSession} from "../../utils/index"


export const CreateAccount = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    let checkRegistration = useSelector(state => state.users.emailRegister);
    const [stepper, setStepper] = useState({step: 'Step 1', progress: 35});
    let stepForm;
    let stepTitle;
    let bottomLogo;

    useEffect(() => {
        cleanSession();
        if(!localStorage.getItem('sd-user')) {
            dispatch(createUserSuccess(null));
        }
    }, [])


    useEffect(() => {
        return () => {
            dispatch(createUserSuccess(null));
        }
    }, []);

    function createNewUser(data, event) {
        event.preventDefault();
        const newUserData = {username: data.username, email: data.email, password: data.password}
        if (stepper.step === 'Step 1') {
            dispatch(createUser(newUserData, history));
        }
        if (stepper.step === 'Step 2') {
            dispatch(updateUserProfile(data, history, false));
        }
    }

    function emailRegistrationValid() {
        setStepper({step: 'Step 2', progress: 70});
    }

    useEffect(() => {
        if (checkRegistration) {
            emailRegistrationValid();
        }
    }, [checkRegistration])


    if (stepper.step === 'Step 1') {
        stepTitle = 'New Account';
        bottomLogo = (
            <div className="row justify-content-center on-boarding-login-section mt-5">
                <img src={logo} alt="ShipDistrict" height="80" width="180"/>
            </div>);
        stepForm = <CreateUserCredentialsComponent onSubmit={(data, e) => createNewUser(data, e)}/>
    }

    if (stepper.step === 'Step 2') {
        stepTitle = 'Profile Setup';
        bottomLogo = null;
        stepForm =
            <CreateUserDetailsComponent onSubmit={(data, e) => createNewUser(data, e)}/>
    }

    return (
            <Container fluid className="custom-container-padding">
                <Card className="w-100 mt-3 mb-3 page-content">
                    <CardBody className="w-100">
                        <div className="row">
                            <div className="col-9 col-xs-6 col-sm-6 col-md-8 col-xl-9">
                                <div className="row justify-content-center on-boarding-login-section mb-2">
                                    <span className="text-center">{stepper.step}</span>
                                </div>
                                <div className="row justify-content-center mb-5">
                                    <Progress color="danger" className="text-center w-25" style={{height: "5px"}}
                                              value={stepper.progress}/>
                                </div>
                                <div className="row justify-content-center">
                                    <h4 className="mt-3">{stepTitle}</h4>
                                </div>
                                {stepForm}
                                <div className="row justify-content-center mt-5">
                                    <span className="sign-up-label">Already have an account? &nbsp;</span>
                                    <Link className="sign-up-label font-weight-bold" to={LOGIN}>Login</Link>
                                </div>
                                {bottomLogo}
                            </div>
                            <div className="col-3 col-xs-6 col-sm-6 col-md-4 col-xl-3">
                                <OnBoardingCarousel/>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
    )
}

export default CreateAccount;
