import React, {useState} from "react";

import {Button, Form, FormGroup, Input, InputGroup, InputGroupAddon,} from "reactstrap";

import {Link} from "react-router-dom";

// Import menuDropdown
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
//Import logo Images
import logo from "../../assets/images/logo.svg";
import ProfileMenu from "../commonForBoth/ProfileMenu";

export const Header = (props) => {

    const [isSearch, setIsSearch] = useState(false);

    function toggleSidebar(e) {
        e.preventDefault();

        if (!document.body) {
            return true;
        }
        document.body.classList.toggle('sidebar-enable');
        document.body.classList.toggle('vertical-collpsed');
    };

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="32"/>
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" height="30"/>
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="32"/>
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" height="30"/>
                                </span>
                            </Link>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" onClick={toggleSidebar}>
                            <i className="ri-menu-2-line align-middle"></i>
                        </button>

                        <Form className="app-search d-none d-lg-block ml-5">
                            <div className="position-relative">
                                <Input type="text" className="form-control" placeholder={'Search'}/>
                                <span className="ri-search-line"></span>
                            </div>
                        </Form>

                    </div>

                    <div className="d-flex">

                        <div className="dropdown d-inline-block d-lg-none ml-2">
                            <button type="button" onClick={() => {
                                setIsSearch(!isSearch)
                            }} className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                <i className="ri-search-line"></i>
                            </button>
                            <div
                                className={isSearch === true ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                aria-labelledby="page-header-search-dropdown">

                                <Form className="p-3">
                                    <FormGroup className="m-0">
                                        <InputGroup>
                                            <Input type="text" className="form-control"
                                                   placeholder={'Search'}/>
                                            <InputGroupAddon addonType="append">
                                                <Button color="primary" type="submit"><i className="ri-search-line"></i></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>

                        <ProfileMenu/>

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}
export default Header;
