import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import NewEditInvoiceForm from "./newEditInvoiceForm";
import image from "../../../assets/images/modal-header-logo.png";
import { Col, Row, Container } from "reactstrap";
import { setAdminInvoicesState } from "../../../redux/actions/admin.invoices.actions";

const ModalTitle = ({ title }) => {
    return (
        <Container className="modal-title">
            <Row>
                <Col className="d-flex">
                    <h2 className="mr-auto p-2">{title}</h2>
                    <img className="p-2" src={image} alt="modal-header-logo" />
                </Col>
            </Row>
        </Container>
    );
};

const NewEditInvoiceModal = () => {
    const { newEditInvoiceModal = {} } = useSelector((state) => ({
        newEditInvoiceModal: state.adminInvoices.newEditInvoiceModal,
    }));

    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(setAdminInvoicesState({
            newEditInvoiceModal: {
                open: false,
            }
        }));
    };

    const isEditInvoice = ('invoice' in newEditInvoiceModal && newEditInvoiceModal.invoice.invoiceId > 0);

    const modalTitle = isEditInvoice ? 'Edit Invoice' : 'New Invoice';

    return (
        <Dialog
            closable={false}
            header={ <ModalTitle title={modalTitle} /> }
            visible={newEditInvoiceModal.open}
            onHide={onCancel}
        >
            <NewEditInvoiceForm isEditInvoice={isEditInvoice} editInvoiceData={isEditInvoice ? newEditInvoiceModal.invoice : null} />
        </Dialog>
    );
};

export default NewEditInvoiceModal;