import React, {useEffect, useState} from 'react';
import {Container} from "reactstrap";
import '../../styles/shipment.css';
import {CardAddress} from "../../components/shipment/CardAddress";
import {CardQuote} from "../../components/shipment/shippingQuote";
import {EditAddress} from "../../components/shipment/EditAddress";
import {SearchCustomers} from "../../components/shipment/SearchCustomers";
import {ProgressBar} from "../../components/shipment/ProgressBar";
import ShipmentDetails from "../../components/shipment/ShipmentDetails";
import Provider from '../../components/shipment/Provider';
import PackagingDetails from '../../components/shipment/PackagingDetails';
import {useDispatch, useSelector} from "react-redux";
import {resetShipmentRequestState, resetShipmentResponseState} from '../../redux/actions/shipments.actions';
import {
    resetReceiverAddress,
    retrieveUserAddress,
    updateReceiverAddress,
    updateReturnAddress,
    updateSenderAddress
} from '../../redux/actions/addresses.actions';
import CommercialInvoice from '../../components/shipment/CommercialInvoice';
import {resetQuote, resetQuoteRequestState} from '../../redux/actions/quotes.actions';
import ShippingUpdates from '../../components/shipment/ShippingUpdates';
import {resetCommercialInvoice} from '../../redux/actions/commercial.invoices.action';
import AddressBookDialog from "../../components/addressbook/AddressBookDialog";
import CreateEditContactDialog from "../../components/addressbook/CreateEditContactDialog";
import {createCustomer} from "../../redux/actions/customers.actions";
import {Checkbox} from "primereact/checkbox";

export const editorAddressType = {
    RETURN: "return",
    SENDER: "sender",
    RECEIVER: "receiver"
}


export const Shipment = (props) => {

    let localeDate = new Date().toLocaleDateString()
    const [actualeditorAddressType, setActualeditorAddressType] = useState(editorAddressType.SENDER);
    const [showHideQuote, setShowHideQuote] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showreceiverAddress, setShowreceiverAddress] = useState(false);
    const [showPackagingDetails, setShowPackagingDetails] = useState(false);
    const [showShipmentDetails, setShowShipmentDetails] = useState(true);
    const [showUpdates, setShowUpdates] = useState(false);
    const [showReturnAddress, setshowReturnAddress] = useState(false);
    const [showCommercialInvoice, setShowCommercialInvoice] = useState(false);
    const [currentTime, setCurrentTime] = useState(localeDate);
    const [percentage, setPercentage] = useState(20);
    const [curier, setCurier] = useState(0);
    const dispatch = useDispatch();
    const isShimpmentCompleted = useSelector(state => state.shipmentResponse.isShipmentSent);
    const shipmentCourier = useSelector(state => state.shipmentResponse.shipment.courier);
    const [showAddressBookDialog, setShowAddressBookDialog] = useState(false);
    const [openNewContactDialog, setOpenNewContactDialog] = useState(false);
    const [isQuoteValid, setIsQuoteValid] = useState(false);
    const quoteRequest = useSelector(state => state.quoteRequest);

    useEffect(() => {
        if (isShimpmentCompleted) {
            setPercentage(100);
        }
    });

    useEffect(() => {
        dispatch(resetShipmentRequestState());
        dispatch(resetShipmentResponseState());
        dispatch(retrieveUserAddress());
        dispatch(resetReceiverAddress());
        dispatch(resetQuoteRequestState());
        dispatch(resetQuote());
        dispatch(resetCommercialInvoice());
    }, []);

    useEffect(() => {
        checkRequest();
    }, [quoteRequest]);

    useEffect(() => {
        if (isQuoteValid) {
            setShowHideQuote(true);
        }
    }, [isQuoteValid]);

    const checkRequest = () => {
        if (quoteRequest.sender && quoteRequest.receiver && quoteRequest.service_type && quoteRequest.signature &&
            (quoteRequest.pieces || quoteRequest.pieces.length === 0)) {
            setIsQuoteValid(true);
        }
    }

    const hideComponent = (name) => {
        if (name) {
            setShowHideQuote(!showHideQuote);
        }
        if (showEdit) {
            setShowEdit(!showEdit)
        } //check box edit open
        setShowCommercialInvoice(false)
    }

    const updateShowEdit = (editorType) => {
        if (editorType == actualeditorAddressType) {
            setShowEdit(!showEdit) //show hide Edit
        } else {
            if (showEdit) {
                setActualeditorAddressType(editorType)
            } else {
                setShowEdit(true)
                setActualeditorAddressType(editorType)
            }
        }
        if (showHideQuote) {
            setShowHideQuote(!showHideQuote)
        } //check quote box open
        console.log("Edit", showEdit, showHideQuote);
    }

    const saveEdit = () => {
        setShowEdit(false)
    }

    const updateShowReturnAddress = () => {
        setshowReturnAddress(!showReturnAddress);
        if (actualeditorAddressType == editorAddressType.RETURN && showReturnAddress) {
            setShowEdit(false)
        }
        setShowCommercialInvoice(false);
    }

    const updatedShowReceiverAddress = (selectedCustomer) => {
        setShowreceiverAddress(true);
        setShowCommercialInvoice(false);
        setPercentage(60);
        dispatch(updateReceiverAddress(selectedCustomer));
    }

    const updateShowPackagingDetails = (showPackagingDetails) => {
        setShowEdit(false)
        setShowCommercialInvoice(false)
        setShowHideQuote(!showPackagingDetails)
        setShowPackagingDetails(showPackagingDetails);
        setPercentage(80);
    }

    const updateShowCommercialInvoice = (showCommercialInvoice) => {
        setShowEdit(false);
        setShowHideQuote(!showCommercialInvoice)
        setShowPackagingDetails(false);
        setShowCommercialInvoice(showCommercialInvoice)
    }

    const updateShowUpdates = () => {
        setShowUpdates(!showUpdates);
    }

    const openAddressBookDialog = (editorType) => {
        setActualeditorAddressType(editorType);
        setShowAddressBookDialog(true);
    }

    const updateAddressBook = (selectedAddressBook) => {
        switch (actualeditorAddressType) {
            case editorAddressType.SENDER:
                dispatch(updateSenderAddress(selectedAddressBook));
                break;
            case editorAddressType.RECEIVER:
                dispatch(updateReceiverAddress(selectedAddressBook));
                setShowreceiverAddress(true);
                break;
            case editorAddressType.RETURN:
                dispatch(updateReturnAddress(selectedAddressBook));
            default:
                break;
        }
    }

    const saveCustomer = (customer) => {
        dispatch(createCustomer(customer))
        setOpenNewContactDialog(false);
        setShowreceiverAddress(true);
    }

    console.log('Ship: ', curier)
    return (
        <Provider>
            <div className="page-content shipment">
                <Container fluid>
                    <div>
                        <div className={`row ship-head pl-3 borderC   ${percentage >= 100 ? "head-red" : "test "}`}>
                            <div className="col-sm-4 pt-3 ">
                                <div
                                    className={`h2 font-weight-bold mt-2  ${percentage >= 100 ? " head-red" : " "}`}>
                                    New Shipment
                                </div>
                                <div className={`ship-order ${percentage >= 100 ? "head-red" : " "}`}>Order #:
                                    390239SLD540
                                </div>
                            </div>
                            <div className={`col-sm-3 pt-4 date-ship ${percentage >= 100 ? " head-red" : " "}`}>
                                Shipping Date: {currentTime}
                                <ProgressBar percentage={percentage}/>
                            </div>
                            <div
                                className={`col-sm-2 pt-4 ship-progress ${percentage >= 100 ? " head-red" : " "}`}> {percentage} %
                                Progress
                            </div>
                            <div className="col-sm-3 pt-4 item-dx">
                                {percentage < 100 &&
                                <button type="button" onClick={() => hideComponent("showHideQuote")}
                                        className={`btn btn-outline-danger ${percentage >= 100 ? "head-red ship-border-w" : " "}`}>
                                        Update Quote
                                </button>
                                }
                            </div>
                        </div>
                        {!isShimpmentCompleted &&
                        <div className="row ship-row shipment-background">
                            <div className="col-sm-6 ship-column">
                                <CardAddress showEditor={() => updateShowEdit(editorAddressType.SENDER)}
                                             titleCard="Sender Address"
                                             openAddressBookDialog={() => openAddressBookDialog(editorAddressType.SENDER)}
                                             tipoCard={editorAddressType.SENDER}/>
                                <div className="row col-sm align-content-center mt-2">
                                    <Checkbox inputId="returnAddress" onChange={() => updateShowReturnAddress()}
                                              checked={showReturnAddress}/>
                                    <label htmlFor="returnAddress" className="ml-2 font-weight-bold"
                                           style={{marginTop: '1px'}}>Return address is different from sender
                                        address</label>
                                </div>
                                {(showReturnAddress) &&
                                <CardAddress showEditor={() => updateShowEdit(editorAddressType.RETURN)}
                                             openAddressBookDialog={() => openAddressBookDialog(editorAddressType.RETURN)}
                                             titleCard="Return Address" tipoCard={editorAddressType.RETURN}/>}
                                {(showreceiverAddress) &&
                                <CardAddress showEditor={() => updateShowEdit(editorAddressType.RECEIVER)}
                                             openAddressBookDialog={() => openAddressBookDialog(editorAddressType.RECEIVER)}
                                             titleCard="Receiver Address"
                                             tipoCard={editorAddressType.RECEIVER}/>}

                                {(!showreceiverAddress) &&
                                <SearchCustomers updatedShowReceiverAddress={updatedShowReceiverAddress}
                                                 openAddressBookDialog={() => openAddressBookDialog(editorAddressType.RECEIVER)}
                                                 addNewContact={() => setOpenNewContactDialog(true)}
                                />}
                                {(showreceiverAddress) && showShipmentDetails &&
                                <ShipmentDetails curier={curier} showPackagingDetail={updateShowPackagingDetails}
                                                 isCommercialInvoiceActive={showCommercialInvoice}
                                                 showCommercialInvoice={(e) => updateShowCommercialInvoice(e)}
                                                 updateShowUpdates={updateShowUpdates}/>}
                            </div>

                            <div className="col-sm-6 ship-column">
                                {showEdit &&
                                <EditAddress type={actualeditorAddressType} hideEditor={() => saveEdit()}
                                             cancel={() => setShowEdit(false)}/>}
                                {showHideQuote && <CardQuote titlecard="Shipping Quote"/>}

                                {showPackagingDetails &&
                                <PackagingDetails updateShowPackagingDetails={() => updateShowPackagingDetails()}/>}
                                {showCommercialInvoice &&
                                <CommercialInvoice hideCommercialInvoice={(e) => updateShowCommercialInvoice(e)}/>}
                                {showUpdates && <ShippingUpdates cancel={() => setShowUpdates(false)}/>}
                            </div>
                        </div>
                        }
                    </div>
                    <CreateEditContactDialog open={openNewContactDialog} title={'Create new contact'}
                                             close={() => setOpenNewContactDialog(false)}
                                             submit={(customer) => saveCustomer(customer)}/>

                    <AddressBookDialog open={showAddressBookDialog}
                                       confirm={(selectedAddressBook) => updateAddressBook(selectedAddressBook)}
                                       onClose={() => setShowAddressBookDialog(false)}/>
                </Container>
            </div>
        </Provider>
    )
}
export default Shipment;
