import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {logout} from "../../redux/actions/auth.actions";

const Logout = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout(history));
    }, [])


    return (
        <React.Fragment>
            <h1>&nbsp;</h1>
        </React.Fragment>
    );

}

export default Logout;

