import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateAdminSettingsValue } from "../../redux/actions/admin.settings.actions";


export const InvoiceFeeConfiguration = () => {
    const dispatch = useDispatch();
    const invoicesMarginFeePercentage = useSelector(state => state.adminSettings.invoicesMarginFeePercentage);
    const invoicesMarginFeeLabel = useSelector(state => state.adminSettings.invoicesMarginFeeLabel);

    const setFormValues = () => {return {
        percentage: invoicesMarginFeePercentage ? invoicesMarginFeePercentage : 0,
        label: invoicesMarginFeeLabel ? invoicesMarginFeeLabel : '',
    }};

    const [initialSettingsValues, setInitialSettingsValues] = useState(setFormValues());

    useEffect(() => {
        setInitialSettingsValues(setFormValues());
    }, [invoicesMarginFeePercentage, invoicesMarginFeeLabel]);

    const onFormSubmit= (values) => {
        dispatch(updateAdminSettingsValue({key: 'invoicesMarginFeePercentage', value: values.percentage}));
        dispatch(updateAdminSettingsValue({key: 'invoicesMarginFeeLabel', value: values.label}));
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialSettingsValues}
            validationSchema={Yup.object({
                percentage: Yup.number()
                    .integer()
                    .min(0)
                    .max(399)
                    .required(),
                label: Yup.string()
                    .min(3)
                    .max(100)
                    .required(),
            })}

            onSubmit={onFormSubmit}
        >
            {() => {
                return (
                    <Form>
                        <Card>
                            <CardBody className="shipment admin">
                                <Row className="m-1">
                                    <Col sm={12}>
                                        <div className="h5 font-weight-bold">Invoice Fee</div>
                                        <FormGroup inline={true}>
                                            <Label for="percentage" className="item-sx">Percentage</Label>
                                            <Field className="form-control col-2" name="percentage" id="percentage" placeholder="Fee percentage" />
                                            <ErrorMessage component="div" className="ship-input-error ml-4" name="percentage" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="label" className="item-sx">Fee label</Label>
                                            <Field className="form-control" name="label" id="label" placeholder="Invoice fee label" />
                                            <ErrorMessage component="div" className="ship-input-error ml-4" name="label" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="m-1">
                                    <Col sm={12}>
                                        <button className="w-100 ship-primary-custom-button" type="submit">
                                            Save Changes
                                        </button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default InvoiceFeeConfiguration;
