import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import "../../styles/shipment.css";
import {RadioButton} from "primereact/radiobutton";
import {InputSwitch} from "primereact/inputswitch";
import {useDispatch, useSelector} from "react-redux";
import {getCouriers, updateCourier} from "../../redux/actions/couriers.actions";
import {ADMIN_INVOICES} from "../../navigation/ROUTECONSTANTS";
import {setAdminInvoicesState} from "../../redux/actions/admin.invoices.actions";
import { InvoiceFeeConfiguration } from "../../components/administration/InvoiceFeeConfiguration";
import { getAdminSettingsValue } from "../../redux/actions/admin.settings.actions";

const SelectOfMonth = () => {
    const [day, setday] = useState(null);

    const justifyTemplate = (option) => {
        return <i className={option.icon}></i>;
    };

    return (
        <div className="row pt-3 mb-3">
            <div className="gray col-12 mb-4">Day of week</div>
            <div className="col-12">
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day1"
                        name="day"
                        value="Mon"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Mon"}
                    />
                    <label htmlFor="day1">M</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day2"
                        name="day"
                        value="Tue"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Tue"}
                    />
                    <label htmlFor="day2">Tu</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day3"
                        name="day"
                        value="Wed"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Wed"}
                    />
                    <label htmlFor="day3">W</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day4"
                        name="day"
                        value="Thu"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Thu"}
                    />
                    <label htmlFor="day4">Th</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day5"
                        name="day"
                        value="Fri"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Fri"}
                    />
                    <label htmlFor="day5">F</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day6"
                        name="day"
                        value="Sat"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Sat"}
                    />
                    <label htmlFor="day7">Sa</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton
                        inputId="day7"
                        name="day"
                        value="Sun"
                        onChange={(e) => setday(e.value)}
                        checked={day === "Sun"}
                    />
                    <label htmlFor="day4">Su</label>
                </div>
            </div>
        </div>
    );
};

export const Administration = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [delaccount, setDelAccount] = useState(true);
    const [automated, setAutomated] = useState(true);
    const couriers = useSelector(state => state.couriers);

    function allCarriers(value) {
        couriers.forEach(courier => {
            dispatch(updateCourier(courier.id, {active: value}));
        });
    }

    useEffect(() => {
        dispatch(getCouriers());
        dispatch(getAdminSettingsValue({key: 'invoicesMarginFeePercentage'}));
        dispatch(getAdminSettingsValue({key: 'invoicesMarginFeeLabel'}));
    }, []);

    useEffect(() => {
        couriers.sort((a, b) => a.courier.localeCompare(b.courier));
    }, [couriers])

    const updateSingleCarrier = (id, value) => {
        dispatch(updateCourier(id, {active: value}))
    }

    const onClickNewInvoiceButton = () => {
        dispatch(setAdminInvoicesState({
            newEditInvoiceModal: {
                open: true
            }
        }))
        history.push(ADMIN_INVOICES)
    }

    const onClickManageAccountsButton = () => {
        history.push(ADMIN_INVOICES);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <Row className="align-items-center">
                                        <div className="d-flex col-sm-12 justify-content-start">
                                            <div className="h2 font-weight-bold mt-2 float-left">Administration</div>
                                        </div>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="datatable-crud-demo">
                        <Col sm={12} md={6}>
                            <Card>
                                <CardBody className="shipment admin">
                                    {couriers && couriers.length > 0 &&
                                    <Row className="m-1">
                                        <Col sm={12}>
                                            <div className="row">
                                                <div className="col-sm-7 mb-4 mt-4">
                                                    <div className="h4 font-weight-bold">
                                                        Turn On/Off Shipping Carriers
                                                    </div>
                                                </div>
                                                <div className="mx60">
                                                    <div className="item-sx black w100 h5 ml-3">All Carriers</div>
                                                </div>
                                                <div className="col-1 p-0">
                                                    <label className="ship-switch-big p-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={couriers.every(courier => courier.active === true)}
                                                            value={couriers.every(courier => courier.active === true)}
                                                            onChange={() => allCarriers(!couriers.every(courier => courier.active === true))}
                                                        />
                                                        <span className="ship-slider s-big ship-round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {couriers.filter(element => element.courier != 'DEFAULT').map(courier =>
                                                <div className="row" key={courier.id}>
                                                    <div className="col-8">
                                                        <div className="item-sx "> {courier.courier} </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <InputSwitch checked={courier.active}
                                                                        onChange={(e) => updateSingleCarrier(courier.id, e.value)}/>
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    }
                                    <div className="col-12">
                                        <hr/>
                                    </div>
                                    <Row className="m-1">
                                        <Col sm={12} className="mt-3">
                                            <div className="h5 font-weight-bold">Delete Account</div>
                                            <div className="row mt-3">
                                                <div className="col-8">
                                                    <div className="item-sx h6">Delete Account Feature</div>
                                                </div>
                                                <div className="col-4">
                                                    <InputSwitch
                                                        checked={delaccount}
                                                        onChange={(e) => setDelAccount(e.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="col-12">
                                        <hr/>
                                    </div>
                                    <Row className="m-1">
                                        <Col sm={12} className="mt-3">
                                            <div className="h5 font-weight-bold">Invoices</div>
                                            <div className="row mt-3">
                                                <div className="col-8">
                                                    <div className="item-sx">Automated Weekly Invoicing</div>
                                                </div>
                                                <div className="col-4">
                                                    <InputSwitch
                                                        checked={automated}
                                                        onChange={(e) => setAutomated(e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <SelectOfMonth/>
                                            <Row className="m-1">
                                                <Col sm={12}>
                                                <button
                                                    className="w-100 ship-secondary-custom-button font-weight-bold m-3 black"
                                                    type="button"
                                                    style={{maxWidth: "206px", fontSize: "12px", height: "40px"}}
                                                    onClick={onClickManageAccountsButton}
                                                    >
                                                    Manage Accounts
                                                </button>
                                                <button
                                                    className="w-100 ship-primary-custom-button m-3"
                                                    type="button"
                                                    style={{maxWidth: "206px", fontSize: "12px", height: "40px"}}
                                                    onClick={onClickNewInvoiceButton}
                                                    >
                                                    Create New Invoice
                                                </button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={12} md={6}>
                            <InvoiceFeeConfiguration />
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default Administration;
