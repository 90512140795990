import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { formatCurrency } from "../../../../utils/numbers-helper";
import { setAdminInvoicesState } from "../../../../redux/actions/admin.invoices.actions";
import userIconColor from "../../../../assets/images/icon-user-color.png";
import pdfIcon from "../../../../assets/images/pdf-icon.png";
import "./style.scss";

const ModalTitle = ({ title }) => {
  return (
    <div className="modal-title">
      <h2>{title}</h2>
    </div>
  );
};

export default function SendReminderModal() {
  const dispatch = useDispatch();
  const { sendReminderModal = {} } = useSelector((state) => ({
    sendReminderModal: state.adminInvoices.sendReminderModal,
  }));

  const validationSchema = Yup.object().shape({
    defaultEmail: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    emails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().email("Email is invalid"),
      })
    ),
  });

  const closeSendReminderModal = () => {
    dispatch(
      setAdminInvoicesState({
        pdfInvoiceModal: {
          open: false,
          invoice: {},
        },
        sendReminderModal: {
          open: false,
          invoice: {},
        },
      })
    );
  };
  const addNewEmail = (e, values, errors, setValues) => {
    const emails = [...values.emails];
    const entries = Object.entries(errors).length;
    const pop = emails.length === 0 || emails.pop()?.email;
    if (entries === 0 && pop) {
      setValues({ ...values, emails: [...values.emails, { email: "" }] });
    }
  };
  const onSubmit = (values) => {
    closeSendReminderModal();
  };

  const openPdfModalInvoice = () => {
    dispatch(
      setAdminInvoicesState({
        pdfInvoiceModal: {
          open: true,
          invoice: sendReminderModal.invoice,
        },
        sendReminderModal: {
          open: false,
          invoice: sendReminderModal.invoice,
        },
      })
    );
  };
  return (
    <Dialog
      closable={false}
      visible={sendReminderModal.open}
      className="send-reminder-modal"
      header={<ModalTitle title="Send Invoice" />}
      onHide={closeSendReminderModal}
      dismissableMask={true}
    >
      <div className="send-reminder-modal send-reminder-modal__content">
        <div className="p-justify-between subinfos">
          <div className="send-reminder-modal send-reminder-modal__content__infos">
            <img src={userIconColor} alt="userIconColor" />
            <div className="infos">
              <ul>
                <li>
                  <span className="infos infos_type">Customer: </span>
                  <b className="infos infos_values">
                    {sendReminderModal.invoice.customerName}
                  </b>
                </li>
                <li>
                  <span className="infos infos_type">Business Namer: </span>
                  <b className="infos infos_values">
                    {sendReminderModal.invoice.company}
                  </b>
                </li>
                <li>
                  <span className="infos infos_type">Email: </span>
                  <b className="infos infos_values">S.NGUY@NeonRobotics.tech</b>
                </li>
              </ul>
            </div>
          </div>
          <div className="send-reminder-modal send-reminder-modal__content__infos">
            <div className="invoice-amount">
              Amount Due:{" "}
              <b>{formatCurrency(sendReminderModal.invoice.total)}</b>
            </div>
            <div className="pdf-icon" onClick={openPdfModalInvoice}>
              <img src={pdfIcon} alt="pdfIcon" />
              <span>View PDF</span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            defaultEmail: "S.NGUY@NeonRobotics.tech",
            emails: [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            values,
            touched,
            setValues,
            handleBlur,
            handleChange,
          }) => {
            return (
              <Form>
                <div className="send-reminder-modal send-reminder-modal__content__form formik-form p-grid">
                  <div className="form-group p-col-7 emails">
                    <label>Email Address</label>
                    <div className="input-container">
                      <input
                        type="text"
                        name="defaultEmail"
                        id="defaultEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.defaultEmail}
                      />
                    </div>
                    <FieldArray name="emails">
                      {() =>
                        values.emails.map((emails, i) => (
                          <div key={i} className="input-container">
                            <input
                              type="text"
                              name={`emails.${i}.email`}
                              id={`emails.${i}.email`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.emails[i].email}
                            />
                            <ErrorMessage
                              name={`emails.${i}.email`}
                              component="div"
                              className="has-error"
                            />
                          </div>
                        ))
                      }
                    </FieldArray>
                  </div>
                  <div className="p-col-5 submit-button-container">
                    <button className="submit-button" type="submit">
                      Send Reminder
                    </button>
                  </div>
                </div>
                <div
                  className="add-email"
                  onClick={(e) => addNewEmail(e, values, errors, setValues)}
                >
                  Add another email
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="form-footer p-grid p-justify-between">
        <div className="p-col-5">
          <button className="cancel-button" onClick={closeSendReminderModal}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
}
