import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
   GET_SERVICES,
   getServicesSuccess
} from "../actions/services.actions";
import {disableOverlay, enableOverlay} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getServices} from "../../services/servicesService";

/**
 * getServicesEffect
 */
 function* getServicesEffect() {
    try {
        yield put(enableOverlay('Loading services...'));
        const servicesList = yield call(getServices);
        yield put(getServicesSuccess(servicesList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchGetServices() {
    yield takeEvery(GET_SERVICES, getServicesEffect);
}

function* servicesSaga() {
    yield all([
        fork(watchGetServices)
    ]);
}

export default servicesSaga;