import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import InvoicesHeader from "./header";
import InvoicesBody from "./body";
import PayInvoiceModal from "./payInvoiceModal";
import PdfInvoiceModal from "./pdfInvoice"
import "../../styles/invoices.scss";

const Invoices = () => {
  const { payInvoiceModal = {}, pdfInvoiceModal = {} } = useSelector((state) => ({
    payInvoiceModal: state.invoices.payInvoiceModal,
    pdfInvoiceModal: state.invoices.pdfInvoiceModal,
  }));

  return (
    <div className="page-content invoices-page">
      <Container fluid>
        <InvoicesHeader />
        <InvoicesBody />
        {payInvoiceModal.open && <PayInvoiceModal />}
        {pdfInvoiceModal.open && <PdfInvoiceModal />}
      </Container>
    </div>
  );
};

export default Invoices;
