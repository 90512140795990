import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import "primeflex/primeflex.css";
import { Checkbox } from "primereact/checkbox";
import {setInvoicesState} from "../../../redux/actions/invoices.actions"
import { creditCardReg, cvvReg } from "../../../utils/formik-helpers";

const paymentSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Field Required"),
  lastName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Field Required"),
  expireDate: Yup.string()
    .typeError("Not a valid expiration date. Example: MM/YY")
    .max(5, "Not a valid expiration date. Example: MM/YY")
    .matches(
      /([0-9]{2})\/([0-9]{2})/,
      "Not a valid expiration date. Example: MM/YY"
    )
    .required("Field Required"),
  cvv: Yup.string()
    .typeError("Not a valid CVV")
    .matches(cvvReg, "Not a valid CVV")
    .required("Field Required"),
  zipcode: Yup.string()
    .min(1, "Too short")
    .max(5, "Too long")
    .required("Field Required"),
  cardNumber: Yup.string()
    .typeError("Not a valid credit card")
    .matches(creditCardReg, "Not a valid credit card")
    .required("Field Required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  expireDate: "",
  cvv: "",
  zipcode: "",
  cardNumber: "",
  remember: false
};

const PayInvoiceForm = () => {
  const dispatch = useDispatch()
  const onSubmit = (values) => {
    dispatch(setInvoicesState({
      payInvoiceModal: {
        open: false,
        invoice: {}
      }
    }))
  };
  const onCancel = () => {
    dispatch(setInvoicesState({
      payInvoiceModal: {
        open: false,
        invoice: {}
      }
    }))
  }
  return (
    <div className="p-grid">
      <Formik
        initialValues={initialValues}
        validationSchema={paymentSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <Form className="formik-form p-grid" onSubmit={handleSubmit}>
              <div className="form-group p-col-12 p-md-6">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className={`${errors.firstName && "has-error"}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                <span className="has-error">{errors.firstName}</span>
              </div>
              <div className="form-group p-col-12 p-md-6">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className={`${errors.lastName && "has-error"}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
                <span className="has-error">{errors.lastName}</span>
              </div>
              <div className="form-group p-col-12">
                <label>Credit Card Number</label>
                <input
                  type="text"
                  name="cardNumber"
                  id="cardNumber"
                  placeholder="Enter 16-digit number"
                  className={`${errors.cardNumber && "has-error"}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cardNumber}
                />
                <span className="has-error">{errors.cardNumber}</span>
              </div>
              <div className="form-group p-col-4">
                <label>Expiration Date</label>
                <input
                  type="text"
                  name="expireDate"
                  id="expireDate"
                  placeholder="00/00"
                  className={`${errors.expireDate && "has-error"}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.expireDate}
                />
                <span className="has-error">{errors.expireDate}</span>
              </div>
              <div className="form-group p-col-4">
                <label>CVV</label>
                <input
                  type="text"
                  name="cvv"
                  id="cvv"
                  placeholder="###"
                  className={`${errors.cvv && "has-error"}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cvv}
                />
                <span className="has-error">{errors.cvv}</span>
              </div>
              <div className="form-group p-col-4">
                <label>Zip Code</label>
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  className={`${errors.zipcode && "has-error"}`}
                  placeholder="###"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zipcode}
                />
                <span className="has-error">{errors.zipcode}</span>
              </div>
              <div className="checkbox-group p-col-12">
                <Checkbox
                  id="remember"
                  name="remember"
                  checked={values.remember}
                  onChange={handleChange}
                />
                <label>Save Credit Card For Later Use</label>
              </div>
              <div className="form-footer p-grid p-justify-between">
                <div className="p-col-4">
                  <button onClick={onCancel} className="cancel-button">Cancel</button>
                </div>
                <div className="p-col-4">
                  <button className="submit-button">Pay Invoice</button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PayInvoiceForm;
