import React, {useContext, useEffect, useState} from 'react';
import usericon from '../../theme/assets/images/icon-user.png';
import {useDispatch, useSelector} from "react-redux";
import {updateShipmentRequest} from '../../redux/actions/shipments.actions';
import {AppContext} from '../shipment/Provider';
import {updateQuoteRequest} from '../../redux/actions/quotes.actions';
import {UncontrolledTooltip} from "reactstrap";
import { joinAccountAddress } from '../../utils';

function Listadati(props) {
    const [type, setType] = useState(props.tipoCard);
    const senderData = useSelector(state => state.senderAddress);
    const receiverData = useSelector(state => state.receiverAddress);
    const returnData = useSelector(state => state.returnAddress)

    var dataCard = {};
    switch (type) {
        case 'sender':
            dataCard = senderData;
            break;
        case 'receiver':
            dataCard = receiverData;
            break;
        case 'return':
            dataCard = returnData;
            break;
        default:
            break;
    }
    return (
        <div>
            <table className="table fnt12">
                <tbody>
                <tr>
                    <td className="item-sx">Address Type</td>
                    <td className="item-dx black">{dataCard.residential ? 'Residential' : 'Business'}</td>
                </tr>
                <tr>
                    <td className="item-sx">{type.charAt(0).toUpperCase() + type.slice(1)}</td>
                    <td className="item-dx black">{dataCard.fullName}</td>
                </tr>
                <tr>
                    <td className="item-sx">Business Name</td>
                    <td className="item-dx black">{dataCard.companyName}</td>
                </tr>
                <tr>
                    <td className="item-sx">Address</td>
                    <td className="item-dx black">{joinAccountAddress(dataCard)}</td>
                </tr>
                <tr>
                    <td className="item-sx">Contact</td>
                    <td className="item-dx black">{dataCard.phone}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

function TypeCards(props) {
    return (
        <div className="ship-card">
            <Listadati dati={props.info} tipoCard={props.tipoCard}/>
        </div>
    );
}

export const CardAddress = (props) => {
    var dataCard = {};
    const dispatch = useDispatch();
    const shipmentRequest = useSelector(state => state.shipmentRequest);
    const quoteRequest = useSelector(state => state.quoteRequest);
    const senderData = useSelector(state => state.senderAddress);
    const receiverData = useSelector(state => state.receiverAddress);
    const returnData = useSelector(state => state.returnAddress);
    const [stato, setStato] = useContext(AppContext);
    const buttonEditCliked = () => {
        props.showEditor()
        setStato({...stato, addressToEdit: dataCard});
    }
    switch (props.tipoCard) {
        case 'sender':
            dataCard = senderData;
            shipmentRequest.sender = {
                name: dataCard.fullName,
                address_1: dataCard.address1,
                address_2: dataCard.address2,
                city: dataCard.city,
                state: dataCard.state,
                country: dataCard.country,
                company: dataCard.companyName,
                phone: dataCard.phone,
                zip_code: dataCard.zip,
                residential: dataCard.residential,
            }
            quoteRequest.sender = {
                country: dataCard.country,
                state: dataCard.state,
                city: dataCard.city,
                zip: dataCard.zip,
                residential: dataCard.residential,
            }
            break;
        case 'receiver':
            dataCard = receiverData;
            shipmentRequest.receiver = {
                name: dataCard.fullName,
                address_1: dataCard.address1,
                address_2: dataCard.address2,
                city: dataCard.city,
                state: dataCard.state,
                country: dataCard.country,
                zip_code: dataCard.zip,
                phone: dataCard.phone,
                residential: dataCard.residential,
            }
            quoteRequest.receiver = {
                country: dataCard.country,
                state: dataCard.state,
                city: dataCard.city,
                zip: dataCard.zip,
                email: dataCard.email,
                residential: dataCard.residential,
            }
            break;
        case 'return':
            dataCard = returnData;
            shipmentRequest.return_to = {
                name: dataCard.fullName,
                address_1: dataCard.address1,
                address_2: dataCard.address2,
                city: dataCard.city,
                state: dataCard.state,
                country: dataCard.country,
                zip_code: dataCard.zip,
                residential: dataCard.residential,
            }
            break;
        default:
            break;
    }

    useEffect(() => {
        dispatch(updateShipmentRequest(shipmentRequest))
        dispatch(updateQuoteRequest(quoteRequest))
    }, []);

    const openAddressBookDialog = () => {
        props.openAddressBookDialog();
    }

    return (
        <>
            <div className="ship-box-w borderC shipment-cards-background">
                <div className="row mt-3 mb-3">
                    <div className="col-9">
                        <div className="h4 font-weight-bold">{props.titleCard}</div>
                    </div>
                    <div className="col-3 pl-5">
                        <button type="button" onClick={buttonEditCliked}
                                className="btn btn-outline-danger borderC shit-btn-edit float-right">Edit
                        </button>
                    </div>
                </div>
                <div className="row borderC">
                    <img className="ship-user-icon" style={{cursor: "pointer"}} src={usericon} alt="user"
                         id={'address-icon' + props.tipoCard}
                         onClick={(e) => openAddressBookDialog()}/>
                    <UncontrolledTooltip placement="top" target={'address-icon' + props.tipoCard}>
                        Select from AddressBook
                    </UncontrolledTooltip>
                    <br/>
                    <TypeCards tipoCard={props.tipoCard}/>
                </div>
            </div>
        </>
    )
}

export default CardAddress;
