import axios from "axios";
import {API_BASE_URL, ACCOUNTS} from "./APICONSTANTS";

export const getAccounts = (page, limit, filter, order, sortBy) => {
    let url = API_BASE_URL + ACCOUNTS + '?page=' + page.toString();
    url += limit ? '&pageSize=' + limit.toString() : '';
    url += filter && filter.length > 0 ? '&search=' + filter : '';
    if(order && sortBy) {
        if(order === 'asc') {
            url += '&ordering=+' + sortBy;
        } else  {
            url += '&ordering=-' + sortBy;
        }
    }
    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Accounts loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get accounts error: ", err);
                    reject("Get accounts error");
                });
        } catch (error) {
            console.error("Get accounts error: ", error);
            reject("Get accounts error");
        }
    });
};

export const getAccount = (accountId) => {
    let url = API_BASE_URL + ACCOUNTS + '/' + accountId;
    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Account loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get account error: ", err);
                    reject("Get account error");
                });
        } catch (error) {
            console.error("Get account error: ", error);
            reject("Get account error");
        }
    });
};


export const updateAccount = ({accountId, data}) => {
    let url = API_BASE_URL + ACCOUNTS + '/' + accountId;
    return new Promise((resolve, reject) => {
        try {
            axios.put(url, data)
                .then((res) => {
                    console.log("Account updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Update account error: ", err);
                    reject("Update account error");
                });
        } catch (error) {
            console.error("Update account error: ", error);
            reject("Update account error");
        }
    });
};
