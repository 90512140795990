import {createAction} from "@reduxjs/toolkit";

// UI action types
export const ENABLE_OVERLAY = '[UI] Enable Overlay';
export const DISABLE_OVERLAY = '[UI] Disable Overlay';
export const NEW_NOTIFICATION_MODAL = '[UI] Opening new notification modal';
export const CLOSE_NOTIFICATION_MODAL = '[UI] Closing new notification modal';
export const SET_TOAST = '[UI] setting toast';

// UI actions
export const enableOverlay = createAction(ENABLE_OVERLAY);
export const disableOverlay = createAction(DISABLE_OVERLAY);
/**
 * @type: {PayloadActionCreator<"[UI] Opening new notification modal">}
 * @payload: {title: string, message: string, icon: string}
 */
export const openNotificationModal = createAction(NEW_NOTIFICATION_MODAL);
export const closeNotificationModal = createAction(CLOSE_NOTIFICATION_MODAL);

export const setToast = createAction(SET_TOAST);
