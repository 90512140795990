import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import '../../styles/tracking.scss'

const ViewTracking = (props) => {

    const redirectToCourier = () => {
        switch (props.shipmentData.courier) {
            // case 'DHL':
            // case 'UPS':
            // case 'FEDEX':
            case 'USPS':
                window.open('https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLabels=' + props.shipmentData.tracking_number, '_blank');
                break;
            default:
                console.error('Courier not handled');
        }
    }

    return (
        <ConfirmDialog visible={true}
            className='tracking-confirm-dialog'
            message="Do you want to view the tracking in external site?"
            header="View tracking #"
            acceptLabel="View tracking"
            rejectLabel="Cancel"
            accept={redirectToCourier} reject={props.open}
            closable={false} />
    );
};

export default ViewTracking;
