import {all, call, delay, fork, put, takeEvery} from "redux-saga/effects";
import successIcon from "../../assets/icons/shared/success.svg"
import {
    CREATE_SHIPMENT,
    createShipmentSuccess, GET_SHIPMENT,
    GET_SHIPMENTS,
    getShipments,
    getShipmentsSuccess, getShipmentSuccess,
    getShipmentLabelSuccess,
    VOID_SHIPMENT_REQUEST,
    GET_SHIPMENT_LABEL,
    resetShipmentState,
    SEND_SHIPMENT_LABEL_EMAIL
} from "../actions/shipments.actions";
import {closeNotificationModal, disableOverlay, enableOverlay, openNotificationModal} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getShipments as getShipmentsCall, getShipment as getShipmentCall, postShipment, voidShipment, getShipmentLabel, sendEmailLabel} from "../../services/shipmentsService";
import {postCommercialInvoice} from "../../services/commercial.invoice.service";
import {checkCommercialInvoice} from "../../utils";
import {SHIPMENT} from "../../navigation/ROUTECONSTANTS";

/**
 * getShipmentsEffect
 * @param {*} param0 page
 */
function* getShipmentsEffect({payload}) {
    try {
        yield put(enableOverlay('Loading shipments...'));
        const shipmentsList = yield call(getShipmentsCall, payload.page, payload.limit, payload.filter, payload.order, payload.sort);
        yield put(getShipmentsSuccess(shipmentsList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * getShipmentEffect
 * @param {*} param0
 */
function* getShipmentEffect({payload}) {
    try {
        yield put(enableOverlay('Loading shipment...'));
        const shipment = yield call(getShipmentCall, payload.id);
        yield put(getShipmentSuccess(shipment));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * createShipmentEffect
 * @param {*} param0
 */
function* createShipmentEffect({payload}) {
    try {
        yield put(enableOverlay('Creating shipment...'));
        const shipment = yield call(postShipment, payload);
        if (shipment) {
            const commercialInvoiceIsValid = yield checkCommercialInvoice(payload.commercial_invoice);
            if (shipment.id && commercialInvoiceIsValid) {
                yield put(createShipmentSuccess(shipment));
                payload.commercial_invoice.shipment = yield shipment.id;
                const commercialInvoice = yield call(postCommercialInvoice, payload.commercial_invoice);
                if (commercialInvoice) {
                    yield put(disableOverlay());
                    yield put(openNotificationModal({
                        title: 'Done!',
                        message: 'Shipment with Commercial Invoice request send! Wait the end of the validation process...',
                        icon: successIcon
                    }));
                    yield delay(3000);
                    yield put(closeNotificationModal());
                    yield window.location.href = SHIPMENT + '/' + shipment.id;
                }
            } else {
                yield put(createShipmentSuccess(shipment));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Done!',
                    message: 'Shipment request send! Wait the end of the validation process...',
                    icon: successIcon
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
                yield window.location.href = SHIPMENT + '/' + shipment.id;
            }

        } else {
            console.log("Something goes wrong during shipment creation")
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * voidShipmentEffect
 * @param {*} param0
 */
function* voidShipmentEffect({payload}) {
    try {
        yield put(enableOverlay('Void shipment...'));
        const voiding = yield call(voidShipment, payload.shipmentId);
        if (voiding) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'Your shipment has been voided!',
                icon: successIcon
            }));
            if (payload.history.location.pathname === '/tracking') {
                yield put(resetShipmentState());
                yield put(getShipments({page: 1, limit: 10}));
            }
            yield put(closeNotificationModal());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * getShipmentLabelEffect
 * @param {*} param0
 */
function* getShipmentLabelEffect({payload}) {
    try {
        yield put(enableOverlay('Loading shipment label...'));
        const shipmentLabel = yield call(getShipmentLabel, payload.shipmentId);
        yield put(getShipmentLabelSuccess(shipmentLabel));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

function* sendShipmentLabelEmailEffect({payload}) {
    try {
        yield put(enableOverlay('Sending shipment label email...'));
        yield call(sendEmailLabel, payload);
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchGetShipments() {
    yield takeEvery(GET_SHIPMENTS, getShipmentsEffect);
}

export function* watchGetShipment() {
    yield takeEvery(GET_SHIPMENT, getShipmentEffect);
}

export function* watchCreateShipment() {
    yield takeEvery(CREATE_SHIPMENT, createShipmentEffect);
}

export function* watchVoidShipment() {
    yield takeEvery(VOID_SHIPMENT_REQUEST, voidShipmentEffect);
}

export function* watchGetShipmentLabel() {
    yield takeEvery(GET_SHIPMENT_LABEL, getShipmentLabelEffect);
}

export function* watchSendShipmentLabelEmail() {
    yield takeEvery(SEND_SHIPMENT_LABEL_EMAIL, sendShipmentLabelEmailEffect);
}

function* shipmentsSaga() {
    yield all([
        fork(watchGetShipments),
        fork(watchCreateShipment),
        fork(watchVoidShipment),
        fork(watchGetShipment),
        fork(watchGetShipmentLabel),
        fork(watchSendShipmentLabelEmail),
    ]);
}

export default shipmentsSaga;
