import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, ListGroup, ListGroupItem, UncontrolledTooltip } from "reactstrap";
import { resetCustomersFound, searchCustomers } from "../../redux/actions/customers.actions";
import userIcon from "../../theme/assets/images/icon-user.png";

export const SearchCustomers = (props) => {
    const dispatch = useDispatch();
    const [searchkeyword, setSearchkeyword] = useState('');
    const customersFound = useSelector(state => state.customers.customersFound);

    const updateSearchResult = (value) => {
        let searchkeyword = value;
        setSearchkeyword(searchkeyword);
        dispatch(resetCustomersFound());
        if (searchkeyword.trim().length >= 3) {
            dispatch(searchCustomers(searchkeyword));
        }
    }

    const selectCustomer = (e, selectedCustomer) => {
        setSearchkeyword(selectedCustomer.fullName);
        dispatch(resetCustomersFound());
        props.updatedShowReceiverAddress(selectedCustomer);
    }

    const openAddressBookDialog = () => {
        props.openAddressBookDialog();
    }

    const addNewContact = () => {
        props.addNewContact();
    }

    return (
        <React.Fragment>
            <div className="row ship-box-search" >
                <div className="col-12">
                    <div className="h5 font-weight-bold">Receiver Address
                        <img className="float-right" style={{ cursor: "pointer" }} src={userIcon} alt="user"
                            id="address-book-icon-search"
                            onClick={(e) => openAddressBookDialog()} />
                        <UncontrolledTooltip placement="top" target="address-book-icon-search">
                            Select from AddressBook
                        </UncontrolledTooltip>
                    </div>
                    <div className="item-sx mrg-tb20">Search database for previous order recipients, or simply add a new one.</div>
                </div>

                <div className="d-flex col-12">
                    <Form className="ship-search d-lg-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control" placeholder={'Search by name or city'} value={searchkeyword} onChange={e => updateSearchResult(e.target.value)} />
                            <ListGroup>
                                {(customersFound && customersFound.length > 0) ? customersFound.map((item) => {
                                    return (
                                        <ListGroupItem tag="a" onClick={e => selectCustomer(e, item)} key={item.id} action>
                                            {item.fullName}
                                        </ListGroupItem>
                                    )
                                }) : <></>}
                            </ListGroup>
                            <span className="ri-search-line"></span>
                        </div>
                    </Form>
                    <button type="button" className="ship-btn-search ship-primary-custom-button" onClick={addNewContact}>
                        Add New
                    </button>
                </div>
            </div>

        </React.Fragment>
    );
}
export default SearchCustomers;
