import {createAction} from "@reduxjs/toolkit";
// Customers action types
export const SET_ADMIN_INVOICES_STATE = '[ADMIN INVOICES] Set New State'
export const CREATE_INVOICE = '[INVOICE] Creating new invoice...';
export const CREATE_INVOICE_SUCCESS = '[INVOICE] New invoice create with success!';
export const RESET_INVOICE = '[INVOICE] Invoice reset!';
export const DELETE_INVOICE = '[INVOICE] Deleting invoice...';
export const UPDATE_INVOICE = '[INVOICE] Updating invoice...';

export const GET_SELECTED_INVOICE_ACCOUNT = '[INVOICES] Getting account of selected invoice';
export const GET_SELECTED_INVOICE_ACCOUNT_SUCCESS = '[INVOICES] Get selected invoice account success!'

export const GET_SELECTED_ACCOUNT_INVOICES = '[INVOICES] Getting invoices of selected account';
export const GET_SELECTED_ACCOUNT_INVOICES_SUCCESS = '[INVOICES] Get invoices of selected account success!'

// Invoices actions
export const createInvoice = createAction(CREATE_INVOICE);
export const createInvoiceSuccess = createAction(CREATE_INVOICE_SUCCESS);
export const resetInvoice = createAction(RESET_INVOICE);
export const deleteInvoice = createAction(DELETE_INVOICE);
export const setAdminInvoicesState = createAction(SET_ADMIN_INVOICES_STATE);
export const getSelectedInvoiceAccount = createAction(GET_SELECTED_INVOICE_ACCOUNT);
export const getSelectedInvoiceAccountSuccess = createAction(GET_SELECTED_INVOICE_ACCOUNT_SUCCESS);
export const getSelectedAccountInvoices = createAction(GET_SELECTED_ACCOUNT_INVOICES);
export const getSelectedAccountInvoicesSuccess = createAction(GET_SELECTED_ACCOUNT_INVOICES_SUCCESS);

export const updateInvoice = (invoiceId, invoiceData) => ({
    type: UPDATE_INVOICE,
    payload: {invoiceId, invoiceData}
});
