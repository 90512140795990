import { GET_LIST_COURIERS_SUCCESS } from "../actions/couriers.actions";

const initialCouriersState = [];

export const CouriersReducer = (state = initialCouriersState, action) => {
    switch (action.type) {
        case GET_LIST_COURIERS_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
}
