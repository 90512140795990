import React from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {Button} from "primereact/button";
import avatar from '../../theme/assets/images/users/avatar1.png';
import name from '../../theme/assets/images/users/name-profile.svg';
import phone from '../../theme/assets/images/users/phone-profile.svg';
import email from '../../theme/assets/images/users/email.profile.svg';
import address from '../../theme/assets/images/users/address-profile.svg';
import {useHistory} from 'react-router-dom';
import {DASHBOARD, EDIT_PROFILE} from "../../navigation/ROUTECONSTANTS";

export const Profile = () => {

    const user = JSON.parse(localStorage.getItem('sd-user'));
    const history = useHistory();

    function editProfile(event)  {
        event.preventDefault();
        history.push(EDIT_PROFILE);
    }

    function goToDashboard(event)  {
        event.preventDefault();
        history.push(DASHBOARD);
    }

    return (
        <React.Fragment>
            <div className="page-content h-100">
                <Container className="h-100" fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <div className="h2 font-weight-bold mt-2">My Account</div>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="h-100">
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <Row>
                                        <Button icon="pi pi-angle-left" className="mr-2 p-button-danger ml-2 h-25"
                                                onClickCapture={(e) => goToDashboard(e)} />
                                        <div className="h4 font-weight-bold mt-2">Back to Dashboard</div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row className="d-flex align-items-center justify-content-center">
                                        <Col xs={12} md={12} xl={12}>
                                            <Row className="d-flex align-items-center justify-content-center mt-3">
                                                <img src={avatar} width="150px" height="150px" alt="Avatar"/>
                                            </Row>
                                            <Row className="d-flex align-items-center justify-content-center mt-3">
                                                <h3 className="font-weight-bold text-capitalize">{user.first_name} {user.last_name}</h3>
                                            </Row>
                                            <Row className="d-flex align-items-center justify-content-center mt-3">
                                                <img src={name} alt="" className="mr-2"/>
                                                <div className="text-capitalize h5 mb-0">{user.title ? user.title : 'N/A'}</div>
                                            </Row>
                                            <Row className="d-flex align-items-center justify-content-center mt-3">
                                                <img src={address} alt="" className="mr-2"/>
                                                <div className="text-capitalize h5 mb-0">{user.address ? user.address : 'N/A'}</div>
                                            </Row>
                                            <Row className="d-flex align-items-center justify-content-center mt-3">
                                                <img src={email} alt="" className="mr-2"/>
                                                <div className="text-capitalize h5 mb-0">{user.email ? user.email : 'N/A'}</div>
                                            </Row>
                                            <Row className="d-flex align-items-center justify-content-center mt-3">
                                                <img src={phone} alt="" className="mr-2"/>
                                                <div className="text-capitalize h5 mb-0">{user.phone ? user.phone : 'N/A'}</div>
                                            </Row>
                                            <Row className="d-flex align-items-center justify-content-center mt-3 mb-5">
                                                <button className="w-100 ship-primary-custom-button"
                                                type="button" style={{maxWidth: "333px"}} onClickCapture={(e) => editProfile(e)} >
                                                    Edit Profile
                                                </button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Profile;
