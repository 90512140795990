import {
    CLOSE_NOTIFICATION_MODAL,
    DISABLE_OVERLAY,
    ENABLE_OVERLAY,
    NEW_NOTIFICATION_MODAL,
    SET_TOAST
} from "../actions/ui.actions";


const initialUIState = {
    overlay: {loading: false, message: null},
    notificationModal: {title: null, message: null, icon: null, isOpen: false},
    toast: {
        severity: null,
        summary: null,
        detail: null,
        life: null
    }
};

export const UIReducer = (state = initialUIState, action) => {
    switch (action.type) {
        case ENABLE_OVERLAY:
            return {...state, overlay: {loading: true, message: action.payload}};
        case DISABLE_OVERLAY:
            return {...state, overlay: {loading: false, message: null}};
        case NEW_NOTIFICATION_MODAL:
            return {
                ...state, notificationModal: {
                    title: action.payload.title,
                    message: action.payload.message,
                    icon: action.payload.icon,
                    isOpen: true
                }
            };
        case CLOSE_NOTIFICATION_MODAL:
            return {...state, notificationModal: {title: null, message: null, icon: null, isOpen: false}};
        case SET_TOAST:
            return {...state, toast: action.payload}
        default:
            return state;
    }
}
