import { Dialog } from "primereact/dialog";
import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { StatesUS } from "../../assets/shipment/StatesUS";
import { StatesCA } from "../../assets/shipment/StatesCA";
import { countries } from '../../assets/shipment/countries';
import { phoneRegExp } from "../../utils/numbers-helper";


export const CreateEditContactDialog = (props) => {
    const addressToEdit = props.addressToEdit;

    const onHide = () => {

    }

    const close = () => {
        props.close();
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                fullName: addressToEdit ? addressToEdit.fullName : '',
                companyName: addressToEdit ? addressToEdit.companyName : '',
                address1: addressToEdit ? addressToEdit.address1 : '',
                address2: addressToEdit ? addressToEdit.address2 : '',
                zip: addressToEdit ? addressToEdit.zip : '',
                city: addressToEdit ? addressToEdit.city : '',
                phone: addressToEdit ? addressToEdit.phone : '',
                email: addressToEdit ? addressToEdit.email : '',
                state: addressToEdit ? addressToEdit.state : '',
                country: addressToEdit ? addressToEdit.country : '',
                residential: addressToEdit ? addressToEdit.residential : false,
            }}
            validationSchema={Yup.object({
                fullName: Yup.string().min(1, 'Must be at least 1 character')
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),

                companyName: Yup.string().max(150, 'Must be 150 characters or less')
                    .notRequired(),

                address1: Yup.string().min(1, 'Must be at least 1 character')
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),

                address2: Yup.string().min(1, 'Must be at least 1 character')
                    .max(150, 'Must be 150 characters or less'),

                zip: Yup.string().min(1, 'Must be at least 1 character')
                    .max(10, 'Must be 10 characters or less')
                    .required('Required'),

                city: Yup.string().min(1, 'Must be at least 1 character')
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),

                state: Yup.string().min(1, 'Must be at least 1 character')
                    .max(20, 'Must be 20 characters or less')
                    .required('Required'),

                phone: Yup.string()
                    .matches(phoneRegExp, 'Phone number is not valid')
                    .notRequired(),

                email: Yup.string().email('Invalid email address')
                    .notRequired(),
            })}

            onSubmit={(customer, { resetForm }) => {
                props.submit(customer);
                resetForm();
            }}
        >
            {({
                errors,
                values,
                touched,
                handleChange,
            }) => {
                return (
                    <Dialog visible={props.open} style={{ width: '650px' }} header={props.title} modal
                        className="p-fluid"
                        closable={false} onHide={onHide}>
                        <Form className="w-100 mt-3 mb-3 col-sm">
                            <div className="row mb-3">
                                <div className="form-group col-sm">
                                    <input id="fullName" name="fullName"
                                        placeholder="Full Name"
                                        className="ship-inputs"
                                        value={values.fullName}
                                        onChange={handleChange} />
                                    {touched.fullName && <div className="ship-input-error ml-4">{errors.fullName}</div>}
                                </div>

                                <div className="form-group col-sm">
                                    <input id="companyName" name="companyName"
                                        placeholder="Company name"
                                        className="ship-inputs"
                                        value={values.companyName}
                                        onChange={handleChange}
                                    />
                                    {touched.companyName &&
                                        <div className="ship-input-error ml-4">{errors.companyName}</div>}
                                </div>

                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-sm-6">
                                    <select className="ship-inputs" value={values.country}
                                        id="country" name="country"
                                        onChange={(e) => {
                                            values.state = '';
                                            handleChange(e);
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map(countryOption =>
                                            <option value={countryOption.code}
                                                key={countryOption.code}>{countryOption.name}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-sm-6">
                                    <input id="residential" name="residential"
                                        type="checkbox" className="ship-Box ml-2 mt-2" style={{ 'verticalAlign': 'text-bottom' }}
                                        checked={values.residential}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="residential" className="ship-input-label" style={{ marginTop: '0.2rem' }}>Residential Address?</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-sm">
                                    <input id="address1" name="address1"
                                        placeholder="Address 1"
                                        className="ship-inputs"
                                        value={values.address1}
                                        onChange={handleChange}
                                    />
                                    {touched.address1 && <div className="ship-input-error ml-4">{errors.address1}</div>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="form-group col-sm">
                                    <input id="address2" name="address2"
                                        placeholder="Address 2"
                                        className="ship-inputs"
                                        value={values.address2}
                                        onChange={handleChange}
                                    />
                                    {touched.address2 && <div className="ship-input-error ml-4">{errors.address2}</div>}
                                </div>
                                <div className="form-group col-sm">
                                    <input id="zip" name="zip"
                                        type="text"
                                        placeholder="Zip Code"
                                        className="ship-inputs"
                                        value={values.zip}
                                        onChange={handleChange} />
                                    {touched.zip && <div className="ship-input-error ml-4">{errors.zip}</div>}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-sm">
                                    <input id="city" name="city"
                                        placeholder="City"
                                        className="ship-inputs"
                                        value={values.city}
                                        onChange={handleChange} />
                                    {touched.city && <div className="ship-input-error ml-4">{errors.city}</div>}
                                </div>

                                <div className="form-group col-sm">
                                    {values.country == 'US' &&
                                        <select className="ship-inputs" value={values.state}
                                            id="state" name="state"
                                            onChange={handleChange}>
                                            <option value="">Select State</option>
                                            {StatesUS.map(stateOption =>
                                                <option value={stateOption.abbreviation}
                                                    key={stateOption.abbreviation}>{stateOption.name}</option>)}
                                        </select>
                                    }
                                    {values.country == 'CA' &&
                                        <select className="ship-inputs" value={values.state}
                                            id="state" name="state"
                                            onChange={handleChange}>
                                            <option value="">Select State</option>
                                            {StatesCA.map(stateOption =>
                                                <option value={stateOption.abbreviation}
                                                    key={stateOption.abbreviation}>{stateOption.name}</option>)}
                                        </select>
                                    }
                                    {!['US', 'CA'].includes(values.country) &&
                                        <input id="state" name="state"
                                            placeholder="State"
                                            className="ship-inputs"
                                            value={values.state}
                                            onChange={handleChange} />
                                    }
                                    {touched.state && <div className="ship-input-error ml-4">{errors.state}</div>}
                                </div>

                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-sm">
                                    <input id="phone" name="phone"
                                        placeholder="Phone"
                                        className="ship-inputs"
                                        value={values.phone}
                                        onChange={handleChange} />
                                    {touched.phone && <div className="ship-input-error ml-4">{errors.phone}</div>}
                                </div>
                                <div className="form-group col-sm">
                                    <input id="email" name="email"
                                        placeholder="Email"
                                        className="ship-inputs"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    {touched.email && <div className="ship-input-error ml-4">{errors.email}</div>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <button className="ship-secondary-custom-button w-100"
                                        type="button" onClick={close}>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col-sm">
                                    <button className="w-100 ship-primary-custom-button"
                                        type="submit">
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Dialog>
                );
            }}
        </Formik>
    );
}

export default CreateEditContactDialog;
