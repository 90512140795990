import { GET_CUSTOMERS_SUCCESS, RESET_CUSTOMERS_FOUND, RESET_CUSTOMER_STATE, SEARCH_CUSTOMERS_SUCCESS } from "../actions/customers.actions";

const initialCustomersState = {
    customersList: [],
    next: null,
    previous: null,
    count: 0,
    customersFound: [],
};

export const CustomersReducer = (state = initialCustomersState, action) => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state, customersList: [...state.customersList, ...action.payload.results],
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count
            };
        case SEARCH_CUSTOMERS_SUCCESS:
            return {
                ...state, customersFound: action.payload.results
            };
        case RESET_CUSTOMERS_FOUND:
            return {
                ...state, customersFound: []
            };
        case RESET_CUSTOMER_STATE:
            return initialCustomersState;
        default:
            return state;
    }
}
