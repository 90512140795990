import {createContext,useState} from 'react';

export default ({ children }) =>{
    const [stato,setStato] = useState({});
    return (            
            <AppContext.Provider value={[stato,setStato]}>
                {children}
            </AppContext.Provider>  
    );
}

export const AppContext = createContext();