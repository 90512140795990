import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Field, Form, Formik, useField, useFormikContext} from 'formik';
import {useDispatch, useSelector} from "react-redux";
import * as Yup from 'yup';
import logofedex from '../../assets/icons/shipment/logofedex-m.svg';
import logousps from '../../assets/icons/shipment/logousps-m.svg';
import logoups from '../../assets/icons/shipment/logoups-m.svg';
import logodhl from '../../assets/icons/shipment/logodhl-m.svg';
import cartfilled from '../../theme/assets/images/luggage-cart-line.svg';
import {AppContext} from '../shipment/Provider';
import {updateQuoteRequest} from "../../redux/actions/quotes.actions";
import {Col, Container, Row} from 'reactstrap';
import {updateShipmentRequest} from '../../redux/actions/shipments.actions';
import {convertlengthToInches, convertWeightToLbs, domesticDIMShipmentFactor, roundToTwoDecimal} from '../../utils/numbers-helper';
import {addCommercialInvoicePackages, removeCommercialInvoicePackages} from '../../redux/actions/commercial.invoices.action';

function SelectMisurate() {
    const [stato, setStato] = useContext(AppContext);
    return (
        <select id="inputUnits" onChange={e => setStato({...stato, length: e.target.value})} name="units"
                className="form-control">
            <option value="In">in / oz / lbs</option>
            <option value="Cm">cm / kg / g</option>
        </select>
    );
}

const OuncesField = (props) => {
    const [stato, setStato] = useContext(AppContext);
    const {
        values: {weight},
        touched,
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    useEffect(() => {
        if (weight) {
            setFieldValue(props.name, (props.length === 'Cm') ? weight * 35.274 : weight * 16);
        }
    }, [weight, touched.weight, setFieldValue, props.name, stato]);

    return (
        <>
            <input {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
};

export const PackagingDetails = (props) => {
    const [stato, setStato] = useContext(AppContext);
    // Disabling conversion from weight to Ounces
    // const [tempWeight, setTempWeight] = useState('');
    const dispatch = useDispatch();
    const quoteRequest = useSelector(state => state.quoteRequest);
    const shipmentRequest = useSelector(state => state.shipmentRequest);
    let totalBoxes = useMemo(() => {
        return stato.boxes
    }, [stato.boxes]);
    let {isPackselected, selectedPack, indexPackage} = stato;
    const box = 1;
    const [dims, setDims] = useState(null);
    const [packageInitialValues, setPackageInitialValues] = useState({});

    useEffect(() => {
        setStato({...stato, length: "In"});

        let packInitialValues;
        if (indexPackage in quoteRequest.pieces) {
            packInitialValues = {
                length: quoteRequest.pieces[indexPackage].length,
                width: quoteRequest.pieces[indexPackage].width,
                height: quoteRequest.pieces[indexPackage].height,
                weight: quoteRequest.weight,
                ounces: quoteRequest.ounces,
                insurance: quoteRequest.pieces[indexPackage].insuranceAmount,
                description: quoteRequest.contentDescription,
            }
        } else {
            packInitialValues = {
                length: selectedPack ? (stato.length === 'Cm') ? selectedPack.length_cm : selectedPack.length_in : '',
                width: selectedPack ? (stato.length === 'Cm') ? selectedPack.width_cm : selectedPack.width_in : '',
                height: selectedPack ? (stato.length === 'Cm') ? selectedPack.height_cm : selectedPack.height_in : '',
                weight: '',
                ounces: '',
                insurance: '',
                description: ''
            }
        }
        setPackageInitialValues(packInitialValues);
    }, []);

    useEffect(() => {
        let calcDims = 0;
        if ('pieces' in quoteRequest && quoteRequest.pieces.length > 0) {
            let height = parseFloat(quoteRequest.pieces[0].height) || 0;
            let width = parseFloat(quoteRequest.pieces[0].width) || 0;
            let length = parseFloat(quoteRequest.pieces[0].length) || 0;
            calcDims = Math.round(1000 * height * width * length / domesticDIMShipmentFactor) / 1000;
        }
        setDims(calcDims);
    }, [quoteRequest.pieces[0]])

    const cancelClicked = () => {
        setStato({...stato, isPackselected: false});
        props.updateShowPackagingDetails(false)
    }

    return (
        <Container className="mt-2">
            <Row className="mt-4">
                <Col sm="4">
                    {(() => {
                        switch (stato.selectedCarrier) {
                            case 'UPS':
                                return <img src={logoups} className="card-img " style={{height: '80px'}} alt="ups"/>;
                            case 'USPS':
                                return <img src={logousps} className="card-img " style={{height: '80px'}} alt="usps"/>;
                            case 'DHL':
                                return <img src={logodhl} className="card-img " style={{height: '80px'}} alt="dhl"/>;
                            default:
                                return <img src={logofedex} className="card-img " style={{height: '80px'}}
                                            alt="fedex"/>;
                        }
                    })()}
                </Col>
                <Col sm="2"></Col>
                <Col sm="6" className="shipment-package-card">
                    <Row>
                        <Col md="1" className="mt-3">
                            <img src={cartfilled} className="ship-icon" alt="cart-filled"/>
                        </Col>
                        <Col>
                            <ul className="ship-list">
                                <li className="ship-item">Total QTY:</li>
                                <li className="ship-item">Total Weight:</li>
                                <li className="ship-item">Ounces:</li>
                                <li className="ship-item">DIMS:</li>
                            </ul>
                        </Col>
                        <Col className="float-end text-end">
                            <ul className="ship-list result black font-weight-bold">
                                <li className="ship-item">{totalBoxes > 1 ? totalBoxes : box}</li>
                                <li className="ship-item">{stato.weight ? ((stato.length === 'In' || stato.length === undefined) ? stato.weight : convertWeightToLbs(stato.weight)) : 0} {(stato.length === 'In' || stato.length === undefined) ? "lbs" : "Kg"}</li>
                                <li className="ship-item">{stato.ounces ? stato.ounces : 0} oz</li>
                                <li className="ship-item">{dims}</li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-3 col-sm">
                <h4>Packaging details</h4>
            </Row>
            <div className="mt-3 col-sm pl-0 pr-0">
                {isPackselected &&
                <Formik
                    enableReinitialize={true}
                    initialValues={packageInitialValues}

                    validationSchema={Yup.object().shape({
                        length: Yup.number().required('Required').moreThan(0, 'Must be a number greater than zero.'),

                        width: Yup.number().required('Required').moreThan(0, 'Must be a number greater than zero.'),

                        height: Yup.number().required('Required').moreThan(0, 'Must be a number greater than zero.'),

                        weight: Yup.number().when('ounces', {
                            is: (ounces) => ounces > 0,
                            then: Yup.number().notRequired(),
                            otherwise: Yup.number().required('Required fields either Weight or Ounces').moreThan(0, 'Must be a number greater than zero.'),
                        }),

                        ounces: Yup.number().when('weight', {
                            is: (weight) => weight > 0,
                            then: Yup.number().notRequired(),
                            otherwise: Yup.number().required('').moreThan(0, 'Must be a number greater than zero.'),
                        }),

                        insurance: Yup.number().notRequired(),

                        description: Yup.string().notRequired().max(150, 'Must be at least 150 character.')
                    }, [['weight', 'ounces']])}

                    onSubmit={values => {
                        const piece = values;

                        piece.weight = parseFloat(piece.weight) || 0;
                        piece.ounces = parseFloat(piece.ounces) || 0;
                        stato.weight = parseFloat(stato.weight) || 0;
                        stato.ounces = parseFloat(stato.ounces) || 0;

                        quoteRequest.package_type = selectedPack.id;
                        quoteRequest.dimUnit = stato.length;
                        quoteRequest.contentDescription = piece.description;
                        // TODO: move ounces and weight to per package data (also description ?)
                        quoteRequest.ounces = piece.ounces;
                        quoteRequest.weight = piece.weight;

                        const weightInPounds = (stato.length === 'In' || stato.length === undefined) ? piece.weight : convertWeightToLbs(piece.weight);
                        const weightPoundsPlusOunces = roundToTwoDecimal(parseFloat(weightInPounds) + ( piece.ounces / 16)).toString();

                        //Setup this default values to get quote response
                        quoteRequest.currency = "USD";
                        quoteRequest.customsCurrency = "USD";
                        quoteRequest.weightUnit = "lb";
                        quoteRequest.dimUnit = "in";
                        quoteRequest.pieces[indexPackage] = ({
                            weight: weightPoundsPlusOunces,
                            length: (stato.length === 'In') ? roundToTwoDecimal(piece.length).toString() : convertlengthToInches(piece.length).toString(),
                            height: (stato.length === 'In') ? roundToTwoDecimal(piece.height).toString() : convertlengthToInches(piece.height).toString(),
                            width: (stato.length === 'In') ? roundToTwoDecimal(piece.width).toString() : convertlengthToInches(piece.width).toString(),
                            insuranceAmount: piece.insurance.toString(),
                            declaredValue: null
                        })

                        shipmentRequest.contentDescription = piece.description;
                        shipmentRequest.packages[indexPackage] = ({
                            weight: weightPoundsPlusOunces,
                            length: (stato.length === 'In') ? roundToTwoDecimal(piece.length).toString() : convertlengthToInches(piece.length).toString(),
                            height: (stato.length === 'In') ? roundToTwoDecimal(piece.height).toString() : convertlengthToInches(piece.height).toString(),
                            width: (stato.length === 'In') ? roundToTwoDecimal(piece.width).toString() : convertlengthToInches(piece.width).toString(),
                            insurance_amount: piece.insurance.toString(),
                            package_type: selectedPack.id
                        })

                        const newStatoWeight = ((stato.length === 'In' || stato.length === undefined) ? piece.weight : convertWeightToLbs(piece.weight));

                        dispatch(updateQuoteRequest(quoteRequest));
                        dispatch(updateShipmentRequest(shipmentRequest));
                        dispatch(removeCommercialInvoicePackages(0))
                        dispatch(addCommercialInvoicePackages(values));
                        setStato({
                            ...stato, isPackselected: true,
                            weight: roundToTwoDecimal(newStatoWeight),
                            ounces: piece.ounces,
                            // ounces: (stato.length === 'Cm') ? values.ounces * 35.274 : values.ounces * 16
                        });
                        props.updateShowPackagingDetails(false);
                    }}
                >
                    {({
                          errors,
                          values,
                          touched,
                          handleChange,
                          setFieldValue
                      }) => {
                        return (
                            <Form>
                                <div className="form-row gray">
                                    <div className="form-group col-sm">
                                        <label htmlFor="inputUnits">Units</label><i
                                        className="bi bi-info-circle packaging-details"></i>
                                        <SelectMisurate/>
                                    </div>
                                    <div className="form-group col-sm">
                                        <label htmlFor="length">Length
                                            ({(stato.length === 'Cm') ? "Cm" : "Inches"})</label><i
                                        className="bi bi-info-circle packaging-details"></i>
                                        <input className="form-control" autoFocus
                                               disabled={!(selectedPack.is_custom === true)}
                                               name="length"
                                               id="length"
                                               type="text"
                                               placeholder={(stato.length === 'Cm') ? "Cm" : "In"}
                                               value={values.length}
                                               onChange={handleChange}/>
                                        {touched.length && <div className="ship-input-error ">{errors.length}</div>}
                                    </div>
                                    <div className="form-group col-sm">
                                        <label htmlFor="width">Width
                                            ({(stato.length === 'Cm') ? "Cm" : "Inches"})</label><i
                                        className="bi bi-info-circle packaging-details"></i>
                                        <input className="form-control "
                                               disabled={!(selectedPack.is_custom === true)}
                                               name="width"
                                               id="width"
                                               type="text"
                                               placeholder={(stato.length === 'Cm') ? "Cm" : "In"}
                                               value={values.width}
                                               onChange={handleChange}/>
                                        {touched.width && <div className="ship-input-error ">{errors.width}</div>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm">
                                        <label htmlFor="height">Height
                                            ({(stato.length === 'Cm') ? "Cm" : "Inches"}) </label><i
                                        className="bi bi-info-circle packaging-details"></i>
                                        <input className="form-control "
                                               disabled={!(selectedPack.is_custom === true)}
                                               name="height"
                                               id="height"
                                               type="text"
                                               placeholder={(stato.length === 'Cm') ? "Cm" : "In"}
                                               value={values.height}
                                               onChange={handleChange}/>
                                        {touched.height && <div className="ship-input-error ">{errors.height}</div>}
                                    </div>
                                    <div className="form-group col-sm">
                                        <label htmlFor="weight"> Weight
                                            ({(stato.length === 'Cm') ? "kg" : "Lbs"})</label><i
                                        className="bi bi-info-circle packaging-details"></i>
                                        <Field className="form-control "
                                               name='weight'
                                               id="weight"
                                               type="text"
                                               placeholder={(stato.length === 'Cm') ? "0 kg" : "0 lbs"}
                                               value={values.weight}
                                               // onChange={(e, selected) => {
                                               //    setTempWeight(e.target.value);
                                               // }}
                                               onChange={handleChange}
                                               />
                                        {touched.weight && <div className="ship-input-error ">{errors.weight}</div>}
                                    </div>
                                    <div className="form-group col-sm">
                                        <label htmlFor="ounces">Ounces (Oz)</label><i
                                        className="bi bi-info-circle packaging-details"></i>
                                        { // Disabling custom component for field Ounces
                                        /*
                                        <OuncesField className="form-control "
                                                     disabled={!(selectedPack.is_custom === true)}
                                                     length={stato.length}
                                                     name='ounces'
                                                     id="ounces"
                                                     type="text"
                                                     onChange={handleChange}
                                                     placeholder="0 oz"/>
                                        */ }
                                        <Field className="form-control "
                                               name='ounces'
                                               id="ounces"
                                               type="text"
                                               disabled={!(selectedPack.is_custom === true)}
                                               placeholder="0 oz"
                                               value={values.ounces}
                                               onChange={handleChange}
                                               />
                                        {touched.ounces && <div className="ship-input-error ">{errors.ounces}</div>}
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-sm">
                                        <label htmlFor="insurance">Insurance Amount</label> <i
                                        className="bi bi-info-circle packaging-details"></i>
                                        <input className="form-control"
                                               placeholder="$0.00"
                                               id="insurance"
                                               type="text"
                                               name='insurance' value={values.insurance}
                                               onChange={handleChange}/>
                                        {touched.insurance &&
                                        <div className="ship-input-error ">{errors.insurance}</div>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm">
                                        <label htmlFor="description"> Overall Package Description</label><i
                                        className="bi bi-info-circle packaging-details" style={{left: "160px"}}></i>
                                        <textarea className="form-control"
                                                  id="description" name='description'
                                                  placeholder="Description"
                                                  rows="3"
                                                  value={values.description}
                                                  onChange={handleChange}/>
                                        {touched.description &&
                                        <div className="ship-input-error">{errors.description}</div>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-check SaveCheck">
                                        <input className="form-check-input" type="checkbox" id="SaveCheck"/>
                                        <label className="form-check-label black" style={{marginTop: '2px'}}
                                               htmlFor="gridCheck">
                                            Save package details for later use?
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <button className="ship-secondary-custom-button w-100" type="button"
                                                onClick={cancelClicked}>Cancel
                                        </button>
                                    </div>
                                    <div className="col-sm">
                                        <button className="ship-primary-custom-button w-100" type="submit">Save Changes</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
                }
            </div>
        </Container>
    );

}
export default PackagingDetails;
