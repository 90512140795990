import {UPDATE_COMMERCIAL_INVOICE_REQUEST, RESET_COMMERCIAL_INVOICE_REQUEST,CREATE_COMMERCIAL_INVOICE_SUCCESS, ADD_COMMERCIAL_INVOICE_PACKAGES, REMOVE_COMMERCIAL_INVOICE_PACKAGES} from "../actions/commercial.invoices.action";

const initialCommercialInvoiceState = {
    commercialInvoiceRequest: {},
    commercialInvoiceResponse: {},
    packages: []
}

export const CommercialInvoiceReducer = (state = initialCommercialInvoiceState, action) => {
    switch (action.type) {
        case UPDATE_COMMERCIAL_INVOICE_REQUEST:
            return { ...state, commercialInvoiceRequest: {...action.payload}}
        case RESET_COMMERCIAL_INVOICE_REQUEST:
            return { ...state, commercialInvoiceRequest: {}};
        case CREATE_COMMERCIAL_INVOICE_SUCCESS:
            return {...state, commercialInvoiceResponse: {...action.payload}};
        case ADD_COMMERCIAL_INVOICE_PACKAGES:
            return {...state, packages: [...state.packages, action.payload]}
        case REMOVE_COMMERCIAL_INVOICE_PACKAGES:
            state.packages.splice(action.payload, 1);
            return state;
        default:
            return state;
    }
}
