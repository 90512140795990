import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateQuoteRequest } from "../../redux/actions/quotes.actions";
import { updateShipmentRequest } from '../../redux/actions/shipments.actions';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';

export const AdditionalInformation = (props) => {
  const [isCommercialInvoiceToggleActive, setIsCommercialInvoiceToggleActive] = useState(false);
  const quoteRequest = useSelector(state => state.quoteRequest);
  const signatures = useSelector(state => state.signatures);
  const shipmentRequest = useSelector(state => state.shipmentRequest);
  const dispatch = useDispatch();

  const customStylesSelectedService = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '0px',
      backgroundColor: 'white',
      cursor: 'pointer',
      color: state.isSelected ? '#1F2D3D' : '#9ea3b0',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      borderRadius: '10px',
      fontSize: "14px !important",
      fontWeight: "500 !important",
      '&:hover': {
        backgroundColor: '#F6F7FB',
        fontWeight: 'bold',
        color: '#1F2D3D'
      }
    }),
    container: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      borderColor: '#D4DAE9',
      borderWidth: '1px',
      borderRadius: '10px'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      paddingLeft: '10px',
    }),
    indicatorsContainer: () => ({
      backgroundColor: 'white',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      display: state.menuIsOpen ? 'inline' : 'none'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: state.menuIsOpen ? 'none' : 'inline'
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      border: '1px solid #D4DAE9',
      boxShadow: 'none',
      padding: '0px',
      outline: 'none',
      borderRadius: '10px',
      backgroundColor: 'white',
      '&:hover': {
        borderColor: '#D4DAE9',
        borderWidth: '1px',
        boxShadow: 'none',
      }
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0 : 1;
      const transition = 'opacity 300ms';
      const fontSize = "14px !important";
      const fontWeight = "600 !important";
      return { ...provided, opacity, transition, fontSize, fontWeight };
    },
    menuList: (base) => ({
      ...base,
      cursor: 'pointer',
      "::-webkit-scrollbar": {
        width: "9px"
      },
      "::-webkit-scrollbar-track": {
        background: "white"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#D4DAE9",
        borderRadius: '100px'
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#D4DAE9"
      }
    })
  }

  const courierSignatureOptions = signatures.filter( signature => signature.courier === props.courier);
  const signatureOptions = courierSignatureOptions.map(signature => ({ label: signature.jusda_label, value: signature.id }));
  const defaultSignature = courierSignatureOptions.find((signature) => signature.internal_id == 'NO_SIGNATURE_REQUIRED');

  const selectedSignature = defaultSignature ? {label: defaultSignature.jusda_label, value: defaultSignature.id} : null;

  const billingPartyOptions = [({ label: "Receiver", value: "receiver" }), ({ label: "Sender", value: "sender" })];

  useEffect(() => {
    if(isCommercialInvoiceToggleActive) {
      props.showCommercialInvoice(true);
    } else {
      props.showCommercialInvoice(false);
    }
  }, [isCommercialInvoiceToggleActive])

  useEffect(() => {
    if (selectedSignature) {
      updateSignature(selectedSignature);
    }
  }, [])

  const updateSaturdayDelivery = (e) => {
    quoteRequest.saturdayDelivery = e.currentTarget.checked
    dispatch(updateQuoteRequest(quoteRequest))
  }

  const updateSignature = (signature) => {
    quoteRequest.signature = shipmentRequest.signature = signature.value;
    dispatch(updateQuoteRequest(quoteRequest));
    dispatch(updateShipmentRequest(shipmentRequest));
  }

  const updateBilling = (billing) => {
    quoteRequest.billing = { party: billing.value }
    dispatch(updateQuoteRequest(quoteRequest));
  }

  const handleCommercialInvoiceToggle = () => {
    setIsCommercialInvoiceToggleActive(!isCommercialInvoiceToggleActive);
  }

  const showCommercialInvoice = () => {
    if(setIsCommercialInvoiceToggleActive) {
      props.showCommercialInvoice(!props.isCommercialInvoiceActive);
    }
  }

  return (
    <>
      <Container className="ml-0 pl-0">
        <Row>
          <Col xs="12" className="mt-4 pl-0 ml-0">
            <div className="h6 font-weight-bold">Additional Information</div>
            <div className="item-sx mrg-tb20"> Re-use previous order history dimensions, or create new shipment dimensions</div>
          </Col>
        </Row>

        <Row>
          <Col xs="3" className="mt-2 pl-0">
            <Col xs="1" sm="1" lg="1" className="mt-0 mr-1 pr-0">
              <i className="bi bi-info-circle mt-2" style={{ left: "5px" }} ></i>
            </Col>
            <Col xs="11" sm="11" lg="11" className="mt-0">
              <div className="item-sx ml-2 additional-info-label">Signature Required</div>
            </Col>
          </Col>
          <Col xs="1" className="mr-3">
          </Col>
          <Col xs="5" className="ml-0 pl-0">
            <Select isSearchable={false} options={signatureOptions} styles={customStylesSelectedService} onChange={updateSignature} defaultValue={selectedSignature} />
          </Col>
        </Row>

        {quoteRequest.signature && <Row className="mt-2">
          <Col xs="3" className="mt-2 pl-0">
            <Col xs="1" sm="1" lg="1" className="mt-0 mr-1 pr-0">
              <i className="bi bi-info-circle mt-2" style={{ left: "5px" }} ></i>
            </Col>
            <Col xs="11" sm="11" lg="11" className="mt-0">
              <div className="item-sx ml-2 additional-info-label">Change Billing Party</div>
            </Col>
          </Col>
          <Col xs="1" className="mr-3">
            <label className="ship-switch mt-2 mr-2">
              <input type="checkbox" />
              <span className="ship-slider ship-round"></span>
            </label>
          </Col>
          <Col xs="5" className="ml-0 pl-0">
            <Select isSearchable={false} styles={customStylesSelectedService} options={billingPartyOptions} onChange={updateBilling} />
          </Col>
        </Row>}

        {quoteRequest.signature && <Row className="mt-2">
          <Col xs="3" className="mt-2 pl-0">
            <Col xs="1" sm="1" lg="1" className="mt-0 mr-1 pr-0">
              <i className="bi bi-info-circle mt-2" style={{ left: "5px" }} ></i>
            </Col>
            <Col xs="11" sm="11" lg="11" className="mt-0">
              <div className="item-sx ml-2 additional-info-label"> Saturday Delivery </div>
            </Col>
          </Col>
          <Col xs="1" className="mr-3">
            <label className=" ship-switch mt-2 ">
              <input type="checkbox" onChange={updateSaturdayDelivery}/>
              <span className="ship-slider ship-round"></span>
            </label>
          </Col>
        </Row>}

        {quoteRequest.signature && <Row className="mt-2" >
          <Col xs="3" className="mt-2 pl-0">
            <Col xs="1" sm="1" lg="1" className="mt-0 mr-1 pr-0">
              <i className="bi bi-info-circle mt-2" style={{ left: "5px" }} ></i>
            </Col>
            <Col xs="11" sm="11" lg="11" className="mt-0">
              <div className="item-sx ml-2 additional-info-label"> Shipping Updates </div>
            </Col>
          </Col>
          <Col xs="1" className="mr-3">
            <label className=" ship-switch mt-2">
              <input type="checkbox" />
              <span className="ship-slider ship-round"></span>
            </label>
          </Col>
          <Col xs="5" className="ml-0 pl-0">
            <button className="ship-btn-manage ml-0 gray" onClick={props.showUpdates} type="button">Manage Updates</button>
          </Col>
        </Row>}

        {quoteRequest.signature && <Row className="mt-2" >
          <Col xs="3" className="mt-2 pl-0">
            <Col xs="1" sm="1" lg="1" className="mt-0 mr-1 pr-0">
              <i className="bi bi-info-circle mt-2" style={{ left: "5px" }} ></i>
            </Col>
            <Col xs="11" sm="11" lg="11" className="mt-0">
              <div className="item-sx ml-2 additional-info-label"> Commercial Invoice </div>
            </Col>
          </Col>
          <Col xs="1" className="mr-3">
            <label className=" ship-switch mt-2">
              <input type="checkbox" defaultChecked={false} onChange={handleCommercialInvoiceToggle} />
              <span className="ship-slider ship-round"></span>
            </label>
          </Col>
          <Col className="ml-0 pl-0 float-end ms-auto">
            <button className="ship-btn-manage ml-0" disabled={!isCommercialInvoiceToggleActive}
                    style={{opacity: !isCommercialInvoiceToggleActive ? '50%' : '100%'}}
                    onClick={showCommercialInvoice}>Edit Invoice</button>
          </Col>
        </Row> }
      </Container>
    </>
  );


}

export default AdditionalInformation;
