import {CREATE_USER_SUCCESS, GET_USER_SUCCESS } from "../actions/users.actions";

const initialUsersState = {emailRegister: null, loggedUser: {}};

export const UsersReducer = (state = initialUsersState, action) => {
    switch (action.type) {
        case CREATE_USER_SUCCESS:
            return {...state, emailRegister: action.payload};
        case GET_USER_SUCCESS:
            return {...state, loggedUser: action.payload};
        default:
            return state;
    }
}
