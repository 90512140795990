import React, {useEffect} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import {Link, useLocation} from "react-router-dom";
import {CUSTOMERS, INVOICES, ADMIN_INVOICES, ADMINISTRATION} from "../../../navigation/ROUTECONSTANTS";
import { useSelector } from "react-redux";

export const SidebarContent = (props) => {

    const location = useLocation();
    const isAdmin = useSelector(state => state.users.loggedUser.is_superuser);

    useEffect(() => {
        initMenu();
    }, [props]);

    const initMenu = () => {
        new MetisMenu("#side-menu");
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
            } else{
                deActivateParentDropdown(items[i]);
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    }

    const deActivateParentDropdown = (item) => {
        item.classList.remove("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.remove("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.remove("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.remove("mm-active"); // li
                    parent3.childNodes[0].classList.remove("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.remove("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    }

    return (
        <React.Fragment>
            <div id="sidebar-menu">

                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{'Menu'}</li>

                    <li>
                        <Link to="/dashboard" className="waves-effect">
                            <i className="ri-dashboard-line"></i>
                            <span className="ml-1">{'Dashboard'}</span>
                        </Link>
                    </li>
                      <li>
                        <Link to="/new-shipment" className="has-arrow waves-effect">
                        <i className="ri-luggage-cart-lines"></i>
                            <span className="ml-1">{'New Shipment'}</span>
                        </Link>
{/*                        <Link to="/re-shipment" className="has-arrow waves-effect">
                        <i className="ri-luggage-cart-lines1"></i>
                            <span className="ship-btn-menu ship-luggage-cart">{'Recent Shipment'}</span>
                        </Link>
                        <Link to="/order-history" className="has-arrow waves-effect">
                        <i className="ri-luggage-cart-lines2"></i>
                            <span className="ship-btn-menu ship-luggage-cart">{'Order History'}</span>
                        </Link>
                        <Link to="/ship" className="has-arrow waves-effect">
                        <i className="ri-luggage-cart-lines3"></i>
                            <span className="ship-btn-menu ship-luggage-cart">{'Option Type'}</span>
                        </Link>*/}

                    </li>
					<li>
                        <Link to="/tracking" className="has-arrow waves-effect">
                            <i className="ri-map-pin-line"></i>
                            <span className="ml-1">{'Tracking'}</span>
                        </Link>
                    </li>

                    <li>
                        <Link to={CUSTOMERS} className="has-arrow waves-effect">
                            <i className="ri-account-circle-line"></i>
                            <span className="ml-1">{'Customers'}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={isAdmin ? ADMIN_INVOICES : INVOICES} className="has-arrow waves-effect">
                            <i className="ri-wallet-fill"></i>
                            <span className="ml-1">{'Invoices'}</span>
                        </Link>
                    </li>
                    {isAdmin &&
                        <li>
                            <Link to={ADMINISTRATION} className="has-arrow waves-effect">
                                <i className="pi pi-sliders-h"></i>
                                <span className="ml-1">{'Administration'}</span>
                            </Link>
                        </li>
                    }

                </ul>
            </div>
        </React.Fragment>
    );
}

export default SidebarContent;
