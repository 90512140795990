import {Card, CardBody} from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from "react";
import { getBasicStat } from "../../services/shipmentsService";
import { convertBasicStatFormat } from "../../utils";
import { STAT_CODE_BY_STATE } from "../../services/APICONSTANTS";


export const DeliveredAnalyticsComponent = (props) => {

    const defaultData = {
        series: [{
            name: 'Delivered',
            data: [25, 46, 57, 58, 54, 62, 58, 64, 60, 66, 11, 55]
        }, {
            name: 'In-transit',
            data: [20, 50, 25, 16, 52, 53, 15, 55, 63, 6, 9, 40]
        }],
        options : {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },

            colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1'],
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
                title: {
                    text: 'n.'
                }
            },
            grid: {
                borderColor: '#f1f1f1',
            },
            fill: {
                opacity: 1

            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        }
    };

    const [statData, setStatData] = useState({
        series: [],
        options: {}
    });

    useEffect(() => {
        getBasicStat(STAT_CODE_BY_STATE).then((responseData) => {
            let statData = defaultData;

            const convertedStat = convertBasicStatFormat(responseData);
            if (convertedStat.categories.length > 0) {
                statData.options.xaxis.categories = convertedStat.categories;
                statData.series = convertedStat.series;
            }

            setStatData(statData);
        });
    }, [])

    return (
        <Card>
            <CardBody>
                <div className="card-title mb-4 font-weight-bold h4">Shipment by status</div>
                <div>
                    <ReactApexChart options={statData.options} series={statData.series} type="bar" height="280" />
                </div>
            </CardBody>
        </Card>
    )
}

export default DeliveredAnalyticsComponent;
