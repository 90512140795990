import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import PayInvoiceForm from "./payInvoiceForm";
import image from "../../../assets/images/modal-header-logo.png";
import { setInvoicesState } from "../../../redux/actions/invoices.actions";
const ModalTitle = ({ title }) => {
  return (
    <div className="modal-title p-grid p-justify-between">
      <h2>{title}</h2>
      <img src={image} alt="modal-header-logo" />
    </div>
  );
};

const PayInvoiceModal = () => {
  const { payInvoiceModal = {} } = useSelector((state) => ({
    payInvoiceModal: state.invoices.payInvoiceModal,
  }));
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setInvoicesState({
      payInvoiceModal: {
        open: false,
        invoice: {}
      }
    }))
  }

  return (
    <Dialog
      closable={false}
      header={
        <ModalTitle
          title={`Pay Invoice - ${payInvoiceModal.invoice.invoiceId}`}
        />
      }
      onHide={() => onCancel()}
      visible={payInvoiceModal.open}
    >
      <PayInvoiceForm />
    </Dialog>
  );
};

export default PayInvoiceModal;
