import React from "react";
import { Redirect, Switch } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import {
  CREATE_ACCOUNT,
  CUSTOMERS,
  DASHBOARD,
  EDIT_PROFILE,
  LOGIN,
  LOGOUT,
  ON_BOARDING,
  PROFILE,
  RESET_PASSWORD,
  ROOT,
  SHIPMENT,
  INVOICES,
  ADMIN_INVOICES,
  ADMINISTRATION,
  LOGIN_ADMIN,
  TRACKING, SHIPMENT_CREATION
} from "./ROUTECONSTANTS";
import Login from "../pages/on-boarding/Login";
import ResetPassword from "../pages/on-boarding/ResetPassword";
import CreateAccount from "../pages/on-boarding/CreateAccount";
import NotFound from "../pages/error/NotFound";
import OnBoarding from "../pages/on-boarding/OnBoarding";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import Layout from "../theme/components/layout";
import Logout from "../pages/on-boarding/Logout";
import AddressBook from "../pages/addressbook/AddressBook";
import Shipment from "../pages/shipment/Shipment";
import Profile from "../pages/user/Profile";
import EditProfile from "../pages/user/EditProfile";
import Invoices from "../pages/invoices"
import AdminInvoices from "../pages/admin/admin-invoices"
import Administration from "../pages/administration/index";
import LoginAdmin from "../pages/administration/login";
import Tracking from "../pages/tracking/Tracking";
import CurrentShipment from "../pages/shipment/CurrentShipment";

const getIsLoggedIn = () => {
  return !!(
    localStorage.getItem("sd-access") &&
    localStorage.getItem("sd-refresh") &&
    localStorage.getItem("sd-user")
  );
};

const requireLogin = (to, from, next) => {
  if (to.meta.auth) {
    if (getIsLoggedIn()) {
      next();
    }
    next.redirect("/on-boarding");
  } else {
    next();
  }
};

export const RouterConfig = () => {
  return (
    <GuardProvider guards={[requireLogin]} error={NotFound}>
      <Switch>
        {/* List all public routes here */}
        <GuardedRoute exact path={ON_BOARDING}>
          <OnBoarding />
        </GuardedRoute>
        <GuardedRoute exact path={LOGIN}>
          <Login />
        </GuardedRoute>
        <GuardedRoute exact path={RESET_PASSWORD}>
          <ResetPassword />
        </GuardedRoute>

        <GuardedRoute exact path={CREATE_ACCOUNT}>
          <CreateAccount />
        </GuardedRoute>

        {/* List all private/auth routes here */}
        <Layout>
          <GuardedRoute path={ROOT} exact meta={{ auth: true }}>
            <Redirect to={DASHBOARD} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={LOGOUT}
            meta={{ auth: true }}
            component={Logout}
          />
          <GuardedRoute
            exact
            path={DASHBOARD}
            component={Dashboard}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path={CUSTOMERS}
            component={AddressBook}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path={SHIPMENT_CREATION}
            component={Shipment}
            meta={{ auth: true }}
          />
          <GuardedRoute
              exact
              path={SHIPMENT + '/:id'}
              component={CurrentShipment}
              meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path={PROFILE}
            component={Profile}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path={EDIT_PROFILE}
            component={EditProfile}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path={INVOICES}
            component={Invoices}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path={ADMIN_INVOICES}
            component={AdminInvoices}
            meta={{ auth: true }}
          />
            <GuardedRoute
            exact
            path={ADMINISTRATION}
            component={Administration}
            meta={{ auth: true }}
          />
         <GuardedRoute
            exact
            path={LOGIN_ADMIN}
            component={LoginAdmin}
            meta={{ auth: true }}
          />
          <GuardedRoute
              exact
              path={TRACKING}
              component={Tracking}
              meta={{ auth: true }}
          />
        </Layout>


        {/* List a generic 404-Not Found route here */}
      </Switch>
    </GuardProvider>
  );
};
