import { GET_PACKAGES_SUCCESS } from "../actions/packages.actions";

const initialPackagesState = [];

export const PackagesReducer = (state = initialPackagesState, action) => {
    switch (action.type) {
        case GET_PACKAGES_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
}