import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutsideAlerter } from "../../hooks";
import userFilled from "../../assets/images/User-filled.png";
import searchIcon from "../../assets/images/search-icon.png";
import debounce from 'lodash/debounce';
import "./styles.scss";
import { getAutocompleteList, resetAutocompleteList, setAutocompleteSelectedId } from "../../redux/actions/accounts.actions";

const AutoComplete = () => {
  const customersRef = useRef(null);
  const searchFieldRef = useRef(null);
  const rowsPerPage = 5;

  const { autocompleteList } = useSelector((state) => ({autocompleteList: state.accounts.autocompleteList}))
  const [showListCustomers, setShowListCustomers] = useState(false);
  const dispatch = useDispatch();

  useOutsideAlerter(customersRef, setShowListCustomers);

  const onSearchChange = (e) => {
    dispatch(setAutocompleteSelectedId(null));

    if (searchFieldRef.current.value.length > 2) {
      dispatch(getAutocompleteList({
        page: 1,
        limit: rowsPerPage,
        filter: searchFieldRef.current.value,
        order: null,
        sort: null,
      }));
    } else if (searchFieldRef.current.value.length > 0) {
      dispatch(resetAutocompleteList());
    }
  };

  const onItemSelected = (accountId, accountFullName) => {
    dispatch(resetAutocompleteList());
    searchFieldRef.current.value = accountFullName;
    dispatch(setAutocompleteSelectedId(accountId));
  }

  const debouncedOnChange = debounce(onSearchChange, 500);

  useEffect(() => {
    setShowListCustomers(autocompleteList.length > 0);
  }, [autocompleteList]);

  useEffect(() => {
    dispatch(setAutocompleteSelectedId(null));
  }, []);

  return (
    <div className="AutoComplete-container" ref={customersRef}>
      <div className="input-container">
      <img src={searchIcon} alt="searchIcon" onClick={(e) => onSearchChange(e)}/>
        <input type="text" onChange={(e) => debouncedOnChange(e)} ref={searchFieldRef} />
      </div>
      {showListCustomers && (
        <div className="results-list">
          <div className="customers-description">
            <img src={userFilled} alt="userFilled" />
            <span>Customers</span>
          </div>
          <ul>
            {autocompleteList.map((account) => (
              <li key={account.id} onClick={(e) => (onItemSelected(account.id, account.fullName))}>{account.fullName} <small>[{account.email}]</small></li>
            ))}
          </ul>
          { false && // Disabled
          <div
            className="show-more"
            onClick={() => setShowListCustomers(false)}
          >
            show more customers ({autocompleteList.length - 4})
          </div>
          }
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
