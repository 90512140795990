import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Sidebar } from "primereact/sidebar";
import {getSelectedInvoiceAccount, setAdminInvoicesState} from "../../../../redux/actions/admin.invoices.actions"
import userIcon from "../../../../assets/images/user-invoice-icon.png";
import companyAddress from "../../../../assets/images/company-address.png";
import companyIcon from "../../../../assets/images/company-icon.png";
import phoneIcon from "../../../../assets/images/phone-fix-icon.png";
import "../../../../styles/sidebar.scss";
import "primeflex/primeflex.css";
import { joinAccountAddress } from "../../../../utils";

export default function AccountManagement() {
  const dispatch = useDispatch()
  const openAccountManagement = useSelector((state) => state.adminInvoices.openAccountManagement);
  const selectedInvoiceAccount = useSelector((state) => state.adminInvoices.selectedInvoiceAccount);

  const setSideBarHidden = () => {
    dispatch(setAdminInvoicesState({
      openAccountManagement: {
        ...openAccountManagement,
        open: false,
      }
    }))
  }

  useEffect(()=>{
    if (document.getElementsByClassName("p-sidebar-mask")?.length> 0) {
      document.getElementsByClassName("p-sidebar-mask")[0].style.backdropFilter= "none"
    }
  }, [])

  useEffect(() => {
    if (openAccountManagement.selectedInvoice.owner_account_id) {
      dispatch(getSelectedInvoiceAccount(openAccountManagement.selectedInvoice.owner_account_id));
    }
  }, [openAccountManagement.selectedInvoice])

  const deleteAccount = () => {
    setSideBarHidden();
    dispatch(setAdminInvoicesState({
      deleteAccountModal: {
        open: true
      }
    }));
  }

  const enableAccount = () => {
    setSideBarHidden();
    dispatch(setAdminInvoicesState({
      enableAccountModal: {
        open: true
      }
    }));
  }

  const disableAccount = () => {
    setSideBarHidden();
    dispatch(setAdminInvoicesState({
      disableAccountModal: {
        open: true
      }
    }));
  }

  return (
    <Sidebar
      visible={openAccountManagement.open}
      position="right"
      onHide={setSideBarHidden}
      className={`admin-right-sidebar`}
      dismissable
      showCloseIcon={false}
      blockScroll
      baseZIndex={2002}
    >
    {selectedInvoiceAccount &&
      <div className={`container account-management`}>
        {!selectedInvoiceAccount.is_active &&
          <div className="account-disabled-button">
            Account Disabled
          </div>
        }
        <div className="icon-container">
          <img src={userIcon} alt="user-icon" />
        </div>
        <div className="username">{openAccountManagement.selectedInvoice.customerName}</div>
        <ul className="company-infos">
          <li>
            <img src={companyIcon} alt="companyIcon" />
            {openAccountManagement.selectedInvoice.company}
          </li>
          <li>
            <img src={companyAddress} alt="companyAddress" />
            {joinAccountAddress(selectedInvoiceAccount)}
          </li>
          <li>
            <img src={phoneIcon} alt="phoneIcon" />
            {selectedInvoiceAccount.phone}
          </li>
        </ul>
      </div>
    }
    {selectedInvoiceAccount && selectedInvoiceAccount.is_active &&
      <div className="sidebar-footer p-grid p-justify-between">
        <div className="p-col-6">
          <button className="delete-button" onClick={() => deleteAccount()}>Delete</button>
        </div>
        <div className="p-col-6">
          <button className="disable-button" onClick={() => disableAccount()}>Disable Account</button>
        </div>
      </div>
    }
    {selectedInvoiceAccount && !selectedInvoiceAccount.is_active &&
      <div className="sidebar-footer p-grid p-justify-between">
        <div className="p-col-6">
          <button className="delete-button" onClick={() => deleteAccount()}>Delete</button>
        </div>
        <div className="p-col-6">
          <button className="enable-button" onClick={() => enableAccount()}>Enable Account</button>
        </div>
      </div>
    }
    </Sidebar>
  );
}
