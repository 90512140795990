import React, {useEffect} from 'react';
import logo from '../../assets/icons/on-boarding/ShipDistrict logo horiz_blk_red_1797 1.svg';
import OnBoardingCarousel from "../../components/onboarding/OnBoardingCarouselComponent";
import {Card, CardBody, Container, FormGroup, UncontrolledTooltip} from 'reactstrap';
import {CREATE_ACCOUNT, RESET_PASSWORD} from "../../navigation/ROUTECONSTANTS";
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Control, Errors, Form} from 'react-redux-form';
import '../../App.scss';
import '../../styles/OnBoarding.css';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {login} from "../../redux/actions/auth.actions";
import {cleanSession} from "../../utils/index"


export const Login = () => {
    const maxLength = (len) => (val) => !(val) || (val.length <= len);
    const minLength = (len) => (val) => val && (val.length >= len);
    const validUsername = (val) => /^[A-Za-zÀ-ȕ0-9,-_.@+]+$/i.test(val) && (val?.length > 1) && (val?.length <= 150);

    const dispatch = useDispatch();
    const formIsValid = useSelector(state => state.forms.login.$form.valid);
    const history = useHistory();

    useEffect(() => {
        cleanSession();
    }, []);

    function loginSubmit(data, event) {
        event.preventDefault();
        dispatch(login(data, history));
    }

    return (
        <Container fluid className="custom-container-padding">
            <Card className="w-100 mt-3 mb-3 page-content">
                <CardBody className="w-100">
                    <div className="row">
                        <div className="col-9 col-xs-6 col-sm-6 col-md-8 col-xl-9">
                            <div className="row justify-content-center on-boarding-login-section mb-5">
                                <img src={logo} alt="ShipDistrict" height="80" width="180"/>
                            </div>
                            <div className="row justify-content-center ">
                                <h4 className="mt-5">Login</h4>
                            </div>
                            <Form className="mt-5 justify-content-center" model="login"
                                  onSubmit={(formModelData, event) => loginSubmit(formModelData, event)}>
                                <FormGroup className="row justify-content-center">
                                    <Control.text model=".username" id="username" name="username"
                                                  placeholder="Enter username or email address"
                                                  className="form-control ship-input custom-input-margin"
                                                  validators={{
                                                      validUsername,
                                                  }}
                                    />
                                    <span>
                                        <InfoOutlinedIcon fontSize="small" id="usernameTooltip" className="info-icon"/>
                                    </span>
                                    <UncontrolledTooltip placement="top" target="usernameTooltip">
                                        Please ensure email address is following correct email format
                                    </UncontrolledTooltip>
                                    <Errors
                                        className="text-center ship-input-error"
                                        model=".username"
                                        show="touched"
                                        messages={{
                                            validUsername: 'Invalid username or email',
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="row justify-content-center">
                                    <Control.input model=".password" id="password" name="password"
                                                   placeholder="Password"
                                                   className="form-control ship-input custom-input-margin"
                                                   type="password"
                                                   validators={{
                                                       minLength: minLength(1),
                                                       maxLength: maxLength(128)
                                                   }}
                                    />
                                    <span className="">
                                        <InfoOutlinedIcon fontSize="small" id="passwordTooltip" className="info-icon"/>
                                    </span>
                                    <UncontrolledTooltip placement="top" target="passwordTooltip">
                                        Please enter password for account
                                    </UncontrolledTooltip>
                                    <Errors
                                        className="ship-input-error text-center"
                                        model=".password"
                                        show="touched"
                                        messages={{
                                            minLength: 'Password must be greater than 1 character',
                                            maxLength: 'Password be 150 characters or less'
                                        }}
                                    />
                                </FormGroup>
                                <div className="row justify-content-center">
                                    <button className="w-100 ship-primary-custom-button"
                                            disabled={!formIsValid}
                                            type="submit"
                                            style={{maxWidth: "333px"}}>
                                        Login
                                    </button>
                                </div>
                            </Form>
                            <div className="row justify-content-center mt-3">
                                <Link className="forgot-password" to={RESET_PASSWORD}>I forgot my password</Link>
                            </div>
                            <div className="row justify-content-center sign-up-section">
                                <span className="sign-up-label">Don’t have an account? &nbsp;</span>
                                <Link className="sign-up-label font-weight-bold" to={CREATE_ACCOUNT}>Sign up</Link>
                            </div>
                        </div>
                        <div className="col-3 col-xs-6 col-sm-6 col-md-4 col-xl-3">
                            <OnBoardingCarousel/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Container>
    )
}


export default Login;
