import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
   GET_QUOTES,
   getQuotesSuccess,
   CREATE_QUOTE,
   createQuoteSuccess
} from "../actions/quotes.actions";
import {disableOverlay, enableOverlay} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getQuotes, postQuote} from "../../services/quotesService";

/**
 * getQuotesEffect
 */
 function* getQuotesEffetcs() {
    try {
        yield put(enableOverlay('Loading quotes...'));
        const quotesList = yield call(getQuotes);
        yield put(getQuotesSuccess(quotesList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * createQuoteEffect
 * @param {*} param0
 */
 function* createQuoteEffect({payload}) {
    try {
        yield put(enableOverlay('Creating quote...'));
        const quote = yield call(postQuote, payload);
        if(quote) {
            console.log('tutto ok!')
            yield put(createQuoteSuccess(quote));
            yield put(disableOverlay());
        }
        else {
            console.log("non è tutto ok")
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchGetQuotes() {
    yield takeEvery(GET_QUOTES, getQuotesEffetcs);
}

export function* watchCreateQuote() {
    yield takeEvery(CREATE_QUOTE, createQuoteEffect);
}

function* quotesSaga() {
    yield all([
        fork(watchGetQuotes),
        fork(watchCreateQuote)
    ]);
}

export default quotesSaga;