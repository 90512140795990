import React , { useRef } from 'react';
import { Dialog } from "primereact/dialog";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { useReactToPrint } from 'react-to-print';
import {API_BASE_URL, COMMERCIAL_INVOICE, API_URL_PDF} from "../../services/APICONSTANTS";


const ModalTitle = ({ shipmentId }) => {
  return (
    <div className="modal-title">
      <div className="p-grid p-justify-between pdf-invoice-title">
        <h3>Print Commercial Invoice - Shipment - #{shipmentId} </h3>
      </div>
    </div>
  );
};


const PdfInvoiceModal = (props) => {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onHide = (name) => {console.log(name)}
  const token = localStorage.getItem('sd-access');

  return (
    <Dialog
      closable={false}
      visible
      className="pdf-invoice"
      header={
        <ModalTitle shipmentId={props.shipmentId} />
      }
      onHide={() => onHide('displayBasic')}
    >

    <div className="commercial-invoice-resize">
      <div className="pdf-container" ref={componentRef}>
        <Document file={{
            url: API_BASE_URL + COMMERCIAL_INVOICE + '/' + props.commercialInvoiceId + API_URL_PDF,
            httpHeaders: { Authorization: 'Bearer ' + token
          }}} >
          <Page pageNumber={1} scale={2}/>
        </Document>
      </div>
    </div>

    <div className="form-footer p-grid p-justify-between">
      <div className="p-col-4">
        <button className="cancel-button" onClick={props.open}>Cancel</button>
      </div>
      <div className="p-col-4">
        <button className="submit-button" onClick={handlePrint}>Print Invoice</button>
      </div>
    </div>
    </Dialog>
  );
};

export default PdfInvoiceModal;
