import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Card, CardBody, Container, Progress} from "reactstrap";
import logo from "../../assets/icons/on-boarding/ShipDistrict logo horiz_blk_red_1797 1.svg";
import OnBoardingCarousel from "../../components/onboarding/OnBoardingCarouselComponent";
import '../../App.scss';
import CheckMailResetPasswordComponent from "../../components/onboarding/CheckMailResetPassworComponent";
import ConfirmResetPasswordComponent from "../../components/onboarding/ConfirmResetPasswordComponent";
import {useHistory, useLocation} from "react-router-dom";
import qs from "query-string";
import {resetPassword as resetPasswordAction, resetPasswordConfirmation} from "../../redux/actions/users.actions";
import {cleanSession} from "../../utils";

export const ResetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [stepper, setStepper] = useState({step: 'Step 1', progress: 30});
    const [token, setToken] = useState('');
    let resetPasswordStepForm;
    let stepLogo;
    let location = useLocation();

    function resetPasswordCheck(data, event) {
        event.preventDefault();
        if (stepper.step === 'Step 1') {
            const email = {email: data.email};
            dispatch(resetPasswordAction(email, history));
        }
        if (stepper.step === 'Step 2') {
            const credentials = {password: data.new_password, token: token};
            dispatch(resetPasswordConfirmation(credentials, history));
        }
    }

    useEffect(() => {
        cleanSession();
    }, []);

    useEffect(() => {
        if (location.search) {
            const resetToken = Object.assign({}, qs.parse(location.search));
            if (resetToken.hasOwnProperty('token')) {
                setToken(resetToken.token);
                setStepper({step: 'Step 2', progress: 60});
            }
        }
    }, [token])


    if (stepper.step === 'Step 1') {
        stepLogo = (
            <div className="row justify-content-center on-boarding-login-section mt-5" style={{paddingTop: "15em"}}>
                <img src={logo} alt="ShipDistrict" height="80" width="180"/>
            </div>);
        resetPasswordStepForm = <CheckMailResetPasswordComponent onSubmit={(data, e) => resetPasswordCheck(data, e)}/>
    }

    if (stepper.step === 'Step 2') {
        stepLogo = (
            <div className="row justify-content-center on-boarding-login-section mt-5" style={{paddingTop: "13em"}}>
                <img src={logo} alt="ShipDistrict" height="80" width="180"/>
            </div>);
        resetPasswordStepForm = <ConfirmResetPasswordComponent onSubmit={(data, e) => resetPasswordCheck(data, e)}/>
    }

    return (
        <Container fluid className="custom-container-padding">
            <Card className="w-100 mt-3 mb-3 page-content">
                <CardBody className="w-100">
                    <div className="row">
                        <div className="col-9 col-xs-6 col-sm-6 col-md-8 col-xl-9">
                            <div className="row justify-content-center on-boarding-login-section mb-2">
                                <span className="text-center">{stepper.step}</span>
                            </div>
                            <div className="row justify-content-center mb-5">
                                <Progress color="danger" className="text-center w-25" style={{height: "5px"}}
                                          value={stepper.progress}/>
                            </div>
                            <div className="row justify-content-center">
                                <h4 className="mt-3">Reset password</h4>
                            </div>
                            {resetPasswordStepForm}
                            {stepLogo}
                        </div>
                        <div className="col-3 col-xs-6 col-sm-6 col-md-4 col-xl-3">
                            <OnBoardingCarousel/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Container>
    )
}

export default ResetPassword;
