import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import '../../styles/datatables.scss'
import '../../styles/tracking.scss'
import logoUps from '../../assets/images/tracking/tracking_ups.png';
import logoUsps from '../../assets/images/tracking/tracking_usps.png';
import logoDhl from '../../assets/images/tracking/tracking_dhl.png';
import logoFedex from '../../assets/images/tracking/tracking_fedex.png';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {
    getShipments,
    resetShipmentState,
    setTrackingSidebarState,
    voidShipmentRequest
} from "../../redux/actions/shipments.actions";
import TrackingSidebar from "../../components/tracking/TrackingSidebar";
import {courierMainServiceIdToString, currencyFormatter} from "../../utils";
import DialogEmailShip from "../../components/shipment/DialogEmailShip";
import PdfInvoiceModal from "../../components/shipment/DialogPrint";
import ViewTracking from "../../components/tracking/ViewTrackingModal";
import DialogVoid from "../../components/shipment/DialogVoid";
import {useHistory} from 'react-router-dom';
import arrowLeft from "../../assets/images/arrow.alt.left.png";
import {Ripple} from "primereact/ripple";
import arrowRight from "../../assets/images/arrow.alt.right.png";
import {MultiSelect} from "primereact/multiselect";
import LabelPrintModal from '../../components/shipment/DialogLabelPrint';
import { debounce } from '@material-ui/core';

export const Tracking = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const rowsPerPage = 10;

    // Redux selectors
    let shipments = useSelector(state => state.shipments.quotesList);
    const totalShipmentsCount = useSelector(state => state.shipments.count);
    const printLabel = useSelector(state => state.shipments.openSideBarTracking.openPrintLabelModal);
    const printInvoice = useSelector(state => state.shipments.openSideBarTracking.openPrintInvoiceModal);
    const trackingNumberModal = useSelector(state => state.shipments.openSideBarTracking.openLinkTrackingModal);
    const emailLabel = useSelector(state => state.shipments.openSideBarTracking.openMailLabelModal);
    const openVoidModal = useSelector(state => state.shipments.openSideBarTracking.openVoidShipmentModal);

    const selectedShipment = useSelector(state => state.shipments.openSideBarTracking.selectedShipment);

    const [tableState, setTableState] = useState({
        first: 0,
        limit: rowsPerPage,
        page: 1,
        lastPageLoaded: 1,
        sortField: null,
        sortOrder: null,
        filter: []
    });

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filterCouriers, setFilterCouriers] = useState([]);
    const [shipmentsData, setShipmentsData] = useState([]);

    const couriersElements = [
        "FEDEX",
        "UPS",
        "USPS",
        "DHL"
    ];

    useEffect(() => {
        reorderItems(tableState.first);
    }, [shipments]);

    useEffect(() => {
        onFilter();
    }, [globalFilter, filterCouriers]);

    const reorderItems = (first) => {
        let tmp = shipments;
        tmp = tmp.slice(first, first + rowsPerPage);
        setShipmentsData([...tmp]);
    }

    const openTrackingSidebar = (shipment) => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: true,
                selectedShipment: shipment,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: false,
                openLinkTrackingModal: false
            }
        }));
    }

    const closeFunctionalModal = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: false,
                selectedShipment: null,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: false,
                openLinkTrackingModal: false
            }
        }));
    }

    const closeMailLabelModal = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                openMailLabelModal: false,
            }
        }));
    }

    const voidShipment = () => {
        dispatch(voidShipmentRequest(selectedShipment.id, history));
        closeFunctionalModal();
    }

    const carrierIconBodyTemplate = (rowData) => {
        const img = getCarrierImage(rowData.courier);
        return (
            <React.Fragment>
                <div className="carrier-icon-container">
                    <img alt={rowData.courier} src={img} className="carrier-icon"/>
                </div>
            </React.Fragment>
        );
    }

    const getCarrierImage = (carrier) => {
        switch (carrier) {
            case 'FEDEX':
                return logoFedex;
            case 'UPS':
                return logoUps;
            case 'USPS':
                return logoUsps;
            case 'DHL':
                return logoDhl;
            default:
                return null;
        }
    }

    const carrierStatusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="shipment-status-chip">
                    <span className="shipment-status-chip-label">{rowData.state}</span>
                </div>
            </React.Fragment>
        );
    }

    const courierServiceTypeTemplate = (rowData) => {
        const service = courierMainServiceIdToString(rowData.courier, rowData.service_type);
        return (
            <React.Fragment>
                <span className="font-weight-bold">{service.name}</span>
            </React.Fragment>
        );
    }

    const courierBodyTemplate = (courier) => {
        return (
            <React.Fragment>
                <span className="image-text">{courier}</span>
            </React.Fragment>
        );
    }

    const formatCurrency = currencyFormatter(2);

    const representativeFilterTemplate = () => {
        return <MultiSelect value={filterCouriers} options={couriersElements}
                            display="chip"
                            itemTemplate={courierBodyTemplate}
                            onChange={(e) => setFilterCouriers(e.value)}
                            placeholder="Select Carrier"
                            style={{minWidth: '200px', height: '40px'}}/>;
    }

    const onFilter = () => {
        dispatch(resetShipmentState());
        let _lazyParams = tableState;
        _lazyParams.first = 0;
        _lazyParams.page = 1;
        _lazyParams.lastPageLoaded = 1;
        let order = _lazyParams.sortOrder === 1 ? 'asc' : 'desc';
        let filterFields = [];
        // By courier
        if (filterCouriers.length > 0) {
            // Filter on couriers is a regex on BE side
            filterFields.push(filterCouriers.join('|'));
        }
        // By tracking number
        if (globalFilter) {
            filterFields.push(globalFilter);
        }
        // BE accept comma separated values for filterFields
        _lazyParams.filter = filterFields.join(',');
        dispatch(getShipments({
            page: _lazyParams.page,
            limit: tableState.limit,
            filter: _lazyParams.filter,
            order: order,
            sort: _lazyParams.sortField
        }));
        setTableState(_lazyParams);
    }

    const onSort = (event) => {
        dispatch(resetShipmentState());
        let _lazyParams = tableState;
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        _lazyParams.first = 0;
        _lazyParams.page = 1;
        _lazyParams.lastPageLoaded = 1;
        let order = event.sortOrder === 1 ? 'asc' : 'desc';
        dispatch(getShipments({
            page: _lazyParams.page,
            limit: tableState.limit,
            filter: _lazyParams.filter,
            order: order,
            sort: _lazyParams.sortField
        }));
        setTableState(_lazyParams);
    }

    const onPage = (event) => {
        let _lazyParams = tableState;
        _lazyParams.first = event.first;
        _lazyParams.page = event.page + 1;
        let order = _lazyParams.sortOrder === 1 ? 'asc' : 'desc';
        if (tableState.lastPageLoaded < _lazyParams.page) {
            dispatch(getShipments({
                page: _lazyParams.page,
                limit: tableState.limit,
                filter: _lazyParams.filter,
                order: order,
                sort: _lazyParams.sortField
            }));
            _lazyParams.lastPageLoaded = _lazyParams.page;
        } else {
            reorderItems(_lazyParams.first);
        }
        setTableState(_lazyParams);
    }

    const paginator = {
        layout: "PrevPageLink CurrentPageReport NextPageLink",
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={options.className}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={arrowLeft} alt="arrowLeft"/>
                    <Ripple/>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={options.className}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={arrowRight} alt="arrowRight"/>
                    <Ripple/>
                </button>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{color: "var(--text-color)", userSelect: "none"}}>
                    <span className="page-number-button">{options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {printLabel && <LabelPrintModal open={closeFunctionalModal} shipmentId={selectedShipment.id} />}
                    {printInvoice && <PdfInvoiceModal open={closeFunctionalModal} shipmentId={selectedShipment.id} commercialInvoiceId={selectedShipment.commercial_invoice[0]} />}
                    <DialogEmailShip displayDialog={emailLabel} closeCallback={closeMailLabelModal} shipmentId={selectedShipment.id} />
                    {trackingNumberModal && <ViewTracking open={closeFunctionalModal} shipmentData={selectedShipment} />}
                    {openVoidModal && <DialogVoid open={closeFunctionalModal} accept={voidShipment}/>}
                    <TrackingSidebar/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <Row className="align-items-center">
                                        <Col xl={3} md={3} xs={3} className="d-flex justify-content-start">
                                            <div className="h2 font-weight-bold mt-2 float-left">Tracking</div>
                                        </Col>
                                        <Col xl={7} md={7} xs={7} className="d-flex justify-content-between">
                                            <span className="p-input-icon-left mr-1 w-100">
                                                <i className="pi pi-search"/>
                                                <InputText type="search"
                                                        className="w-100 filter-tag-input"
                                                        onInput={debounce((e) => setGlobalFilter(e.target.value), 600)}
                                                        placeholder="Search"/>
                                            </span>
                                            <Button icon="search-option"
                                                    className="customer-button float-right"/>
                                        </Col>
                                        <Col xl={2} md={2} xs={2}
                                             className="d-flex justify-content-end total-shipment-count">
                                            {totalShipmentsCount} Orders
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="datatable-crud-demo">
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <DataTable value={shipmentsData}
                                               lazy
                                               selectionMode="single"
                                               dataKey="id" rows={rowsPerPage}
                                               paginator
                                               paginatorPosition={'top'}
                                               paginatorTemplate={paginator}
                                               paginatorLeft={representativeFilterTemplate}
                                               totalRecords={totalShipmentsCount}
                                               onPage={onPage}
                                               onSort={onSort}
                                               sortField={tableState.sortField}
                                               sortOrder={tableState.sortOrder}
                                               first={tableState.first}
                                               selection={selectedShipment}
                                               onSelectionChange={e => openTrackingSidebar(e.value)}
                                    >
                                        <Column field="courier" header="Service" body={carrierIconBodyTemplate} sortable ></Column>
                                        <Column field="service_type" header="Service Type" body={courierServiceTypeTemplate} sortable ></Column>
                                        <Column header="Tracking Number" field="tracking_number" sortable ></Column>
                                        <Column field="cost_with_fee" header="Shipment Total" body={(rowData) => formatCurrency.format(rowData.cost_with_fee)}
                                            headerClassName="text-right" bodyClassName="text-right" sortable ></Column>
                                        <Column field="label_creation" header="Label Creation" sortable ></Column>
                                        <Column field="shipment_date" header="Shipment Date" sortable ></Column>
                                        <Column field="state" body={carrierStatusBodyTemplate} header="Status"></Column>
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Tracking;
