import {all, call, delay, fork, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_USER,
    createUserSuccess,
    RESET_PASSWORD,
    RESET_PASSWORD_CONFIRMATION,
    UPDATE_USER_PROFILE
} from "../actions/users.actions";
import {closeNotificationModal, disableOverlay, enableOverlay, openNotificationModal} from "../actions/ui.actions";
import {initializeSenderAddress, resetReturnAddress} from '../actions/addresses.actions';
import {genericError} from "../actions/error.actions";
import {
    checkUserRegistration,
    postUser,
    resetPasswordByEmail,
    resetPasswordCredentials,
    updateUser
} from "../../services/userService";
import successIcon from "../../assets/icons/shared/success.svg";
import {loginSuccess} from "../actions/auth.actions";


/**
 * checkRegisterUserEffect
 * @param {*} param0
 */
function* updateUserProfileEffect({payload}) {
    try {
        yield put(enableOverlay('Updating profile...'));
        const updateProfile = yield call(updateUser, payload.user);
        if (updateProfile) {
            localStorage.setItem('sd-user', JSON.stringify(updateProfile));
            yield put(disableOverlay());

            const senderAddress = {
                address1: updateProfile.address,
                address2: "",
                address3: "",
                city: updateProfile.city,
                companyName: updateProfile.title,
                country: updateProfile.country,
                email: updateProfile.email,
                fullName: updateProfile.first_name + " " + updateProfile.last_name,
                phone: updateProfile.phone,
                state: updateProfile.state,
                zip: updateProfile.zip,
                residential: updateProfile.residential,
            }
            yield put(initializeSenderAddress(senderAddress));
            yield put(resetReturnAddress());

            yield put(openNotificationModal({
                title: 'Success!',
                message: 'You profile was updated!',
                icon: successIcon
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            if (!payload.auth) {
                payload.history.push('/dashboard');
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * createUserEffect
 * @param {*} param0
 */
function* createUserEffect({payload}) {
    try {
        yield put(enableOverlay('Checking email...'));
        const email = {email: payload.user.email};
        const result = yield call(checkUserRegistration, email);
        if (result) {
            yield put(disableOverlay());
            yield put(enableOverlay('Creating new account...'));
            const created = yield call(postUser, payload.user);
            if (created) {
                yield put(loginSuccess({access: created.tokens.access, refresh: created.tokens.refresh}));
                localStorage.setItem('sd-access', created.tokens.access);
                localStorage.setItem('sd-refresh', created.tokens.refresh);
                localStorage.setItem('sd-user', JSON.stringify(created.user));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Success!',
                    message: 'You account was created!',
                    icon: successIcon
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
                yield put(createUserSuccess(true));
            }
        }

    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * resetPasswordEffect
 * @param {*} param0
 */
function* resetPasswordEffect({payload}) {
    try {
        yield put(enableOverlay('Sending email...'));
        const sending = yield call(resetPasswordByEmail, payload.email);
        if (sending) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'A reset password email was send!',
                icon: successIcon
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            payload.history.push('/on-boarding/login');
        }

    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * resetPasswordConfirmationEffect
 * @param {*} param0
 */
function* resetPasswordConfirmationEffect({payload}) {
    try {
        yield put(enableOverlay('Resetting password...'));
        const reset = yield call(resetPasswordCredentials, payload.newCredentials);
        if (reset) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'Your password was successfully changed!',
                icon: successIcon
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            payload.history.push('/on-boarding/login');
        }

    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * Watchers
 */
export function* watchUpdateUserProfile() {
    yield takeEvery(UPDATE_USER_PROFILE, updateUserProfileEffect);
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUserEffect);
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPasswordEffect);
}

export function* watchResetPasswordConfirmation() {
    yield takeEvery(RESET_PASSWORD_CONFIRMATION, resetPasswordConfirmationEffect);
}

function* usersSaga() {
    yield all([
        fork(watchUpdateUserProfile),
        fork(watchCreateUser),
        fork(watchResetPassword),
        fork(watchResetPasswordConfirmation)
    ]);
}

export default usersSaga;
