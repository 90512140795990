import {createAction} from "@reduxjs/toolkit";

// Accounts action types
export const GET_ACCOUNTS = '[ACCOUNTS] Getting accounts';
export const GET_ACCOUNTS_SUCCESS = '[ACCOUNTS] Getting accounts success!';
export const RESET_ACCOUNTS = '[ACCOUNTS] Accounts reset!';
export const GET_AUTOCOMPLETE_LIST = '[ACCOUNTS] Getting autocomplete list';
export const GET_AUTOCOMPLETE_LIST_SUCCESS = '[ACCOUNTS] Getting autocomplete list success!';
export const SET_AUTOCOMPLETE_SELECTED_ID = '[ACCOUNTS] Set autocomplete selected ID';
export const RESET_AUTOCOMPLETE_LIST = '[ACCOUNTS] Autocomplete list reset!';
export const UPDATE_ACCOUNT = '[ACCOUNTS] Updating account';
export const UPDATE_ACCOUNT_SUCCESS = '[ACCOUNTS] Account updated!';

// Accounts actions
export const getAccounts = createAction(GET_ACCOUNTS);
export const getAccountsSuccess = createAction(GET_ACCOUNTS_SUCCESS);
export const resetAccounts = createAction(RESET_ACCOUNTS);
export const getAutocompleteList = createAction(GET_AUTOCOMPLETE_LIST);
export const getAutocompleteListSuccess = createAction(GET_AUTOCOMPLETE_LIST_SUCCESS);
export const setAutocompleteSelectedId = createAction(SET_AUTOCOMPLETE_SELECTED_ID);
export const resetAutocompleteList = createAction(RESET_AUTOCOMPLETE_LIST);
export const updateAccount = createAction(UPDATE_ACCOUNT);
export const updateAccountSuccess = createAction(UPDATE_ACCOUNT_SUCCESS);
