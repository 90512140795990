import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import {setAdminInvoicesState} from "../../../../redux/actions/admin.invoices.actions"
import "./style.scss"
import { setToast } from "../../../../redux/actions/ui.actions";

const DeleteAccountModal = () => {
  const dispatch = useDispatch()
  const { deleteAccountModal = {} } = useSelector((state) => ({
    deleteAccountModal: state.adminInvoices.deleteAccountModal,
  }));
  const onDelete = () => {
    dispatch(setAdminInvoicesState({
      deleteAccountModal: {
        open: false
      }
    }));
    dispatch(setToast({
      severity: 'error',
      summary: 'Not Implemented!',
      detail: 'Delete account function is not implemented',
      life: 5000
    }));
  }
  const onCancel = () => {
    dispatch(setAdminInvoicesState({
      deleteAccountModal: {
        open: false
      }
    }));
  }
  return (
    <Dialog
      closable={false}
      visible={deleteAccountModal.open}
      onHide={onCancel}
      dismissableMask={true}
      className="delete-account-modal"
    >
      <div className="delete-account-modal-content">
      <h1>Delete Customer</h1>
      <p>Are you sure you want to delete this customer?</p>
      <div className="delete-footer">
      <div className="delete-footer p-grid p-justify-between">
        <div className="p-col-6">
          <button className="cancel-button" onClick={onCancel}>Cancel</button>
        </div>
        <div className="p-col-6">
          <button className="delete-button"  onClick={onDelete}>Delete User</button>
        </div>
      </div>
      </div>
      </div>
    </Dialog>
  );
};

export default DeleteAccountModal;
