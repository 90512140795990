import axios from "axios";
import {API_BASE_URL, CONTACTS_ACTIONS, API_URL_VERIFY_ADDRESS} from "./APICONSTANTS";

export const verifyAddress = (addressToVerify) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_BASE_URL + CONTACTS_ACTIONS + API_URL_VERIFY_ADDRESS, addressToVerify)
                .then((res) => {
                    console.log("Address verified: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Verify address error: ", err);
                    reject("Verify address error.");
                });
        } catch (error) {
            console.error("Verify address error!==", error);
            reject("Verify address error!");
        }
    });
};
