import {FormGroup, UncontrolledTooltip} from 'reactstrap';
import {Control, Errors, Form} from 'react-redux-form';
import '../../App.scss';
import '../../styles/OnBoarding.css';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useSelector} from "react-redux";


export const CheckMailResetPasswordComponent = (props) => {
    const validEmail = (val) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val) && (val?.length > 1) && (val?.length <= 150);

    const formIsValid = useSelector(state => state.forms.resetPasswordMail.email.valid);

    function onSubmit(data, event) {
        event.preventDefault();
        props.onSubmit(data, event);
    }

    return (
        <div className="mt-5">
            <Form className="mt-5 justify-content-center" model="resetPasswordMail"
                  onSubmit={(formModelData, event) => onSubmit(formModelData, event)}>
                <FormGroup className="row justify-content-center">
                    <Control.text model=".email" id="email" name="email"
                                  placeholder="Enter email address"
                                  className="form-control ship-input custom-input-margin"
                                  validators={{
                                      validEmail,
                                  }}
                    />
                    <span>
                        <InfoOutlinedIcon fontSize="small" id="mailTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="mailTooltip">
                        Please enter your registered email address
                    </UncontrolledTooltip>
                    <Errors
                        className="text-center ship-input-error"
                        model=".email"
                        show="touched"
                        messages={{
                            validEmail: 'Invalid email',
                        }}
                    />
                </FormGroup>
                <div className="row justify-content-center">
                    <button className="w-100 ship-primary-custom-button"
                            disabled={!formIsValid}
                            type="submit"
                            style={{maxWidth: "333px"}}>
                        Next
                    </button>
                </div>
            </Form>
        </div>

    )
}


export default CheckMailResetPasswordComponent;
