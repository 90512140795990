import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../components/autocomplete"
import { setAdminInvoicesState } from "../../../redux/actions/admin.invoices.actions";

const InvoicesHeader = (props) => {
  const dispatch = useDispatch();

  const openNewModalInvoice = () =>{
    dispatch(setAdminInvoicesState({
      newEditInvoiceModal: {
        open: true
      }
    }))
  }

  return (
    <div className="invoices-header">
      <div className="d-flex justify-content-between align-items-center">
        <div className="title-container">
          <div className="title">Invoices</div>
          <div className="account-total">
            Accounts Receivable: <span>{props.recivableAmount}</span>
          </div>
        </div>
        <AutoComplete />
        <div className="d-flex button-container">
          <button
            className={"selected"}
            onClick={openNewModalInvoice}
          >
            New Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicesHeader;
