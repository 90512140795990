import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import InvoicesHeader from "./header";
import InvoicesBody from "./body";
import EnableDisableAccount from "./EnableDisableAccount"
import DeleteAccountModal from "./deleteAccountModal"
import EnableAccountModal from "./enableAccountModal"
import DisableAccountModal from "./disableAccountModal"
import PdfInvoiceModal from "./pdfInvoice"
import SendReminderModal from "./sendReminderModal"
import AccountManagement from "./AccountManagement"
import "../../../styles/invoices.scss";
import NewEditInvoiceModal from "../../invoices/createEditInvoiceModal";
import { getAmountsPerStatus } from "../../../services/invoicesService";
import { currencyFormatter } from "../../../utils";

const Invoices = () => {
  const {pdfInvoiceModal = {}, sendReminderModal = {}, newEditInvoiceModal = {}} = useSelector((state)=>({
    pdfInvoiceModal: state.adminInvoices.pdfInvoiceModal,
    sendReminderModal: state.adminInvoices.sendReminderModal,
    newEditInvoiceModal: state.adminInvoices.newEditInvoiceModal
  }))

  const isAdmin = useSelector(state => state.users.loggedUser.is_superuser)

  const [recivableAmount, setRecivableAmount] = useState('');
  const [paidAmount, setPaidAmount] = useState(0);

  const formatCurrency = currencyFormatter(2);

  useEffect(() => {
    getAmountsPerStatus().then((responseData) => {
        let recivableStat = responseData.find((stat) => stat.status === 'CREATED');
        if (recivableStat) {
          setRecivableAmount(formatCurrency.format(recivableStat.amount));
        } else {
          setRecivableAmount(formatCurrency.format(0));
        }

        let paidStat = responseData.find((stat) => stat.status === 'PAID');
        if (paidStat) {
          setPaidAmount(formatCurrency.format(paidStat.amount));
        } else {
          setPaidAmount(formatCurrency.format(0));
        }
    });
  }, [])

  return (

    <div className="page-content invoices-page">
      {isAdmin && <Container fluid>
        <InvoicesHeader recivableAmount={recivableAmount}/>
        <InvoicesBody paidAmount={paidAmount}/>
        <EnableDisableAccount />
        <DeleteAccountModal />
        <EnableAccountModal />
        <DisableAccountModal />
        <AccountManagement />
        {pdfInvoiceModal.open & <PdfInvoiceModal />}
        {sendReminderModal.open && <SendReminderModal />}
        {newEditInvoiceModal.open && <NewEditInvoiceModal />}
      </Container>
        }
    </div>
  );
};

export default Invoices;
