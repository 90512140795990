import axios from "axios";
import { API_BASE_URL, LOGIN } from "./APICONSTANTS";

export const userLogin = ({ username, password }) => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    const credentials = { username: username, password: password };
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.post(API_BASE_URL + LOGIN, credentials)
                .then((res) => {
                    console.log("Auth token: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Auth error: ", err);
                    reject("signin error!");
                });
        } catch (error) {
            console.error("signin error!==", error);
            reject("signin error!");
        }
    });
};

const userSignOut = () => {
    return new Promise((resolve, reject) => {
        try {
            // do API endpoint axios call here and return the promise for logout.
            resolve(true);
        } catch (error) {
            console.error("signout error!==", error);
            reject("signout error!");
        }
    });
};
