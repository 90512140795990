import {createAction} from "@reduxjs/toolkit";

// Auth action types
export const LOGIN = '[Auth] Login in progress...';
export const LOGIN_SUCCESS = '[Auth] Login success!';
export const LOGOUT = '[Auth] Logout in progress...';
export const LOGOUT_SUCCESS = '[Auth] Logout success!';
export const REFRESH_TOKEN_SUCCESS = '[Auth] Refreshing token success!';

//Auth actions
export const login = (data, history) => ({
    type: LOGIN,
    payload: {username: data.username, password: data.password, history: history}
});
export const loginSuccess = (access, refresh) => ({
    type: LOGIN_SUCCESS,
    payload: {access, refresh}
});

export const logout = (history) => ({
    type: LOGOUT,
    payload: {history: history}
});

export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);



