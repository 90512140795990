import { Dialog } from 'primereact/dialog';
import { ListGroup, ListGroupItem } from 'reactstrap';

export const DialogUSPSServicesInformation = (props) => {
    const renderFooter = () => {
        return (
            <div className="row mt-3 justify-content-center">
                <button onClick={props.close} className="ship-primary-custom-button" autoFocus>Close</button>
            </div>
        );
    }

    return (
        <Dialog header="USPS Package Specifications"
        visible={props.open} footer={renderFooter()} style={{ width: '50vw' }} dismissableMask={true}
        closable={true} closeOnEscape={true} draggable={false} onHide={props.close}
        >
            <ListGroup>
                <ListGroupItem>
                    <b>Economy packages</b>: Oversize Surcharge for packages below 1lb, equal to or larger than 400 cubic inches, is $ 1.00 per package.
                </ListGroupItem>
                <ListGroupItem>
                    <b>Economy DIMS</b>: Maximum dimensions per package: Packages exceeding 84 inches in length + girth are not accepted.
                </ListGroupItem>
                <ListGroupItem>
                    <b>Expedited packages</b>: A Large Package Surcharge will be applied to a package when its combined length plus girth (2x(width+height))
                    exceeds 130 inches, or if any one dimension exceeds 72
                </ListGroupItem>
                <ListGroupItem>
                    <b>Expedited DIMS</b>: The maximum allowable size of a shipment limits any one dimension from exceeding 72 inches, or 130 inches
                     in combined length plus girth (2 x (width+height)). Shipments exceeding these limits will be subject to the Large Package Surcharge
                     and the Unauthorized Package Surcharge, as applicable.
                </ListGroupItem>
                <ListGroupItem>
                    <b>International Europe packages</b>: We do not deliver to the following France Zip Codes: 97000-99999  (DOM-TOM) Spain and Italy Remote surcharges are included in rates.
                </ListGroupItem>
                <ListGroupItem>
                    <b>International Europe DIMS</b>: The maximum length (longest side) must not exceed 26 inches and no two sides must exceed 17 inches.
                </ListGroupItem>
                <ListGroupItem>
                    <b>International Canada packages</b>: Oversize Surcharge for packages below 1lb, equal to or larger than 400 cubic inches, is $ 1.00 per package.
                </ListGroupItem>
                <ListGroupItem>
                    <b>International Canada DIMS</b>: The maximum length (longest side) must not exceed 26 inches and no two sides must exceed 17 inches.
                </ListGroupItem>
            </ListGroup>
         </Dialog>
    )
}

export default DialogUSPSServicesInformation;