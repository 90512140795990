export const ROOT = '/';
export const ON_BOARDING = '/on-boarding';
export const LOGIN = ON_BOARDING + '/login';
export const RESET_PASSWORD = ON_BOARDING + '/reset-password';
export const CREATE_ACCOUNT = ON_BOARDING + '/create-account';
export const DASHBOARD = '/dashboard';
export const LOGOUT = '/logout';
export const CUSTOMERS = '/customers';
export const SHIPMENT_CREATION = '/new-shipment';
export const SHIPMENT = '/shipment';
export const PROFILE = '/profile';
export const EDIT_PROFILE = '/profile/edit';
export const INVOICES = '/invoices'
export const ADMINISTRATION = '/administration';
export const LOGIN_ADMIN = ADMINISTRATION + '/login';
export const TRACKING = '/tracking';

// admin routes
export const ADMIN_INVOICES = "/admin/invoices"
