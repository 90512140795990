import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

// users
import avatar from '../../assets/images/users/avatar1.png';

export const ProfileMenu = (props) => {
    const loggedUser = useSelector(state => state.users.loggedUser);
    const [menu, setMenu] = useState(false);
    let username = loggedUser.username ? loggedUser.username :  loggedUser.first_name;

    function toggle() {
        setMenu(!menu);
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => toggle()} className="d-inline-block user-dropdown">
                    <span className="d-none d-xl-inline-block mr-2 pt-2 text-transform font-weight-bold text-capitalize">{username}</span>
                    <img className="rounded-circle header-profile-user mr-3" src={avatar} alt="Header Avatar"/>
                <DropdownToggle tag="button" className="btn header-item waves-effect"
                                id="page-header-user-dropdown">
                    <span className="text-transform p-2 font-weight-bold header-option-span">
                        {'Settings'}
                    </span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem href="/profile">
                        <i className="ri-user-line align-middle mr-1"></i> {'Profile'}
                    </DropdownItem>
{/*                    <DropdownItem className="d-block" href="#">
                        <i className="ri-settings-2-line align-middle mr-1"></i>
                        {'Settings'}
                    </DropdownItem>*/}
                    {!loggedUser.is_superuser &&
                    <React.Fragment>
                        <DropdownItem divider/>
                        <DropdownItem  href="/administration/login">
                            <i className="ri-user-settings-line align-middle mr-1"></i> {'Admin'}
                        </DropdownItem>
                    </React.Fragment>
                    }
                    <DropdownItem divider/>
                    <DropdownItem className="text-danger" href="/logout"><i
                        className="ri-shut-down-line align-middle mr-1 text-danger"></i> {'Logout'}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

export default ProfileMenu;
