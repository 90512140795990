import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getCustomers, resetCustomerState} from "../../redux/actions/customers.actions";
import {Dialog} from "primereact/dialog";
import {Row} from "reactstrap";
import '../../styles/AddressBook.scss';
import '../../styles/datatables.scss';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import arrowLeft from "../../assets/images/arrow.alt.left.png";
import {Ripple} from "primereact/ripple";
import arrowRight from "../../assets/images/arrow.alt.right.png";

export const AddressBookDialog = (props) => {
    const dispatch = useDispatch();
    const customers = useSelector(state => state.customers.customersList);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const customerCount = useSelector(state => state.customers.count);

    const rowsPerPage = 10;
    const [customersData, setCustomersData] = useState([]);

    const [tableState, setTableState] = useState({
        first: 0,
        limit: rowsPerPage,
        page: 1,
        lastPageLoaded: 1,
        sortField: null,
        sortOrder: null,
        filter: []
    });

    useEffect(() => {
        dispatch(resetCustomerState());
        dispatch(getCustomers({page: tableState.page, limit: tableState.limit}));
    }, []);

    useEffect(() => {
        reorderItems(tableState.first);
    }, [customers]);

    const reorderItems = (first) => {
        let tmp = customers;
        tmp = tmp.slice(first, first + rowsPerPage);
        setCustomersData([...tmp]);
    }


    const onHide = () => {
        // nothing for now
    }

    const confirm = () => {
        props.confirm(selectedCustomer);
        close();
    }

    const close = () => {
        props.onClose();
    }

    const modalTitle = () => {
        return (
            <span className="functional-modal-title">Select Customer</span>
        );
    }

    const onPage = (event) => {
        let _lazyParams = tableState;
        _lazyParams.first = event.first;
        _lazyParams.page = event.page + 1;
        let order = _lazyParams.order === 1 ? 'asc' : 'desc';
        if (tableState.lastPageLoaded < _lazyParams.page) {
            dispatch(getCustomers({
                page: _lazyParams.page,
                limit: tableState.limit,
                filter: _lazyParams.filter,
                order: order,
                sort: _lazyParams.sortField
            }));
            _lazyParams.lastPageLoaded = _lazyParams.page;
        } else {
            reorderItems(_lazyParams.first);
        }
        setTableState(_lazyParams);
    }


    const paginator = {
        layout: "PrevPageLink CurrentPageReport NextPageLink",
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={options.className}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={arrowLeft} alt="arrowLeft"/>
                    <Ripple/>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={options.className}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={arrowRight} alt="arrowRight"/>
                    <Ripple/>
                </button>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    className="p-mx-3"
                    style={{color: "var(--text-color)", userSelect: "none"}}
                >
          <span className="page-number-button">{options.currentPage}</span>
          <span className="page-of-button"> of </span>
          <span className="page-number-button">{options.totalPages}</span>
        </span>
            );
        },
    };

    const paginatorLeft = () => {
        return (
            <React.Fragment>
                <span className="total-address-count">Customers {customerCount}</span>
            </React.Fragment>);
    }


    const renderFooter = () => {
        return (
            <div className="row mt-3 justify-content-center">
                <button type="button" onClick={() => close()} style={{width: '125px'}}
                        className="ship-secondary-custom-button">Cancel
                </button>
                <button type="button" onClick={() => confirm()} style={{width: '125px'}}
                        className="ship-primary-custom-button ml-2" autoFocus disabled={!selectedCustomer}>Confirm
                </button>
            </div>
        );
    }


    return (
        <Dialog header={modalTitle}
                onHide={() => onHide()}
                style={{width: '75vw'}} breakpoints={{'960px': '75vw'}}
                visible={props.open} footer={renderFooter}
                draggable={false} closable={false}>
            <Row className="datatable-crud-demo w-100">
                <DataTable value={customersData}
                           lazy
                           paginator
                           paginatorPosition={'top'}
                           paginatorTemplate={paginator}
                           paginatorLeft={paginatorLeft}
                           selection={selectedCustomer}
                           first={tableState.first}
                           totalRecords={customerCount}
                           selectionMode="single"
                           onPage={onPage}
                           onSelectionChange={(e) => setSelectedCustomer(e.value)}
                           dataKey="id" rows={rowsPerPage}>
                    <Column field="fullName" header="Customer"></Column>
                    <Column field="companyName" header="Company"></Column>
                    <Column header="Address" field="address1"></Column>
                    <Column field="city" header="City"></Column>
                    <Column field="state" header="State"></Column>
                    <Column field="zip" header="Zip"></Column>
                    <Column field="country" header="Country"></Column>
                    <Column field="phone" header="Phone"></Column>
                </DataTable>
            </Row>
        </Dialog>
    );
}

export default AddressBookDialog;
