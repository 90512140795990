import {createAction} from "@reduxjs/toolkit";

// user action types
export const GET_USER_SUCCESS = '[Auth] user info recover with success!';
export const UPDATE_USER_SUCCESS = '[Users] user updated!';
export const PATCH_USER_SUCCESS = '[Users] user patched!';
export const CREATE_USER = '[Users] user creating...!';
export const CREATE_USER_SUCCESS = '[Users] user create with success!';
export const UPDATE_USER_PROFILE = '[Users] updating user profile...';
export const UPDATE_USER_PROFILE_SUCCESS = '[Users] updating user profile success!';
export const RESET_PASSWORD = '[user] sending email to reset password';
export const RESET_PASSWORD_SUCCESS = '[user] Email to reset password send!';
export const RESET_PASSWORD_CONFIRMATION = '[user] resetting password...';
export const RESET_PASSWORD_CONFIRMATION_SUCCESS = '[user] resetting password success!'

// user actions
export const getUserSuccess = createAction(GET_USER_SUCCESS);
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const patchUserSuccess = createAction(PATCH_USER_SUCCESS);
export const createUser = (user, history) => ({
    type: CREATE_USER,
    payload: {user: user, history: history}
});
export const createUserSuccess = createAction(CREATE_USER_SUCCESS);
export const updateUserProfile = (user, history, auth) => ({
    type: UPDATE_USER_PROFILE,
    payload: {user: user, history: history, auth: auth}
});
export const updateUserProfileSuccess = (result) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: result
});

export const resetPassword = (email, history) => ({
    type: RESET_PASSWORD,
    payload: {email: email, history: history}
});

export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);

export const resetPasswordConfirmation = (newCredentials, history) => ({
    type: RESET_PASSWORD_CONFIRMATION,
    payload: {newCredentials: newCredentials, history: history}
})

export const resetPasswordConfirmationSuccess = createAction(RESET_PASSWORD_CONFIRMATION_SUCCESS);
