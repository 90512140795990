import React, {useContext, useEffect, useMemo, useState} from 'react';
import eyeicon from '../../theme/assets/images/eye-ship.svg';
import {useDispatch, useSelector} from "react-redux";
import {QuotesShip} from "../../assets/shipment/quoteshipping";
import {createQuote} from '../../redux/actions/quotes.actions';
import {Col, Container, Row} from 'reactstrap';
import {AppContext} from '../shipment/Provider';
import logofedex from '../../assets/icons/shipment/logofedex-m.svg';
import logousps from '../../assets/icons/shipment/logousps-m.svg';
import logoups from '../../assets/icons/shipment/logoups-m.svg';
import logodhl from '../../assets/icons/shipment/logodhl-m.svg';
import cartfilled from '../../theme/assets/images/luggage-cart-line.svg';
import {createShipment} from "../../redux/actions/shipments.actions";
import { domesticDIMShipmentFactor } from '../../utils/numbers-helper';


const totalRates = QuotesShip.map((cost) =>
    cost.BaseRates + cost.Fuel + cost.Residencial + cost.Saturday + cost.Saturday + cost.Saturday);

function ListQuote() {
    const [isRequestValid, setIsRequestValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [stato, setStato] = useContext(AppContext);
    const dispatch = useDispatch();
    const quoteRequest = useSelector(state => state.quoteRequest);
    const quotation = useSelector(state => state.quote.quotation);

    useEffect(() => {
        checkRequest();
    }, [quoteRequest]);

    useEffect(() => {
        if (isRequestValid) {
            dispatch(createQuote(quoteRequest));
        }
    }, [isRequestValid])


    const checkRequest = () => {
        var errorMessages = []
        if (!quoteRequest.sender) {
            errorMessages.push('- Sender Address')
        }
        if (!quoteRequest.receiver) {
            errorMessages.push('- Receiver Address')
        }
        if (!quoteRequest.service_type) {
            errorMessages.push('- Service Type')
        }
        if (!quoteRequest.signature) {
            errorMessages.push("- Signature")
        }

        if (!quoteRequest.pieces || quoteRequest.pieces.length == 0) {
            errorMessages.push('- Package Dimensions and Weight')
        }

        if (errorMessages.length > 0) {
            setIsRequestValid(false);
        } else {
            setIsRequestValid(true);
        }

        setStato({...stato, isRequestValid: isRequestValid});
        setErrorMessages(errorMessages);
        return errorMessages;
    }


    const quotationList = quotation ? quotation.surcharges.map((surcharge, i) =>
        <tr key={i}>
            <td className="h5 item-sx gray">{surcharge.description}</td>
            <td className="h5 item-dx gray pr-5">${surcharge.amount ? surcharge.amount : "--"}</td>
        </tr>
    ) : [];


    const lista =
        <table className="table mb-0">
            <tbody>
            <tr>
                <td className="h5 item-sx gray">Base rate(s)</td>
                <td className="h5 item-dx gray pr-5">${quotation.baseAmount ? quotation.baseAmount : "--"}</td>
            </tr>
            {quotationList}
            <tr className="p-5">
                <th scope="col"></th>
                <th scope="col"></th>
            </tr>
            <tr>
                <td className="h4  sx ship-total black">Total</td>
                <td className="h4  ship-total dx red pr-5">${quotation.total_amount_with_fee ? quotation.total_amount_with_fee : "--"}</td>
            </tr>

            </tbody>
        </table>

    const errorMessagesElements = errorMessages.map((error, i) =>
        <li className="h5 item-sx gray" key={i}>{error}</li>
    )

    const errorList = <div>
        <ul className="quote-error-list"><h5 className="font-weight-bold ">Missing the following
            items:</h5>{errorMessagesElements}</ul>
        <hr></hr>
        <table className="table mb-0">
            <tbody>
            <tr>
                <td className="h4  sx ship-total black">Total</td>
                <td className="h4  ship-total dx red pr-5">$ 0.00</td>
            </tr>

            </tbody>
        </table>
    </div>
    return (
        <div className="ship-card">{isRequestValid ? lista : errorList}</div>
    );
}

const convertWeightToLbs = (measure) => {
    return (measure / 2.205).toFixed(3)
}

export const CardQuote = (props) => {
    const [stato, setStato] = useContext(AppContext);
    let totalBoxes = useMemo(() => {
        return stato.boxes
    }, [stato.boxes]);
    let {isPackselected, isRequestValid} = stato;
    const box = 1;

    const dispatch = useDispatch();
    const quote = useSelector(state => state.quote);
    const shipmentRequest = useSelector(state => state.shipmentRequest);
    const commercialInvoiceRequest = useSelector(state => state.commercialInvoiceStore.commercialInvoiceRequest);

    const [dims, setDims] = useState(null);

    useEffect(() => {
        let calcDims = 0;
        if ('packages' in shipmentRequest && shipmentRequest.packages.length > 0) {
            let height = parseFloat(shipmentRequest.packages[0].height) || 0;
            let width = parseFloat(shipmentRequest.packages[0].width) || 0;
            let length = parseFloat(shipmentRequest.packages[0].length) || 0;
            calcDims = Math.round(1000 * height * width * length / domesticDIMShipmentFactor) / 1000;
        }
        setDims(calcDims);
    }, [shipmentRequest.packages[0]])

    const joinAddresses = (contactInfo) => {
        let addressItems = [];
        for (let item of ['address_1', 'address_2', 'address_3']) {
            if (item in contactInfo && contactInfo[item]) {
                addressItems.push(contactInfo[item]);
            }
        }
        return addressItems.join(', ');
    }

    const createNewShipment = () => {
        // Concatenate addresses fields into address_1 field
        // TODO Each adderss line should have their own field
        shipmentRequest.sender.address_1 = joinAddresses(shipmentRequest.sender);
        shipmentRequest.receiver.address_1 = joinAddresses(shipmentRequest.receiver);
        delete(shipmentRequest.sender.address_2);
        delete(shipmentRequest.sender.address_3);
        delete(shipmentRequest.receiver.address_2);
        delete(shipmentRequest.receiver.address_3);

        dispatch(createShipment({...shipmentRequest, associated_quote: quote.id, commercial_invoice: commercialInvoiceRequest}))
    }

    return (
        <>
            <Container className="mb-3 mt-4 packaging-detail-container">
                {isPackselected &&
                <Row className="mt-4">
                    <Col sm="4">
                        {(() => {
                            switch (stato.selectedCarrier) {
                                case 'UPS':
                                    return <img src={logoups} className="card-img " style={{height: '80px'}}
                                                alt="ups"/>;
                                case 'USPS':
                                    return <img src={logousps} className="card-img " style={{height: '80px'}}
                                                alt="usps"/>;
                                case 'DHL':
                                    return <img src={logodhl} className="card-img " style={{height: '80px'}}
                                                alt="dhl"/>;
                                default:
                                    return <img src={logofedex} className="card-img " style={{height: '80px'}}
                                                alt="fedex"/>;
                            }
                        })()}
                    </Col>
                    <Col sm="2"></Col>
                    <Col sm="6" className="shipment-package-card">
                       <Row>
                            <Col md="1" lg="1" className="mt-3">
                                <img src={cartfilled} className="ship-icon" alt="cart-filled"/>
                            </Col>
                            <Col>
                                <ul className="ship-list">
                                    <li className="ship-item">Total QTY:</li>
                                    <li className="ship-item">Total Weight:</li>
                                    <li className="ship-item">Ounces:</li>
                                    <li className="ship-item">DIMS:</li>
                                </ul>
                            </Col>
                            <Col className="float-end text-end">
                                <ul className="ship-list result black font-weight-bold">
                                    <li className="ship-item">{totalBoxes > 1 ? totalBoxes : box}</li>
                                    <li className="ship-item">{stato.weight ? ((stato.length === 'In' || stato.length === undefined) ? stato.weight : convertWeightToLbs(stato.weight)) : 0} {(stato.length === 'In' || stato.length === undefined) ? "lbs" : "Kg"}</li>
                                    <li className="ship-item">{stato.ounces ? stato.ounces : 0} oz</li>
                                    <li className="ship-item">{dims}</li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
                <div className="row">
                    <div className="col-12 mt-4 mb-3">
                        <div className="h5 font-weight-bold" key="{props}">{props.titlecard} <img
                            className="ship-eye-icon" src={eyeicon} alt="eye"/></div>
                    </div>
                </div>
                <div className="row">
                    <ListQuote tipo={props.tipoCard}/>
                </div>
                {isRequestValid &&
                <div className="row"><p className="h6 ship-info ml-4">Quoted Weight/Type(s) 5lb/Dimensional</p></div>}
                <div className="row col-sm">
                    <button type="button" onClick={createNewShipment} className="ship-btn-red w-100"
                            style={{width: '80%', height: '50px'}}>Create | Service Type | Label
                    </button>
                </div>
            </Container>
        </>
    )
}

export default CardQuote;
