import {userLogin} from '../../services/authService';
import {LOGIN, loginSuccess, LOGOUT, logoutSuccess} from '../actions/auth.actions';
import {genericError} from "../actions/error.actions";
import {getLoggedUser} from "../../services/userService";
import {getUserSuccess} from "../actions/users.actions";
import {closeNotificationModal, disableOverlay, enableOverlay, openNotificationModal} from "../actions/ui.actions";
import {initializeSenderAddress, resetReturnAddress} from '../actions/addresses.actions';
import successIcon from "../../assets/icons/shared/success.svg"
import {all, call, delay, fork, put, takeEvery} from "redux-saga/effects";
import {cleanSession} from "../../utils/index"

/**
 * Login
 * @param {*} param0
 */
function* logInEffect({payload}) {
    try {
        let userResult = null;
        yield put(enableOverlay('Logging in...'));
        const loginResult = yield call(userLogin, payload);
        yield put(loginSuccess(loginResult));
        if (loginResult) {
            localStorage.setItem('sd-access', loginResult.access);
            localStorage.setItem('sd-refresh', loginResult.refresh);
            userResult = yield call(getLoggedUser, loginResult.access);
        }
        if (userResult) {
            localStorage.setItem('sd-user', JSON.stringify(userResult))
            yield put(getUserSuccess(userResult));

            const senderAddress = {
                address1: userResult.address,
                address2: "",
                address3: "",
                city: userResult.city,
                companyName: userResult.title,
                country: userResult.country,
                email: userResult.email,
                fullName: userResult.first_name + " " + userResult.last_name,
                phone: userResult.phone,
                state: userResult.state,
                zip: userResult.zip,
                residential: userResult.residential,
            }
            yield put(initializeSenderAddress(senderAddress));
            yield put(resetReturnAddress());
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'You have been logged in!',
                icon: successIcon
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            payload.history.push('/dashboard')
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * Logout
 * @param {*} param0
 */
function* logoutEffect({payload}) {
    try {
        /*
                localStorage.getItem('sd-access');
                localStorage.getItem('sd-refresh');
                localStorage.removeItem("sd-user");

                const response = yield call(logoutUser);
        */
        cleanSession();
        yield put(logoutSuccess());
        payload.history.push('/on-boarding');
    } catch (error) {
        yield put(genericError(error));
    }
}

/**
 * Watchers
 */
export function* watchLogin() {
    yield takeEvery(LOGIN, logInEffect);
}

export function* watchLogout() {
    yield takeEvery(LOGOUT, logoutEffect);
}

function* authSaga() {
    yield all([
        fork(watchLogin),
        fork(watchLogout)
    ]);
}

export default authSaga;
