import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch } from 'react-redux';
import { sendShipmentLabelEmail } from '../../redux/actions/shipments.actions';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";

export const DialogEmailShip = ({ displayDialog, closeCallback, shipmentId }) => {
    const dispatch = useDispatch();

    const textDialog = {
        title: 'Email Label',
        buttonLeft: 'Cancel',
        buttonRight: 'Send Email'
    }

    const sendShipmentLabel = (values) => {
        dispatch(sendShipmentLabelEmail({
            recipients: [values.recipients],
            subject: values.subject,
            body: values.body,
            shipmentId
        }));
        closeCallback();
    }

    return (
        <Dialog header={textDialog.title} visible={displayDialog} style={{ width: '533px', height: '577px' }} onHide={closeCallback}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    recipients: '',
                    subject: '',
                    body: ''
                }}
                validationSchema={Yup.object({
                    recipients: Yup.string()
                        .email()
                        .required(),
                    subject: Yup.string()
                        .min(3)
                        .max(250)
                        .required(),
                    body: Yup.string()
                        .min(3)
                        .max(1000)
                        .required(),
                })}

                onSubmit={sendShipmentLabel}
            >
                {() => (
                    <Form>
                        <div className="p-fluid" id="ship-email">
                            <div className="p-field text-left">
                                <label htmlFor="recipients" className="ship-title-popup">TO</label>
                                <Field as={InputText} name="recipients" id="recipients" type="text" />
                                <ErrorMessage name="recipients" />
                            </div>
                            <div className="p-field text-left">
                                <label htmlFor="subject" className="ship-title-popup">SUBJECT</label>
                                <Field as={InputText} name="subject" id="subject" type="text" />
                                <ErrorMessage name="subject" />
                            </div>
                            <div className="p-field p-col-12 text-left">
                                <label htmlFor="body" className="ship-title-popup">BODY</label>
                                <Field as={InputTextarea} name="body" id="body" rows="4" />
                                <ErrorMessage name="body" />
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="button" onClick={closeCallback} style={{ width: '200px' }} className="ship-btn-gray " >{textDialog.buttonLeft}</button>
                            <button type="submit" style={{ width: '200px' }} className="ship-btn-red ml-2 " autoFocus >{textDialog.buttonRight}</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default DialogEmailShip;