import {
    GET_SHIPMENTS_SUCCESS,
    CREATE_SHIPMENT_SUCCESS,
    UPDATE_SHIPMENT_REQUEST,
    RESET_SHIPMENT_REQUEST_STATE,
    RESET_SHIPMENT_RESPONSE_STATE,
    UPDATE_SHIPMENT_REQUEST_COURIER,
    SET_TRACKING_SIDEBAR_STATE, GET_SHIPMENT_SUCCESS, RESET_SHIPMENT_STATE,
    GET_SHIPMENT_LABEL_SUCCESS, RESET_SHIPMENT_LABEL_STATE,
} from "../actions/shipments.actions";

//da sistemare
const initialShipmentsState = {quotesList: [], next: null, previous: null, count: 0, openSideBarTracking: {
        open: false,
        selectedShipment: {},
        openPrintLabelModal: false,
        openPrintInvoiceModal: false,
        openMailLabelModal: false,
        openVoidShipmentModal: false,
        openLinkTrackingModal: false
    }};

export const ShipmentsReducer = (state = initialShipmentsState, action) => {
    switch (action.type) {
        case GET_SHIPMENTS_SUCCESS:
            return {...state, quotesList: [...state.quotesList, ...action.payload.results],
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count};
        case SET_TRACKING_SIDEBAR_STATE:
            state.openSideBarTracking = {
                ...state.openSideBarTracking,
                ...action.payload.openSideBarTracking,
            };
            return state;
        case RESET_SHIPMENT_STATE:
            return initialShipmentsState;
        default:
            return state;
    }
}

const initialShipmentResponseState = {
    shipment: {
        id: null,
        sender: {
            country: "",
            zip: ""
        },
        receiver: {
            country: "",
            city: "",
            zip: "",
            email: "",
            phone: "",
        },
        pieces: [
            {
                weight: "",
                length: "",
                width: "",
                height: "",
                insurance_amount: "",
                declaredValue: 0,
                quote: 0
            }
        ],
        quotation: {
            id: null,
            surcharges: [
                {
                    description: "",
                    amount: ""
                },
                {
                    description: "",
                    amount: ""
                }
            ],
            currency: "",
            customsCurrency: "",
            totalAmount: ".",
            baseAmount: "",
            zone: null,
            quotedWeight: "",
            quotedWeightType: ""
        },
        carrierCode: "",
        serviceCode: "",
        packageTypeCode: "",
        residential: false,
        signatureOptionCode: "",
        uspsExpressAmDelivery: false,
        saturdayDelivery: false,
        contentDescription: "",
        weightUnit: "",
        dimUnit: "",
        currency: "",
        customsCurrency: ""
    },
    isShipmentSent: false

}

export const ShipmentReducer = (state = initialShipmentResponseState, action) => {
    switch(action.type) {
        case CREATE_SHIPMENT_SUCCESS:
            return {...state, shipment:action.payload, isShipmentSent: true}
        case GET_SHIPMENT_SUCCESS:
            return {...state, shipment: action.payload}
        case RESET_SHIPMENT_REQUEST_STATE:
            return initialShipmentResponseState
        default:
            return state;
    }
}

const  initialShipmentRequestState = {packages: []}

export const ShipmentRequestReducer = (state = initialShipmentRequestState, action) => {
    switch(action.type) {
        case UPDATE_SHIPMENT_REQUEST:
            return {...state, ...action.payload}
        case RESET_SHIPMENT_RESPONSE_STATE:
            return initialShipmentRequestState
        case UPDATE_SHIPMENT_REQUEST_COURIER:
            return {...state, courier: action.payload}
        default:
            return state;
    }
}

const initialShipmentLabelState = {
    returnType: "",
    fileContent: "",
    labelUrl: ""
}

export const ShipmentLabelReducer = (state = initialShipmentLabelState, action) => {
    switch(action.type) {
        case GET_SHIPMENT_LABEL_SUCCESS:
            return {...state, ...action.payload}
        case RESET_SHIPMENT_LABEL_STATE:
            return initialShipmentLabelState
        default:
            return state;
    }
}
