import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
   GET_SIGNATURES,
   getSignaturesSuccess
} from "../actions/signatures.actions";
import {disableOverlay, enableOverlay} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getSignatures} from "../../services/signaturesService";

/**
 * getSignaturesEffetcs
 */
 function* getSignaturesEffetcs() {
    try {
        yield put(enableOverlay('Loading signatures...'));
        const signaturesList = yield call(getSignatures);
        yield put(getSignaturesSuccess(signaturesList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchGetSignatures() {
    yield takeEvery(GET_SIGNATURES, getSignaturesEffetcs);
}

function* signaturesSaga() {
    yield all([
        fork(watchGetSignatures)
    ]);
}

export default signaturesSaga;