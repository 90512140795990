import {
    GET_ACCOUNTS_SUCCESS, GET_AUTOCOMPLETE_LIST_SUCCESS, RESET_ACCOUNTS, RESET_AUTOCOMPLETE_LIST, SET_AUTOCOMPLETE_SELECTED_ID
} from "../actions/accounts.actions";

const initialAccountsState = {
    accountsList: [],
    next: null,
    previous: null,
    count: 0,
    autocompleteSelectedId: null,
    autocompleteList: [],
};

const convertApiFormatToUi = (row) => {
    // Inject field fullName
    row['fullName'] = row['first_name'] + ' ' + row['last_name'];
    return row;
};

export const AccountsReducer = (state = initialAccountsState, action) => {
    switch (action.type) {
        case GET_ACCOUNTS_SUCCESS:
            for (let index in action.payload.results) {
                action.payload.results[index] = convertApiFormatToUi(action.payload.results[index]);
            }
            return {
                ...state,
                accountsList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count
            };
        case RESET_ACCOUNTS:
            return initialAccountsState;
        case GET_AUTOCOMPLETE_LIST_SUCCESS:
            let autocompleteList = [];
            for (let index in action.payload.results) {
                autocompleteList.push(convertApiFormatToUi(action.payload.results[index]));
            }

            state.autocompleteList = autocompleteList;
            return state;
        case SET_AUTOCOMPLETE_SELECTED_ID:
            state.autocompleteSelectedId = action.payload;
            return state;
        case RESET_AUTOCOMPLETE_LIST:
            state.autocompleteList = [];
            return state;
        default:
            return state;
    }
}
