import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from './Provider';
import {useDispatch, useSelector} from "react-redux";
import * as Yup from 'yup';
import { countries } from '../../assets/shipment/countries';
import { Col, Container, Row } from 'reactstrap';
import { FieldArray, Form, Formik, Field } from 'formik';
import logofedex from '../../assets/icons/shipment/logofedex-m.svg';
import logousps from '../../assets/icons/shipment/logousps-m.svg';
import logoups from '../../assets/icons/shipment/logoups-m.svg';
import logodhl from '../../assets/icons/shipment/logodhl-m.svg';
import cartfilled from '../../theme/assets/images/luggage-cart-line.svg';
import { roundToTwoDecimal, convertlengthToInches, convertWeightToLbs, convertlengthToCm, convertWeightToKg } from '../../utils/numbers-helper';
import SelectField from './SelectField';
import {updateCommercialInvoiceRequest} from "../../redux/actions/commercial.invoices.action";

function SelectMisurate() {
  const [stato, setStato] = useContext(AppContext);
  return (
    <select id="inputUnits" onChange={e => setStato({ ...stato, length: e.target.value })} name="units" className="form-control">
      <option value="In" >in / oz / lbs</option>
      <option value="Cm" >cm / kg / g</option>
    </select>
  );
}

const customSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '0px',
    backgroundColor: 'white',
    color: state.isSelected ? '#1F2D3D' : '#D4DAE9',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    borderRadius: '10px',
    fontSize: "14px !important",
    fontWeight: "500 !important",
    '&:hover': {
      backgroundColor: '#F6F7FB',
      fontWeight: 'bold',
      color: '#1F2D3D'
    }
  }),
  container: (provided, state) => ({
    ...provided,
    borderColor: '#D4DAE9',
    borderWidth: '1px',
    borderRadius: '10px'
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    paddingLeft: '10px',
  }),
  indicatorsContainer: () => ({
    backgroundColor: 'white',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: state.menuIsOpen ? 'inline' : 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: state.menuIsOpen ? 'none' : 'inline'
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isSelected ? '2px solid #D4DAE9' : '1px solid #D4DAE9',
    boxShadow: 'none',
    padding: '0px',
    outline: 'none',
    borderRadius: '10px',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: '#D4DAE9',
      borderWidth: '1px',
      boxShadow: 'none',
    }
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0 : 1;
    const transition = 'opacity 300ms';
    const fontSize = "14px !important";
    const fontWeight = "600 !important";
    return { ...provided, opacity, transition, fontSize, fontWeight };
  },
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "9px"
    },
    "::-webkit-scrollbar-track": {
      background: "white"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#D4DAE9",
      borderRadius: '100px'
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#D4DAE9"
    }
  })
}

export const CommercialInvoice = (props) => {
  const dispatch = useDispatch();
  const [stato, setStato] = useContext(AppContext);
  const shipmentRequest = useSelector(state => state.shipmentRequest);
  const receiverAddress = useSelector(state => state.receiverAddress);
  const senderAddress = useSelector(state => state.senderAddress);
  const commercialInvoiceRequest = useSelector(state => state.commercialInvoiceStore.commercialInvoiceRequest);
  const packages = useSelector(state => state.commercialInvoiceStore.packages);
  const invoiceTypeOption = [{ label: "Commercial", value: "commercial" }];
  const contentTypeOption = [{ label: "Goods", value: "goods" }, { label: "Documents", value: "value" }];
  const countryOriginOption = countries.map(country => ({ label: country.name, value: country.code }));
  const importerRecordOption = [{ label: "Receiver", value: "receiver" }, { label: "Sender", value: "sender" }];

  const [initialValues, setInitialValues] = useState({
    packages: [],
    inputInvoiceType: null,
    inputTotalWeight: 0,
  });
  const [totalBoxes, setTotalBoxes] = useState(0);

  const termsOfTradeOption = [
    {label: "DAP - Delivered At Place", value: "dap"},
    {label: "CIP - Costs Insurance Paid", value: "cip"},
    {label: "CPT - Carriage Paid to", value: "cpt"},
    {label: "DAT - Delivered at Terminal", value: "dat"},
    {label: "DDP - Delivered Duty Paid", value: "ddp"},
    {label: "EXW - Ex Works", value: "exw"},
    {label: "FCA - Free Carrier", value: "fca" }
  ];

  useEffect(() => {
    setTotalBoxes(commercialInvoiceRequest.packages ? commercialInvoiceRequest.packages.length : 0);
  }, [commercialInvoiceRequest]);

  useEffect(() => {
    setInitialValues({
      packages: packages ? packages : [],
      inputTotalWeight: packages ? (packages.reduce((previousValue, pack) => previousValue + pack.weight, 0)) : 0
    });
  }, [packages]);

  // TODO determinate mandatory values commercial invoice
  const validationSchema= Yup.object().shape({
    packages: Yup.array()
      .of(Yup.object().shape({
        description: Yup.string().required('Required'),
        unitValue: Yup.string().required('Required').min(1,'Required'),
        packQuantity: Yup.string().required('Required').min(1,'Required')
      })),
    termsOfTrade: Yup.string().required('Required').oneOf(termsOfTradeOption.map((e) => e.value)),
  })

  const joinAddresses = (contactInfo) => {
    let addressItems = [];
    for (let item of ['address1', 'address2', 'address3']) {
        if (item in contactInfo && contactInfo[item]) {
            addressItems.push(contactInfo[item]);
        }
    }
    return addressItems.join(', ');
  }

  const onSubmit = (values) => {
    let termsOfTradeDescription = termsOfTradeOption.find(e => e.value === values.termsOfTrade).label;
    commercialInvoiceRequest.date = new Date().toISOString();
    commercialInvoiceRequest.destination_country = receiverAddress.country;
    commercialInvoiceRequest.shipper = senderAddress.fullName;
    commercialInvoiceRequest.consignee = receiverAddress.fullName;
    commercialInvoiceRequest.contact_shipper = null;
    commercialInvoiceRequest.contact_consignee = null;
    commercialInvoiceRequest.shipper_address = joinAddresses(senderAddress);
    commercialInvoiceRequest.shipper_email = senderAddress.email;
    commercialInvoiceRequest.shipper_phone_number = senderAddress.phone;
    commercialInvoiceRequest.receiver_address	= joinAddresses(receiverAddress);
    commercialInvoiceRequest.receiver_email = receiverAddress.email;
    commercialInvoiceRequest.receiver_phone_number = receiverAddress.phone;
    commercialInvoiceRequest.carrier = shipmentRequest.courier;
    commercialInvoiceRequest.shipper_ref = values.shipperRef;
    commercialInvoiceRequest.itn = values.itn;
    commercialInvoiceRequest.terms_of_trade = termsOfTradeDescription;
    commercialInvoiceRequest.number_of_pieces = values.inputTotalQuantity;
    commercialInvoiceRequest.total_weight = values.inputTotalWeight;
    commercialInvoiceRequest.waybill_number	= values.wayBill;
    commercialInvoiceRequest.receiver_tax_id = values.receiverTaxID;
    commercialInvoiceRequest.packages_marks = values.packageMarks;
    commercialInvoiceRequest.shipment_contents = values.inputOverallDescription;
    commercialInvoiceRequest.boxes = values.packages.length.toString();
    commercialInvoiceRequest.hts = values.hts;
    commercialInvoiceRequest.eccn = values.eccn;
    commercialInvoiceRequest.origin_country = senderAddress.country;
    commercialInvoiceRequest.quantity = values.packages.reduce((sum, pack) => sum + pack.packQuantity).packQuantity;
    commercialInvoiceRequest.unit = values.packages.length > 0 ? values.packages[0].unit : null;
    commercialInvoiceRequest.unit_value = values.packages.reduce((sum, pack) => sum + pack.unitValue).unitValue;
    commercialInvoiceRequest.total = values.inputTotalValue;
    commercialInvoiceRequest.charge_description = values.inputChargeDescription;
    commercialInvoiceRequest.charges_amount = values.inputChargeAmount;
    commercialInvoiceRequest.invoice_amount	= values.inputCustoms;
    commercialInvoiceRequest.shipment = null;
    dispatch(updateCommercialInvoiceRequest(commercialInvoiceRequest));
    props.hideCommercialInvoice(false);
  };

  const hideInvoice = () => {
    props.hideCommercialInvoice(false)
  }

  return (
    <Container className="packaging-detail-container mt-2">
      <Row>
        <div className="col-sm">
          <Row className="mt-4">
            <div className="col-sm-4">
              {(() => {
                switch (stato.selectedCarrier) {
                  case 'UPS': return <img src={logoups} className="card-img float-left" style={{ height: '80px' }} alt="ups" />;
                  case 'USPS': return <img src={logousps} className="card-img float-left" style={{ height: '80px' }} alt="usps" />;
                  case 'DHL': return <img src={logodhl} className="card-img float-left" style={{ height: '80px' }} alt="dhl" />;
                  default: return <img src={logofedex} className="card-img float-left" style={{ height: '80px' }} alt="fedex" />;
                }
              })()}
            </div>
            <div className="col-sm"></div>
            <div className="shipment-package-card col-sm-4 mr-2">
              <Row>
                <Col xs={1} md="1" className="mt-3">
                  <img src={cartfilled} className="ship-icon" alt="cart-filled" />
                </Col>
                <Col>
                  <ul className="ship-list">
                    <li className="ship-item">Total QTY:</li>
                    <li className="ship-item">Total Weight: </li>
                    <li className="ship-item">Ounces:</li>
                  </ul>
                </Col>
                <Col className="float-end text-end">
                  <ul className="ship-list result black font-weight-bold">
                    <li className="ship-item">{totalBoxes > 1 ? totalBoxes : 1}</li>
                    <li className="ship-item">{stato.weight ? ((stato.length === 'In' || stato.length === undefined) ? stato.weight : convertWeightToLbs(stato.weight)) : 0} {(stato.length === 'In' || stato.length === undefined) ? "lbs" : "Kg"}</li>
                    <li className="ship-item">{stato.ounces ? stato.ounces : 0} oz</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <div className="col-sm mt-3">
              <h4 className="font-weight-bold">Commercial Invoice</h4>
            </div>
          </Row>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange
            }) => {
              return (
                <Form className="mt-3">
                  <Row className="mb-2">
                    <Col sm={6}>
                      <label htmlFor="inputInvoiceType" className="gray">Invoice Type</label>
                      <Field component={SelectField} name="inputInvoiceType" id="inputInvoiceType" styles={customSelectStyle} isSearchable={false} options={invoiceTypeOption} placeholder='Select Invoice Type' onChange={handleChange} defaultValue={invoiceTypeOption[0]}/>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={6}>
                      <label htmlFor="contentType" className="gray">Content Type</label>
                      <Field component={SelectField} name="contentType" id="contentType" styles={customSelectStyle} isSearchable={false} options={contentTypeOption} placeholder='Select Content Type' onChange={handleChange} />
                    </Col>
                    <Col sm={6}>
                      <label htmlFor="eccn" className="gray"> ECCN # </label>
                      <input className="form-control" name="eccn" id="eccn" placeholder={"ECCN #"} onChange={handleChange} />
                    </Col>
                  </Row>
                  <FieldArray name="packages"
                    render={arrayHelpers => (
                      <Container className="ml-0 pl-0 mr-0 pr-0 mb-3">
                        {values.packages.map((pack, index) => (
                          <React.Fragment key={index}>
                            <Row className="mb-2">
                              <Col xs={12}>
                                <label htmlFor={`packages[${index}].description`} className="gray">Package Description</label><span><i className="bi bi-info-circle commercial-invoice mt-1 ml-1" /></span>
                                <Field as="textarea" className="form-control" id={`packages[${index}].description`} name={`packages[${index}].description`} value={pack.description} placeholder="Description" rows="1" onChange={handleChange} />
                                <Row>
                                  <Col>
                                   {errors &&
                                    errors.packages &&
                                    errors.packages[index] &&
                                    errors.packages[index].description &&
                                    <div className="ship-input-error">{errors.packages[index].description}</div>}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col xs={6} md={6}>
                                <label htmlFor="hts" className="gray">HTS#/ B#</label>
                                <input className="form-control" name="hts" id="hts" placeholder={"Enter Number"} onChange={handleChange} />
                              </Col>
                              <Col xs={6} md={6}>
                                <label htmlFor="countryOrigin" className="gray">Country of Origin</label>
                                <Field component={SelectField} name="countryOrigin" id="countryOrigin" styles={customSelectStyle} isSearchable={true} options={countryOriginOption} placeholder='Select Country' onChange={handleChange} />
                              </Col>
                            </Row>
                            <Row xs={12} className="mb-2">
                              <Col xs={4}>
                                <Row>
                                  <Col md={9} xs={9}>
                                    <label className="gray">Units</label>
                                  </Col>
                                  <Col md={2} xs={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <SelectMisurate />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={4}>
                                <Row>
                                  <Col md={9} xs={9}>
                                    <label htmlFor="unitValue" className="gray"> Units Value</label>
                                  </Col>
                                  <Col md={2} xs={2} className="mt-1" >
                                    <i className="bi bi-info-circle commercial-invoice" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <input className="form-control" name={`packages[${index}].unitValue`} id={`packages[${index}].unitValue`} placeholder={"0.00"} onChange={handleChange} />
                                    <Row>
                                  <Col>
                                    {errors &&
                                    errors.packages &&
                                    errors.packages[index] &&
                                    errors.packages[index].unitValue &&  <div className="ship-input-error">{errors.packages[index].unitValue}</div>}
                                  </Col>
                                </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={4}>
                                <Row>
                                  <Col md={9} xs={9}>
                                    <label htmlFor="packQuantity" className="gray">Quantity</label>
                                  </Col>
                                  <Col md={2} xs={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <input className="form-control" name={`packages[${index}].packQuantity`} id={`packages[${index}].packQuantity`} placeholder={"0"} onChange={handleChange} />
                                    <Row>
                                  <Col>
                                    {errors &&
                                    errors.packages &&
                                    errors.packages[index] &&
                                    errors.packages[index].packQuantity &&  <div className="ship-input-error">{errors.packages[index].packQuantity}</div>}
                                  </Col>
                                </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row xs={12} className="mb-2">
                              <Col xs={4} md={4}>
                                <Row>
                                  <Col md={9} xs={9}>
                                    <label htmlFor="inputLength" className="gray"> Length ({stato.length === "Cm" ? "Cm" : "In"})</label>
                                  </Col>
                                  <Col md={2} xs={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <input id={`packages[${index}].length`} name={`packages[${index}].length`} value={(stato.length === 'In') ? roundToTwoDecimal(pack.length).toString() : convertlengthToCm(pack.length).toString()} className="form-control" placeholder={stato.length === "Cm" ? "Cm" : "In"} onChange={handleChange} />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={4} md={4}>
                                <Row>
                                  <Col xs={9} md={9}>
                                    <label htmlFor="inputWidth" className="gray"> Width ({stato.length === "Cm" ? "Cm" : "In"})</label>
                                  </Col>
                                  <Col xs={2} md={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice"></i>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <input id={`packages[${index}].width`} name={`packages[${index}].width`} value={(stato.length === 'In') ? roundToTwoDecimal(pack.width).toString() : convertlengthToCm(pack.width).toString()} className="form-control" placeholder={stato.length === "Cm" ? "Cm" : "In"} onChange={handleChange}/>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={4} md={4}>
                                <Row>
                                  <Col md={9} xs={9}>
                                    <label htmlFor="inputHeight" className="gray">
                                      Height ({stato.length === "Cm" ? "Cm" : "Inches"})
                                    </label>
                                  </Col>
                                  <Col md={2} xs={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice"></i>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <input id={`packages[${index}].height`} name={`packages[${index}].height`} value={(stato.length === 'In') ? roundToTwoDecimal(pack.height).toString() : convertlengthToCm(pack.height).toString()} className="form-control" placeholder={stato.length === "Cm" ? "Cm" : "In"} onChange={handleChange}/>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col md={4}>
                                <Row>
                                  <Col md={9} xs={9} >
                                    <label htmlFor="inputWeight" className="gray"> Weight ({(stato.length === 'Cm') ? "kg" : "Lbs"})</label>
                                  </Col>
                                  <Col md={2} xs={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <input id={`packages[${index}].weight`} name={`packages[${index}].weight`} value={(stato.length === 'In') ? roundToTwoDecimal(pack.weight).toString() : convertWeightToKg(pack.weight).toString()} className="form-control " placeholder={(stato.length === 'Cm') ? "0 kg" : "0 lbs"} onChange={handleChange} />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={4}>
                                <Row>
                                  <Col xs={9} md={9}>
                                    <label htmlFor="inputOunces" className="gray">Ounces</label>
                                  </Col>
                                  <Col xs={2} md={2} className="mt-1">
                                    <i className="bi bi-info-circle commercial-invoice" />
                                  </Col>
                                </Row>
                                <input id={`packages[${index}].ounces`} name={`packages[${index}].ounces`} value={pack.ounces} className="form-control " placeholder={"0 oz"} onChange={handleChange}/>
                              </Col>
                            </Row>
                            {/*
                            <Row>
                              <Col xs="6" sm="6" className="mb-5">
                                <Row>
                                  <Col xs={1}  className="p-0">
                                    <a href="#" className="fa fa-more" onClick={() => console.log("")}></a>
                                  </Col>
                                  <Col xs={1}  className="p-0 ml-1 ">
                                    <a href="#" onClick={() => console.log("")} className="fa fa-less mb-2"></a>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            */}
                          </React.Fragment>
                        ))}
                      </Container>
                    )} />
                  <hr className="w-100"/>
                  <Row className="mb-2 mt-2">
                    <Col md={4} xs={4}>
                      <Row>
                        <Col xs={9} md={9}>
                          <label htmlFor="inputTotalQuantity" className="gray"> Total Quantity </label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input id="inputTotalQuantity" name="inputTotalQuantity" type="text" className="form-control" placeholder="0" onChange={handleChange} />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} xs={4}>
                      <Row>
                        <Col xs={9} md={9}>
                          <label htmlFor="inputTotalWeight" className="gray"> Total Weight </label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Field id="inputTotalWeight" name="inputTotalWeight" type="text" className="form-control" placeholder="0.00" readOnly={true} />
                    </Col>
                    <Col md={4} xs={4}>
                      <Row>
                        <Col xs={9} md={9}>
                          <label htmlFor="inputTotalValue" className="gray"> Total Value </label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input id="inputTotalValue" name="inputTotalValue" type="text" className="form-control" placeholder="$0.00" onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={10} md={10}>
                          <label htmlFor="inputChargeDescription" className="gray">Charge Description</label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input type="text" className="form-control" placeholder="Enter Description" id="inputChargeDescription" name="inputChargeDescription" onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} md={4}>
                      <Row>
                        <Col xs={9} md={9}>
                          <label htmlFor="inputChargeAmount" className="gray">Charge Amount</label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input type="text" className="form-control" placeholder="$0.00" id="inputChargeAmount" onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4} md={4}>
                      <Row>
                        <Col xs={9} md={9}>
                          <label htmlFor="inputInsurance" className="gray">Insurance Amount</label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input type="text" className="form-control" placeholder="$0.00" id="inputInsurance" onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4} md={4}>
                      <Row>
                        <Col xs={9} md={9}>
                          <label htmlFor="inputCustoms" className="gray">Customs Amount</label>
                        </Col>
                        <Col xs={2} md={2} className="mt-1">
                          <i className="bi bi-info-circle commercial-invoice" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input type="text" className="form-control" placeholder="$0.00" id="inputCustoms" onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="importerRecord" className="gray">Importer of Record</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field component={SelectField} name="importerRecord" id="importerRecord" styles={customSelectStyle} isSearchable={false} options={importerRecordOption} placeholder='Select Importer Record' onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="termsOfTrade" className="gray">Terms of Trade</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field component={SelectField} name="termsOfTrade" id="termsOfTrade" styles={customSelectStyle} isSearchable={true} options={termsOfTradeOption} placeholder='Select Terms of Trade' onChange={handleChange}/>
                          <Row>
                            <Col>
                              {errors.termsOfTrade &&
                                <div className="ship-input-error">{errors.termsOfTrade}</div>
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="receiverTaxID" className="gray"> Receiver Tax ID / VAT </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input className="form-control " name="receiverTaxID" id="receiverTaxID" placeholder={"Enter Number"} onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="packageMarks " className="gray"> Package Marks </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input name="packageMarks" id="packageMarks" className="form-control " placeholder={"Optional Entry"} onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="senderVat" className="gray"> Sender Tax ID / VAT </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input name="senderVat" id="senderVat" className="form-control" placeholder={"Enter Number"} onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="senderIssuerCountry" className="gray">Sender Tax ID / VAT issuer Country</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field component={SelectField} name="senderIssuerCountry" id="senderIssuerCountry" styles={customSelectStyle} isSearchable={false} options={countryOriginOption} placeholder='Select Country' onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="itn" className="gray"> ITN # </label>
                        </Col>
                      </Row>
                      <Col>
                        <Row>
                          <input className="form-control" name="itn" id="itn" placeholder={"Enter Number"} onChange={handleChange}/>
                        </Row>
                      </Col>
                    </Col>
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="wayBill" className="gray"> Waybill # </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input name="wayBill" id="wayBill" className="form-control " placeholder={"Optional Entry"} onChange={handleChange}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6} md={6}>
                      <Row>
                        <Col>
                          <label htmlFor="shipperRef" className="gray"> Shipper Ref </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field className="form-control " name="shipperRef" id="shipperRef" placeholder={"Enter Number"} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="d-inline-flex">
                          <label htmlFor="inputOverallDescription" className="gray">Overall Package Description</label><span><i className="commercial-invoice bi bi-info-circle mt-1 ml-1" /></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <textarea
                            className="form-control"
                            id="inputOverallDescription"
                            placeholder="Description"
                            rows="3" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <div className="form-check SaveCheck">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="SaveCheck"
                        onChange={handleChange}
                      />
                      <label className="form-check-label black" htmlFor="gridCheck">
                        Save package details for later use?
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <button className="ship-secondary-custom-button w-100" type="button"
                              onClick={hideInvoice}>Cancel
                      </button>
                    </div>
                    <div className="col-sm">
                      <button className="ship-primary-custom-button w-100" type="submit">Save Changes</button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Row>
    </Container>
  );

}
export default CommercialInvoice;
