import React, {useRef, useState,} from 'react';
import logofedex from '../../assets/icons/shipment/logofedex-m.svg';
import logoDhl from '../../assets/icons/shipment/logodhl-m.svg';
import logoUps from '../../assets/icons/shipment/logoups-m.svg';
import logoUsps from '../../assets/icons/shipment/logousps-m.svg';
import departure from '../../assets/images/locatioIcon.svg';
import barIcon from '../../assets/images/bar-icon.svg';
import destation from '../../assets/images/destination-ico.svg';
import {Menu} from 'primereact/menu';
import {Button} from "primereact/button";
import {courierMainServiceIdToString, currencyFormatter} from "../../utils";
import {setTrackingSidebarState} from "../../redux/actions/shipments.actions";
import {useDispatch} from "react-redux";


export const CardDetails = (props) => {
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(false);
    const menu = useRef(null);

    const formatCurrency = currencyFormatter(2);

    const openPrintInvoice = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                    open: false,
                    openPrintLabelModal: false,
                    openPrintInvoiceModal: true,
                    openMailLabelModal: false,
                    openVoidShipmentModal: false,
                    openLinkTrackingModal: false
                }
        }));
    }

    const openPrintLabel = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                    open: false,
                    openPrintLabelModal: true,
                    openPrintInvoiceModal: false,
                    openMailLabelModal: false,
                    openVoidShipmentModal: false,
                    openLinkTrackingModal: false
                }
        }));
    }

    const openTrackingNumberModal = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: false,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: false,
                openLinkTrackingModal: true
            }
        }));
    }

    const openEmailLabel = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: false,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: true,
                openVoidShipmentModal: false,
                openLinkTrackingModal: false
            }
        }));
    }

    const openVoidTrackingModal = () => {
        dispatch(setTrackingSidebarState({
            openSideBarTracking: {
                open: false,
                selectedShipment: props.selectedShipment,
                openPrintLabelModal: false,
                openPrintInvoiceModal: false,
                openMailLabelModal: false,
                openVoidShipmentModal: true,
                openLinkTrackingModal: false
            }
        }));
    }


    const items = [
        {
            label: '',
            items: [
                {
                    label: 'Print Label',
                    command: () => {
                        openPrintLabel();
                    }
                },
                {
                    label: 'Print Commercial Invoice',
                    command: () => {
                        openPrintInvoice();
                    }
                },
                {
                    label: 'Email Label',
                    command: () => {
                        openEmailLabel();
                    }

                },
                {
                    label: 'Create Return Label',
                    command: () => {
                    }
                },
                {
                    label: ' Edit & Reship',
                    command: () => {
                    }
                },
                {
                    label: 'Schedule Pickup ',
                    command: () => {
                    }
                }
            ]
        }
    ];

    if(props.selectedShipment.state !== 'VOIDED' && props.selectedShipment.state !== 'VOIDED_IN_JUSDA') {
        items.push({
            label: 'Void Shipment',
            command: () => {
                openVoidTrackingModal();
            }
        })
    }

    const getCarrierLogo = (carrier) => {
        switch (carrier) {
            case 'FEDEX':
                return logofedex;
            case 'UPS':
                return logoUps;
            case 'USPS':
                return logoUsps;
            case 'DHL':
                return logoDhl;
            default:
                return null;
        }
    }

    return (
        <React.Fragment>
            <div style={{width: "545px", padding: "5px"}}>
                <div className="row mt-3 mb-3">
                    <div className="col-6">
                        <img
                            alt="fedex"
                            style={{width: "175px", height: "50px"}}
                            src={getCarrierLogo(props.selectedShipment.courier)}
                        />
                    </div>
                    <div className="col-6 pl-4 text-right">
                        <button
                            type="button"
                            onClick={props.showEditor}
                            className="btn btn-danger text-truncate"
                            style={{maxWidth: "190px"}}
                        >
                            {props.selectedShipment.state}
                        </button>
                        <Menu model={items} popup ref={menu} id="tracking_actions_menu"/>
                        <div className="test" style={{display: 'contents'}}>
                            <Button label="" icon="pi pi-ellipsis-h" className="p-button-rounded ml-2 p-button-secondary"
                                    onClick={(event) => menu.current.toggle(event)}
                                    aria-controls="tracking_actions_menu"
                                    aria-haspopup/>
                        </div>
                    </div>
                </div>

                <div className="row  borderC">
                    <table className="table">
                        <tbody>
                        <tr className=" ti-trk">
                            <td className=" col-9 ">Service Package Type</td>
                            <td className=" col-3  ">Shipment Total</td>
                        </tr>
                        <tr className=" data-trk">
                            <td className="col-9 h5">{courierMainServiceIdToString(props.selectedShipment.courier, props.selectedShipment.service_type).name}</td>
                            <td className="col-3  red fnt30">
                                {props.selectedShipment.cost_with_fee ? formatCurrency.format(props.selectedShipment.cost_with_fee) : '-'}
                            </td>
                        </tr>
                        <tr className=" ti-trk">
                            <td className=" col-9 ">Tracking Number</td>
                            <td className=" col-3  ">Label Creation:</td>
                        </tr>
                        <tr className="data-trk">
                            <td className="col-9 h5">
                                <button className="btn btn-link btn-lg p-0" onClick={openTrackingNumberModal}>
                                    {props.selectedShipment.tracking_number ? props.selectedShipment.tracking_number : '-'}
                                </button>
                            </td>
                            <td className="col-3 h5">
                                {props.selectedShipment.label_creation ? props.selectedShipment.label_creation : '-'}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table className="col-12">
                        <tbody>
                        <tr className=" ti-trk">
                            <td className=" col-4 ">Item count</td>
                            <td className=" col-4  ">Weight</td>
                            <td className=" col-5  ">Shipment Date:</td>
                        </tr>

                        <tr className=" data-trk">
                            <td className="col-3 ">
                                {props.selectedShipment.package ?
                                    props.selectedShipment.packages.length > 0
                                        ? props.selectedShipment.packages.length : '0' : '0'}
                            </td>
                            <td className=" col-5  ">
                                {props.selectedShipment.package
                                    ? props.selectedShipment.packages[0].weight
                                        ? props.selectedShipment.packages[0].weight + ' Ibs' : '-' : '-'}
                            </td>
                            <td className=" col-4  ">
                                {props.selectedShipment.shipment_date ? props.selectedShipment.shipment_date : '-'}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table className="col-12 mt-5">
                        <tbody>
                        <tr className=" ti-trk">
                            <td className=" col-4 ">Sender</td>
                            <td className=" col-4  text-right pr-3">Recipient</td>
                        </tr>

                        <tr className=" data-trk">
                            <td className="col-3 ">
                                {props.selectedShipment.sender ? props.selectedShipment.sender.name +
                                    ' - ' + props.selectedShipment.sender.address_1 + ', '
                                    + props.selectedShipment.sender.state + ', '
                                    + props.selectedShipment.sender.zip_code : '-'}
                            </td>
                            <td className=" col-5  ">
                                {props.selectedShipment.receiver ? props.selectedShipment.receiver.name +
                                    ' - ' + props.selectedShipment.receiver.address_1 + ', '
                                    + props.selectedShipment.receiver.state + ', '
                                    + props.selectedShipment.receiver.zip_code : '-'}
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <div className=" data-trk col-12 mt-5">
                        <img className="float-left pl-3" src={departure} alt="departure"/>
                        <div className="col-11 float-right">
                            Departure: {props.selectedShipment.sender.address_1 ? props.selectedShipment.sender.address_1 : '-'}
                        </div>
                    </div>
                    <div className="col-12">
                        <img src={barIcon} style={{padding: "10px"}} alt="bar"/>
                    </div>
                    <div className=" ti-trk col-12">
                        <img className="float-left pl-3" src={destation} alt="destation"/>
                        <div className="col-11 float-right">
                            Destination: {props.selectedShipment.receiver.address_1 ? props.selectedShipment.receiver.address_1 : '-'}
                        </div>
                    </div>


                    <div className="data-trk ml-2 pt-5 col-12">

                        <p className="col-9 float-left h5">Tracking Details</p>
                        <button className="btn btn-outline-secondary gray">Add Note</button>
                    </div>

                    <div className="row ml-2 p-3">
                        <div className="pl-2 col-12  data-trk">
                            No Data
                        </div>
                        {/*                        <div className="pl-2 col-12  data-trk">
                            Departure: 12234 4th Street Los Angeles, CA, 90273
                        </div>
                        <img src={timer} style={{padding: "10px"}} alt="bar"></img>
                        <div className=" col-11  ti-trk pt-2">
                            March 16th, 2020 - 3:00pm EST
                        </div>*/}
                    </div>
                    {/*                    <div className="row ml-2 p-3">
                        <div className="ml-2 col-12  ti-trk">
                            Shipping Labels have been printed, as requested...
                        </div>
                        <img src={timer} style={{padding: "10px"}} alt="bar"></img>
                        <div className=" col-11  ti-trk pt-2">
                            March 6th, 2020 - 4:00pm EST
                        </div>
                    </div>*/}
                </div>
            </div>
        </React.Fragment>
    );
}

export default CardDetails;
