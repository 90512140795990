import React from 'react';
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import ShipmentAnalyticsComponent from "../../components/dashboard/ShipmentAnalyticsComponent";
import DeliveredAnalyticsComponent from "../../components/dashboard/DeliveredAnalytics";
import SalesAnalytics from "../../components/dashboard/CarrierAnalytics";

export const Dashboard = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader style={{background: "white"}}>
                                    <div className="h2 font-weight-bold mt-2">Dashboard</div>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <ShipmentAnalyticsComponent/>
                        </Col>

                        <Col xl={6}>
                            <DeliveredAnalyticsComponent/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <SalesAnalytics/>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;
