import axios from "axios";
import { buildPaginationUrlParams } from "../utils";
import {API_BASE_URL, CONTACTS_ACTIONS, CONTACTS_BULK_DELETE, GET_CUSTOMERS} from "./APICONSTANTS";

export const getCustomers = (page, limit, filter, order, sortBy) => {
    let url = API_BASE_URL + GET_CUSTOMERS + '?page=' + page.toString();
    url += limit ? '&pageSize=' + limit.toString() : '';
    url += filter && filter.length > 0 ? '&search=' + filter : '';
    if(order && sortBy) {
        if(order === 'asc') {
            url += '&ordering=+' + sortBy;
        } else  {
            url += '&ordering=-' + sortBy;
        }
    }
    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Customers loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get customers error: ", err);
                    reject("Get customers error!");
                });
        } catch (error) {
            console.error("Get customers error!==", error);
            reject("Get customers error!");
        }
    });
};

export const searchCustomers = (filter) => {

    let url = API_BASE_URL + GET_CUSTOMERS + buildPaginationUrlParams({filter});

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Found customers loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Search customers error: ", err);
                    reject("Search customers error!");
                });
        } catch (error) {
            console.error("Search customers error!==", error);
            reject("Search customers error!");
        }
    });
};

export const postCustomer = (customer) => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('sd-access');
            // do db call or API endpoint axios call here and return the promise.
            axios.post(API_BASE_URL + GET_CUSTOMERS, customer, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((res) => {
                    console.log("Customer added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post customers error: ", err);
                    reject("Post customers error!");
                });
        } catch (error) {
            console.error("Post customer error!==", error);
            reject("Post customer error!");
        }
    });
};

// TODO: Missing put endpoint
export const patchCustomer = (customer) => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.patch(API_BASE_URL + GET_CUSTOMERS + '/' + customer.id, customer)
                .then((res) => {
                    console.log("Customer updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Patch customers error: ", err);
                    reject("patch customers error!");
                });
        } catch (error) {
            console.error("Patch customer error!==", error);
            reject("Patch customer error!");
        }
    });
};

export const deleteCustomer = (customer) => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.delete(API_BASE_URL + GET_CUSTOMERS + '/' + customer.id, {
                data: customer
            })
                .then((res) => {
                    console.log("Customer deleted: ", res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log("Customer delete error: ", err);
                    reject("Customer delete error!");
                });
        } catch (error) {
            console.error("Customer delete error!==", error);
            reject("Customer delete error!");
        }
    });
};

export const deleteCustomers = (customersIds) => {
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.delete(API_BASE_URL + CONTACTS_ACTIONS + CONTACTS_BULK_DELETE, {
                data: customersIds
            })
                .then((res) => {
                    console.log("Customers deleted: ", res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log("Customers delete error: ", err);
                    reject("Customers delete error!");
                });
        } catch (error) {
            console.error("Customers delete error!==", error);
            reject("Customers delete error!");
        }
    });
};
