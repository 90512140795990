import React, { useEffect, useState } from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { getBasicStat } from '../../services/shipmentsService';
import { STAT_CODE_AMOUNT_BY_CARRIER } from '../../services/APICONSTANTS';
import { convertBasicStatFormat, currencyFormatter } from '../../utils';

const numberFormatter =  currencyFormatter(0);

const SalesAnalytics = (props) => {
    const defaultPieData = {
        series: [3700, 1600, 1500, 5000],
        options: {
            labels: ["UPS", "FedEx", "DHL", "USPS"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: true
            },
            legend: {
                show: true,
            },
            tooltip: {
                y: {
                    formatter: (val) => numberFormatter.format(val),
                }
            },
            colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1']
        }
    };

    const defaultStatData = {
        series: [{
            name: 'UPS',
            type: 'line',
            data: [23000, 11000, 22000, 27000, 13000, 22000, 37000, 21000, 44000, 22000, 30000, 20000]
        }, {
            name: 'FedEx',
            type: 'line',
            data: [44000, 55000, 41000, 67000, 22000, 43000, 21000, 41000, 56000, 27000, 43000, 11000]
        }, {
            name: 'DHL',
            type: 'line',
            data: [30000, 25000, 36000, 30000, 45000, 35000, 64000, 52000, 59000, 36000, 39000, 11000]
        },
            {
                name: 'USPS',
                type: 'line',
                data: [20000, 29000, 16000, 36000, 25000, 31000, 24000, 42000, 39000, 16000, 33000, 10000]
            }
        ],
        options: {
            chart: {
                stacked: false,
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [4, 4, 4, 4],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1'],

            fill: {
                opacity: [1, 1, 1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                }
            },
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            markers: {
                size: 0
            },
            legend: {
                offsetY: 11,
            },
            xaxis: {
                type: 'month',
            },
            yaxis: {
                title: {
                    text: '$',
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val) => numberFormatter.format(val),
                }
            },
            grid: {
                borderColor: '#f1f1f1'
            }
        }
    };

    const defaultHorizontalData = {
        series: [
            {data: [3700, 1600, 1500, 5000]}],
        options: {
            chart: {
                toolbar: {
                    show: false,
                    columnWidth: '5%',
                    endingShape: 'rounded'
                }
            },
            plotOptions: {
                bar: {
                    barHeight: '50%',
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    },
                }
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: (val) => numberFormatter.format(val),
            },
            legend: {
                show: true,
            },
            grid: {
                borderColor: '#f1f1f1',
            },
            tooltip: {
                y: {
                    title: {
                        formatter: function () {
                            return ' $'
                        },
                    }
                }
            },
            colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1'],
            xaxis: {
                categories: ["UPS", "FedEx", "DHL", "USPS"],
            }
        }
    }

    const [pieData, setPieData] = useState({
        series: [],
        options: {}
    });

    const [statData, setStatData] = useState({
        series: [],
        options: {}
    });

    const [horizontalData, setHorizontalData] = useState({
        series: [],
        options: {}
    });

    useEffect(() => {
        getBasicStat(STAT_CODE_AMOUNT_BY_CARRIER).then((responseData) => {
            let pieData = defaultPieData;
            let statData = defaultStatData;
            let horizontalData = defaultHorizontalData;

            const convertedStat = convertBasicStatFormat(responseData);
            if (convertedStat.categories.length > 0) {
                statData.options.labels = convertedStat.categories;
                statData.series = convertedStat.series;

                let pieLabels = [];
                let pieSeries = [];
                for (let serie of statData.series) {
                    pieLabels.push(serie.name);
                    pieSeries.push(serie.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0));
                }
                pieData.series = pieSeries;
                pieData.options.labels = pieLabels;

                horizontalData.series[0].data = pieSeries;
                horizontalData.options.xaxis.categories = pieLabels;
            }

            setPieData(pieData);
            setStatData(statData);
            setHorizontalData(horizontalData);
        });
    }, [])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/*                    <div className="float-right">
                        <select className="custom-select custom-select-sm">
                            <option defaultValue>Apr</option>
                            <option value="1">Mar</option>
                            <option value="2">Feb</option>
                            <option value="3">Jan</option>
                        </select>
                    </div>*/}
                    <div className="card-title mb-4 font-weight-bold h4">Carrier Usage & Payments</div>
                    <Row>
                        <Col xl={4}>
                            <ReactApexChart options={pieData.options} series={pieData.series} type="donut" height="270"/>
                        </Col>
                        {/*<Col xl={1}>
                            <div className="text-center">
                                <p className="mb-1 text-truncate">
                                    <i className="mdi mdi-circle font-size-10 mr-1 font-weight-bold"
                                       style={{color: "#CF3339"}}></i>
                                    {data.options.labels[0]}</p>
                                <h6>{data.series[0]} $</h6>
                            </div>
                            <div className="text-center mt-3">
                                <p className="mb-1 text-truncate">
                                    <i className="mdi mdi-circle font-size-10 mr-1 font-weight-bold"
                                       style={{color: "#D3D3ED"}}></i>
                                    {data.options.labels[1]}
                                </p>
                                <h6>{data.series[1]} $</h6>
                            </div>
                            <div className="text-center mt-3">
                                <p className="mb-1 text-truncate"><i
                                    className="mdi mdi-circle font-size-10 mr-1 font-weight-bold"
                                    style={{color: "#E5E9F2"}}></i>
                                    {data.options.labels[2]}
                                </p>
                                <h6>{data.series[2]} $</h6>
                            </div>
                            <div className="text-center mt-3">
                                <p className="mb-1 text-truncate"><i
                                    className="mdi mdi-circle font-size-10 mr-1 font-weight-bold"
                                    style={{color: "#7D83A1"}}></i> {
                                    data.options.labels[3]}
                                </p>
                                <h6>{data.series[3]} $</h6>
                            </div>
                        </Col>*/}
                        <Col xl={5}>
                            <ReactApexChart options={statData.options} series={statData.series} type="line" height="270" />
                        </Col>
                        <Col xl={3}>
                            <ReactApexChart options={horizontalData.options} series={horizontalData.series} type="bar" height="270" />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default SalesAnalytics;
