import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {disableOverlay, enableOverlay, setToast} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import { createInvoiceSuccess, CREATE_INVOICE, DELETE_INVOICE, getSelectedAccountInvoicesSuccess, getSelectedInvoiceAccountSuccess,
  GET_SELECTED_ACCOUNT_INVOICES, GET_SELECTED_INVOICE_ACCOUNT, UPDATE_INVOICE
} from "../actions/admin.invoices.actions"
import * as invoicesApi from "../../services/admin.invoices.services"
import { ADMIN_INVOICES } from "../../navigation/ROUTECONSTANTS";
import { getAccount } from "../../services/accountsService";
import { getInvoices } from "../../services/invoicesService";

/**
 * createInvoiceEffect
 */
 function* createInvoiceEffect({payload}) {
  try {
      yield put(enableOverlay('Creating invoice...'));
      const invoice = yield call(invoicesApi.postInvoice, payload);
      if (invoice) {
          yield put(createInvoiceSuccess(invoice));
          yield put(disableOverlay());
          window.location.href = ADMIN_INVOICES;
      }
  } catch (err) {
      console.log('error:', err);
      yield put(genericError(err));
      yield put(disableOverlay());
  }
}

/**
 * deleteInvoiceEffect
 */
 function* deleteInvoiceEffect({payload}) {
  try {
      yield put(enableOverlay('Deleting invoice...'));
      yield call(invoicesApi.deleteInvoice, payload);
      yield put(disableOverlay());
      yield setToast({
          severity: 'success',
          summary: 'Invoice deleted',
          life: 5000
      })
      window.location.href = ADMIN_INVOICES;
  } catch (err) {
      console.log('error:', err);
      yield put(genericError(err));
      yield put(disableOverlay());
  }
}

/**
 * updateInvoiceEffect
 */
 function* updateInvoiceEffect({payload}) {
  try {
      yield put(enableOverlay('Updating invoice...'));
      yield call(invoicesApi.updateInvoice, payload);
      yield put(disableOverlay());
      yield setToast({
          severity: 'success',
          summary: 'Invoice updated',
          life: 5000
      })
      window.location.href = ADMIN_INVOICES;
  } catch (err) {
      console.log('error:', err);
      yield put(genericError(err));
      yield put(disableOverlay());
  }
}

/**
 * getSelectedInvoiceAccountEffect
 */
function* getSelectedInvoiceAccountEffect({ payload }) {
  try {
    yield put(enableOverlay(`Loading invoice account...`));
    const account = yield call(getAccount, payload);
    yield put(getSelectedInvoiceAccountSuccess(account));
    yield put(disableOverlay());
  } catch (err) {
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

/**
 * getSelectedAccountInvoicesEffect
 */
 function* getSelectedAccountInvoicesEffect({ payload }) {
  try {
    yield put(enableOverlay(`Loading invoice account...`));
    const invoices = yield call(getInvoices, {owner_id: payload, limit: 5, sortBy: 'invoiceId', order: 'desc'});
    yield put(getSelectedAccountInvoicesSuccess(invoices.results));
    yield put(disableOverlay());
  } catch (err) {
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

export function* watchCreateInvoice() {
  yield takeEvery(CREATE_INVOICE, createInvoiceEffect);
}

export function* watchDeleteInvoice() {
  yield takeEvery(DELETE_INVOICE, deleteInvoiceEffect);
}

export function* watchUpdateInvoice() {
  yield takeEvery(UPDATE_INVOICE, updateInvoiceEffect);
}

export function* watchGetSelectedInvoiceAccount() {
  yield takeEvery(GET_SELECTED_INVOICE_ACCOUNT, getSelectedInvoiceAccountEffect);
}

export function* watchGetSelectedAccountInvoices() {
  yield takeEvery(GET_SELECTED_ACCOUNT_INVOICES, getSelectedAccountInvoicesEffect);
}

function* invoicesSaga() {
  yield all([
    fork(watchCreateInvoice),
    fork(watchDeleteInvoice),
    fork(watchUpdateInvoice),
    fork(watchGetSelectedInvoiceAccount),
    fork(watchGetSelectedAccountInvoices),
  ]);
}

export default invoicesSaga;
