import React, { Component } from "react";
import { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppContext } from "./Provider"
import { getPackages } from "../../redux/actions/packages.actions";
import { Col, Row } from "reactstrap";
import Select from 'react-select';
import { updateQuoteRequest } from "../../redux/actions/quotes.actions";
import { updateShipmentRequest } from "../../redux/actions/shipments.actions";
import { removeCommercialInvoicePackages, updateCommercialInvoiceRequest } from "../../redux/actions/commercial.invoices.action";

const customStylesSelectedService = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '0px',
    backgroundColor: 'white',
    color: state.isSelected ? '#1F2D3D' : '#9ea3b0',
    cursor: 'pointer',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    borderRadius: '10px',
    fontSize: "14px !important",
    fontWeight: "500 !important",
    '&:hover': {
      backgroundColor: '#F6F7FB',
      fontWeight: 'bold',
      color: '#1F2D3D'
    }
  }),
  container: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    borderColor: '#D4DAE9',
    borderWidth: '1px',
    borderRadius: '10px'
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    paddingLeft: '10px',
  }),
  indicatorsContainer: () => ({
    backgroundColor: 'white',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: state.menuIsOpen ? 'inline' : 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: state.menuIsOpen ? 'none' : 'inline'
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    border: '1px solid #D4DAE9',
    boxShadow: 'none',
    padding: '0px',
    outline: 'none',
    borderRadius: '10px',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: '#D4DAE9',
      borderWidth: '1px',
      boxShadow: 'none',
    }
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0 : 1;
    const transition = 'opacity 300ms';
    const fontSize = "14px !important";
    const fontWeight = "600 !important";
    return { ...provided, opacity, transition, fontSize, fontWeight };
  },
  menuList: (base) => ({
    ...base,
    cursor: 'pointer',
    "::-webkit-scrollbar": {
      width: "9px"
    },
    "::-webkit-scrollbar-track": {
      background: "white"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#D4DAE9",
      borderRadius: '100px'
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#D4DAE9"
    }
  })
}

function MultiBox(props) {
  const [fields, setFields] = useState([{ value: null }]);
  const [stato, setStato] = useContext(AppContext);
  const dispatch = useDispatch();
  const quoteRequest = useSelector(state => state.quoteRequest);
  const shipmentRequest = useSelector(state => state.shipmentRequest);
  const packagesCommercialInvoice = useSelector(state => state.commercialInvoiceStore.packages);
  const packages = useSelector(state => state.packages);
  const [activePackages,setActivePackages] = useState([]);
  const { service } = props;

  useEffect(() => {
    dispatch(getPackages());
  }, []);

  useEffect(() => {
    var filteredPackages = []
    filteredPackages = packages.filter((element) => element.service_types.includes(parseInt(service))).map(packagingOption =>
      ({ label: packagingOption.name, value: packagingOption.id }));
      setActivePackages(filteredPackages);
      }, [packages, service]);

  function PackageSelected(selectedPackage, idx) {
    props.showPackagingDetail(true)
    const selectedPack = packages.find(e => e.id == parseInt(selectedPackage.value));
    const values = [...fields];
    values[values.length-1].value = selectedPack? selectedPack.name : '';
    setFields(values);
    setStato({ ...stato, selectedPack: selectedPack,indexPackage: idx, isPackselected: values.length === 0 ? false : true });
  }

  function handleAdd() {

    const values = [...fields];
    if (values.length <= 5) {
      values.push({ value: null });
      setFields(values);
      { setStato({ ...stato, boxes: values.length }) }

    }

    console.log('add: ', values.length)
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    if(quoteRequest.pieces[i] && shipmentRequest.packages[i]) {
      quoteRequest.pieces.splice(i, 1);
      shipmentRequest.packages.splice(i, 1);
      dispatch(updateQuoteRequest(quoteRequest));
      dispatch(updateShipmentRequest(shipmentRequest));
    }
    if(packagesCommercialInvoice[i]) {
      dispatch(removeCommercialInvoicePackages(i))
    }
    console.log('remove: ', values.length)
    { setStato({ ...stato, boxes: values.length }) }
  }

  return (

    <>
      {fields.map((field, idx) => {
        return (
          <Row className="ml-0 mt-2" key={`${field}-${idx}`}>
            <Col xs="6" sm="6" className="pl-0">
            <Select styles={customStylesSelectedService} isSearchable={false} onChange={selectedPackage=> PackageSelected(selectedPackage, idx)} options={activePackages} placeholder='Select Package' />
            </Col>
            {idx === 0 &&
              <Col xs="6" sm="6" className="mt-2 mr-0">
                <Row>
                  <Col xs="auto" sm="auto" lg="auto" className="mt-1 mr-1 pr-0">
                    <i className="bi bi-info-circle" style={{ left: "5px" }} ></i>
                  </Col>
                  { quoteRequest.courier != 'USPS'
                    ? <Col xs="11" sm="11" lg="11" className="ml-0 pl-0">
                      <a href="#" onClick={() => handleAdd()} className="item-sx ml-1"><u>Need to add another package to this shipment?</u></a>
                    </Col>
                    : ''
                  }
                </Row>
              </Col>
            }
            {idx > 0 &&
              <Col xs="6" sm="6" className="mt-2">
                <Row className="h-100">
                  <a href="#" className="fa fa-more" onClick={() => handleAdd()}></a>  <a href="#" onClick={() => handleRemove(idx)} className="fa fa-less"></a>
                </Row>
            </Col>
            }
          </Row>
        );
      })}
    </>
  );
}

export class SelectPackagingDetails extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <React.Fragment>
        <Row className="ml-1" >
          <Col className="col-12 mt-4 pl-0">
            <div className="h6 font-weight-bold">Packaging Details</div>
            <div className="item-sx mrg-tb20"> Re-use previous order history dimensions, or create new shipment dimensions</div>
          </Col>
        </Row>
        <MultiBox service={this.props.service} showPackagingDetail={this.props.showPackagingDetail} />
      </React.Fragment>
    );
  }
}

export default SelectPackagingDetails;
