import { GET_SIGNATURES_SUCCESS } from "../actions/signatures.actions";

const initialSignaturesState = [];

export const SignaturesReducer = (state = initialSignaturesState, action) => {
    switch (action.type) {
        case GET_SIGNATURES_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
}