import axios from "axios";
import {API_BASE_URL, SHIPMENTS, VOID, LABEL, SHIPMENTS_BASIC_STAT, API_URL_EMAIL_LABEL} from "./APICONSTANTS";

export const getShipments = (page, limit, filter, order, sortBy) => {
    let url = API_BASE_URL + SHIPMENTS + '?page=' + page.toString();
    url += limit ? '&pageSize=' + limit.toString() : '';
    url += filter && filter.length > 0 ? '&search=' + filter : '';
    if(order && sortBy) {
        if(order === 'asc') {
            url += '&ordering=+' + sortBy;
        } else  {
            url += '&ordering=-' + sortBy;
        }
    }
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.get(url)
                .then((res) => {
                    console.log("Shipments loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get shipments error: ", err);
                    reject("Get shipments error!");
                });
        } catch (error) {
            console.error("Get shipments error!==", error);
            reject("Get shipments error!");
        }
    });
};

export const getShipment = (id) => {
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.get(API_BASE_URL + SHIPMENTS + '/' + id)
                .then((res) => {
                    console.log("Shipment loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get shipment error: ", err);
                    reject("Get shipment error!");
                });
        } catch (error) {
            console.error("Get shipment error!==", error);
            reject("Get shipment error!");
        }
    });
};

export const postShipment = (shipment) => {
    return new Promise((resolve, reject) => {
        try {
            // do db call or API endpoint axios call here and return the promise.
            axios.post(API_BASE_URL + SHIPMENTS, shipment)
                .then((res) => {
                    console.log("Shipment added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post shipment error: ", err);
                    reject("signin error!");
                });
        } catch (error) {
            console.error("Post shipment error!==", error);
            reject("Post shipment error!");
        }
    });
};

export const voidShipment = (shipmentId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_BASE_URL + SHIPMENTS + '/' + shipmentId + VOID, {})
                .then((res) => {
                    console.log("Shipment voided: ", res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log("Void shipment error: ", err);
                    reject("Void shipment error!");
                });
        } catch (error) {
            console.error("Void shipment error!==", error);
            reject("Void shipment error!");
        }
    });
};

export const getShipmentLabel = (shipmentId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_BASE_URL + SHIPMENTS + '/' + shipmentId + LABEL, {})
                .then((res) => {
                    console.log("Shipment label loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get shipment label error: ", err);
                    reject("Get shipment label error!");
                });
        } catch (error) {
            console.error("Get shipment label error!==", error);
            reject("Get shipment label error!");
        }
    });
};


export const getBasicStat = (statCode) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_BASE_URL + SHIPMENTS + SHIPMENTS_BASIC_STAT + '/' + statCode, {})
                .then((res) => {
                    console.log(`Shipment basic stat ${statCode} loaded: `, res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get shipment basic stat error: ", err);
                    reject("Get shipment basic stat error!");
                });
        } catch (error) {
            console.error("Get shipment basic stat error!==", error);
            reject("Get shipment basic stat error!");
        }
    });
};

export const sendEmailLabel = ({recipients, subject, body, shipmentId}) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_BASE_URL + SHIPMENTS + '/' + shipmentId + API_URL_EMAIL_LABEL, {recipients, subject, body})
                .then((res) => {
                    console.log("Shipment label emailed: ", res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log("Shipment label email error: ", err);
                    reject("Shipment label email error!");
                });
        } catch (error) {
            console.error("Shipment label email error!==", error);
            reject("Shipment label email error!");
        }
    });
};