import {createAction} from "@reduxjs/toolkit";

// Shipments action types
export const GET_SHIPMENT = '[SHIPMENT] Getting current shipment';
export const GET_SHIPMENT_SUCCESS = '[SHIPMENT] Getting shipment with success!';
export const GET_SHIPMENTS = '[SHIPMENTS] Getting shipments';
export const GET_SHIPMENTS_SUCCESS = '[SHIPMENTS] Getting shipments success!';
export const CREATE_SHIPMENT = '[SHIPMENT] Creating new shipment...';
export const CREATE_SHIPMENT_SUCCESS = '[SHIPMENT] New shipment create with success!';
export const RESET_SHIPMENT_RESPONSE_STATE = '[SHIPMENT] Shipment response reset!';
export const RESET_SHIPMENT_STATE = '[SHIPMENT] Shipment state reset!';
export const SET_TRACKING_SIDEBAR_STATE = '[TRACKING] Set new sidebar state';
export const VOID_SHIPMENT_REQUEST = '[SHIPMENT] Voiding shipment';
export const VOID_SHIPMENT_REQUEST_SUCCESS = '[SHIPMENT] Shipment void with success!';
export const GET_SHIPMENT_LABEL = '[SHIPMENT] Getting shipment label';
// Shipment Request type
export const UPDATE_SHIPMENT_REQUEST = '[SHIPMENT] Shipment request updated ';
export const RESET_SHIPMENT_REQUEST_STATE = '[SHIPMENT] Shipment request reset!';
export const UPDATE_SHIPMENT_REQUEST_COURIER = '[SHIPMENT] Shipment request courier set';
// Shipment label types
export const GET_SHIPMENT_LABEL_SUCCESS = '[SHIPMENT] Getting shipment label with success! ';
export const RESET_SHIPMENT_LABEL_STATE = '[SHIPMENT] Shipment label reset!';
export const SEND_SHIPMENT_LABEL_EMAIL = '[SHIPMENT] Sending shipment label mail';

// Shipments actions
export const getShipments = createAction(GET_SHIPMENTS);
export const getShipmentsSuccess = createAction(GET_SHIPMENTS_SUCCESS);
export const getShipment = createAction(GET_SHIPMENT);
export const getShipmentSuccess = createAction(GET_SHIPMENT_SUCCESS);
export const createShipmentSuccess = createAction(CREATE_SHIPMENT_SUCCESS);
export const resetShipmentResponseState = createAction(RESET_SHIPMENT_RESPONSE_STATE);
export const resetShipmentState = createAction(RESET_SHIPMENT_STATE);
export const getShipmentLabelSuccess = createAction(GET_SHIPMENT_LABEL_SUCCESS);
export const resetShipmentLabel = createAction(RESET_SHIPMENT_LABEL_STATE);
export const sendShipmentLabelEmail = createAction(SEND_SHIPMENT_LABEL_EMAIL);

export const createShipment = (shipmentRequest) => ({
    type: CREATE_SHIPMENT,
    payload: shipmentRequest
});

// Shipment Request actions
export const resetShipmentRequestState = createAction(RESET_SHIPMENT_REQUEST_STATE);
export const updateShipmentRequest = (shipmentRequest) => ({
    type: UPDATE_SHIPMENT_REQUEST,
    payload: shipmentRequest
})

export const updateCourierShipmentRequest = (courier) => ({
    type: UPDATE_SHIPMENT_REQUEST_COURIER,
    payload: courier
})


export const setTrackingSidebarState = (data) => ({
    type: SET_TRACKING_SIDEBAR_STATE,
    payload: data
});

export const voidShipmentRequest = (shipmentId, history) => ({
    type: VOID_SHIPMENT_REQUEST,
    payload: {shipmentId: shipmentId, history: history}
});

export const getShipmentLabel = (shipmentId) => ({
    type: GET_SHIPMENT_LABEL,
    payload: {shipmentId}
});
