import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { saveAs } from 'file-saver'
import { disableOverlay, enableOverlay } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import { GET_INVOICES, DOWNLOAD_INVOICE_PDF, getInvoicesSuccess } from "../actions/invoices.actions"
import * as invoicesApi from "../../services/invoicesService"


function* getInvoicesEffect({ payload }) {
  try {
    yield put(enableOverlay(`Loading invoices...`));
    const invoicesList = yield call(invoicesApi.getInvoices, payload);
    yield put(getInvoicesSuccess(invoicesList));
    yield put(disableOverlay());
  } catch (err) {
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

function* downloadPdfInvoice({ payload }) {
  const { url, invoiceId } = payload
  saveAs(url, `invoice-${invoiceId}.pdf`);
}

export function* watchGetInoives() {
  yield takeEvery(GET_INVOICES, getInvoicesEffect);
}

export function* watchDownloadPdfInvoice() {
  yield takeEvery(DOWNLOAD_INVOICE_PDF, downloadPdfInvoice);
}

function* invoicesSaga() {
  yield all([
    fork(watchGetInoives),
    fork(watchDownloadPdfInvoice),
  ]);
}

export default invoicesSaga;
