import {createAction} from "@reduxjs/toolkit";

// Quotes action types
export const GET_QUOTES = '[QUOTES] Getting quotes';
export const GET_QUOTES_SUCCESS = '[QUOTES] Getting quotes success!';
export const CREATE_QUOTE = '[QUOTE] Creating new quote...';
export const CREATE_QUOTE_SUCCESS = '[QUOTE] New quote create with success!';
export const RESET_QUOTE = '[QUOTE] Quote reset!';

// Quotes actions
export const getQuotes = createAction(GET_QUOTES);
export const getQuotesSuccess = createAction(GET_QUOTES_SUCCESS);
export const createQuoteSuccess = createAction(CREATE_QUOTE_SUCCESS);
export const resetQuote = createAction(RESET_QUOTE);

export const createQuote = (quoteRequest) => ({
    type: CREATE_QUOTE,
    payload: quoteRequest
});

//Quote Request type
export const UPDATE_QUOTE_REQUEST = '[QUOTE] Quote request updated ';
export const UPDATE_QUOTE_REQUEST_COURIER = '[QUOTE] Quote request courier set';
export const RESET_QUOTE_REQUEST_STATE = '[QUOTE] Quote request reset!';
// Quote Request actions

export const resetQuoteRequestState = createAction(RESET_QUOTE_REQUEST_STATE);
export const updateQuoteRequest = (quoteRequest) => ({
    type: UPDATE_QUOTE_REQUEST,
    payload: quoteRequest
})

export const updateCourierQuoteRequest = (courier) => ({
    type: UPDATE_QUOTE_REQUEST_COURIER,
    payload: courier
})
