import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import arrowLeft from "../../../assets/images/arrow.alt.left.png";
import arrowRight from "../../../assets/images/arrow.alt.right.png";
import { setAdminInvoicesState, deleteInvoice} from "../../../redux/actions/admin.invoices.actions";
import { getInvoices } from "../../../redux/actions/invoices.actions";
import { currencyFormatter } from "../../../utils";
import {Button} from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

const ActionsColumn = ({ rowData, dispatch, setDeleteInvoiceDialog, setActionInvoiceId, onEditInvoice}) => {
  const openPdfModalInvoice = (e)=>{
    e.stopPropagation()
    dispatch(setAdminInvoicesState({
      pdfInvoiceModal: {
        open: true,
        invoice: {
          ...rowData,
          // TODO set the proper status
          resend: rowData.status == 'CREATED' ? true : false,
        }
      }
    }))
  }

  const openAccountManagement = (e)=>{
    e.stopPropagation()
    dispatch(setAdminInvoicesState({
      openAccountManagement: {
        open: true,
        selectedInvoice: rowData,
      }
    }))
  }

  const confirmDeleteSelected = (e) => {
    e.stopPropagation();
    setActionInvoiceId(rowData.invoiceId);
    setDeleteInvoiceDialog(true);
  }

  return (
    <div className="files-download">
      <Button className="p-button-secondary p-button-text" title="Send Invoice" icon="pi pi-file" onClick={(e) => openPdfModalInvoice(e)} />
      <Button className="p-button-secondary p-button-text" title="Account Management" icon="pi pi-user" onClick={(e) => openAccountManagement(e)} />
      <Button className="p-button-secondary p-button-text" title="Delete invoice" icon="pi pi-trash" onClick={confirmDeleteSelected} />
      <Button className="p-button-secondary p-button-text" title="Edit invoice" icon="pi pi-pencil" onClick={(e) => onEditInvoice(e, rowData)} />
    </div>
  );
};

const InvoicesBody = (props) => {
  const dispatch = useDispatch();
  const { invoicesList, totalRecords = 0, newEditInvoiceModal = {} } = useSelector((state) => ({
    invoicesList: state.invoices.invoicesList,
    totalRecords: state.invoices.count,
    newEditInvoiceModal: state.adminInvoices.newEditInvoiceModal,
  }));
  const openSideBar = useSelector((state) => state.adminInvoices.openSideBar);
  const { autocompleteSelectedId } = useSelector((state) => ({autocompleteSelectedId: state.accounts.autocompleteSelectedId}))
  const [actionInvoiceId, setActionInvoiceId] = useState(null);
  const [deleteInvoiceDialog, setDeleteInvoiceDialog] = useState(false);

  const onEditInvoice = (e, invoiceData) => {
    e.stopPropagation();
    dispatch(setAdminInvoicesState({
      newEditInvoiceModal: {
        open: true,
        invoice: invoiceData,
      }
    }));
  };

  const rowsPerPage = 10;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rowsPerPage,
    page: 0,
    sortField: 'invoiceId',
    sortOrder: -1,
    filters: {},
    selectedAccountId: null,
  });

  useEffect(() => {
      loadLazyData();
  }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    dispatch(getInvoices({
      'page': lazyParams.page + 1,
      'limit': lazyParams.rows,
      'sortBy': lazyParams.sortField,
      'order': lazyParams.sortOrder < 0 ? 'desc' : 'asc',
      'filter': lazyParams.selectedAccountId,
    }));
  }

  const onPage = (event) => {
    setLazyParams({...lazyParams, ...event});
  }

  const onSort = (event) => {
    setLazyParams({...lazyParams, ...event});
  }

  useEffect(() => {
    setLazyParams({
      ...lazyParams,
      selectedAccountId: autocompleteSelectedId,
    });
  }, [autocompleteSelectedId])

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams({...lazyParams, ...event});
  }

  const formatCurrency = currencyFormatter(2);

  const selectInvoice = (selectionChangeData) => {
    dispatch(
      setAdminInvoicesState({
        openSideBar: {
          open: true,
          selectedInvoice: selectionChangeData.rowData,
        },
      })
    );
  };

  const hideDeleteInvoiceDialog = () => {
    setDeleteInvoiceDialog(false);
  }

  const onDeleteInvoice = () => {
    setDeleteInvoiceDialog(false);
    dispatch(deleteInvoice(actionInvoiceId));
  }

  const template1 = {
    layout: "PrevPageLink CurrentPageReport NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img src={arrowLeft} alt="arrowLeft" />
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img src={arrowRight} alt="arrowRight" />
          <Ripple />
        </button>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          className="p-mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          <span className="page-number-button">{options.currentPage}</span>
          <span className="page-of-button"> of </span>
          <span className="page-number-button">{options.totalPages}</span>
        </span>
      );
    },
  };

  const paginatorLeft = () => {
    return (
      <div className="account-paid">
        Accounts Paid: <b>{props.paidAmount}</b>
      </div>
    )
  }

  return (
    <Fragment>
    <div className="invoices-body">
      <DataTable
        paginator
        paginatorPosition={'top'}
        paginatorTemplate={template1}
        paginatorLeft={paginatorLeft}
        rows={rowsPerPage}
        onPage={onPage}
        onSort={onSort}
        lazy filterDisplay="row"
        first={lazyParams.first}
        totalRecords={totalRecords}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        value={invoicesList}
        removableSort
        className="p-datatable-striped"
        selectionMode="single"
        onSelectionChange={(e)=> selectInvoice(e.value)}
        cellSelection
        dataKey="invoiceId"
        rowClassName={(rowData) => ({"selected-row" : (
          'selectedInvoice' in openSideBar && rowData.invoiceId === openSideBar.selectedInvoice.invoiceId)
        })}
      >
        <Column field="customerName" header="Customer"></Column>
        <Column field="company" header="Company"></Column>
        <Column
          className="invoiceId"
          field="invoiceId"
          header="Invoice #"
          sortable
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="invoice_date"
          header="Invoice Date"
          body={(rowData) => new Date(rowData.invoice_date).toLocaleDateString()}
          headerClassName="text-right"
          bodyClassName="text-right"
          sortable
        ></Column>
        <Column
          field="total"
          header="Total Amount"
          sortable
          body={(rowData) => formatCurrency.format(rowData.total)}
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="balance"
          header="Balance"
          body={(rowData) => formatCurrency.format(rowData.balance)}
          headerClassName="text-right"
          bodyClassName="text-right"
        ></Column>
        <Column
          field="actions"
          header="Actions"
          body={(rowData) =>
              <ActionsColumn rowData={rowData} dispatch={dispatch} setDeleteInvoiceDialog={setDeleteInvoiceDialog}
                setActionInvoiceId={setActionInvoiceId} onEditInvoice={onEditInvoice} />
          }
        ></Column>
      </DataTable>
    </div>

    <ConfirmDialog visible={deleteInvoiceDialog}
        onHide={() => setDeleteInvoiceDialog(false)}
        className='delete-confirm-dialog'
        message={() => <p>Are you sure you want to delete this invoice?<br/><b>This action cannot be undone</b></p>}
        header="Delete Invoice"
        acceptLabel="Delete Invoice"
        rejectLabel="Cancel"
        accept={onDeleteInvoice} reject={hideDeleteInvoiceDialog} closable={false} />
    </Fragment>
  );
};

export default InvoicesBody;
