export const ServiceCarrierFedex = [
    {
        name: 'Priority Overnight',
        abbreviation: 'PI'
    },
    {
        name:  'First Overnight',
        abbreviation: 'FI'
    },
    {
        name:  'Standard Overnight',
        abbreviation: 'SO'
    },
    {
        name:  '2 Day',
        abbreviation: '2D'
    },
    {
        name:  '2 Day A.M',
        abbreviation: '2DAM'
    },
    {
        name:  'Express Saver',
        abbreviation: 'EXS'
    }

];


export const ServiceCarrierUsps = [
    {
        "id": 33,
        "name": "Economy (2-8 days)",
    },
    {
        "id": 34,
        "name": "Expedited (1-3 days)",
    },
    {
        "id": 35,
        "name": "International Canada (4-6 days)",
    },
    {
        "id": 36,
        "name": "International Europe (4-7 days )",
    }
];

export const ServiceCouriersByID = [
    {
        "id": 10,
        "courier": "FEDEX",
        "name": "FedEx Priority Overnight®",
        "active": true,
        "service_packages": [
            {
                "id": 1,
                "name": "FedEx® Small Box",
                "active": true
            },
            {
                "id": 2,
                "name": "FedEx® Medium Box",
                "active": true
            },
            {
                "id": 3,
                "name": "FedEx® Large Box",
                "active": true
            },
            {
                "id": 4,
                "name": "FedEx® Envelope",
                "active": true
            },
            {
                "id": 5,
                "name": "FedEx® Tube",
                "active": true
            },
            {
                "id": 6,
                "name": "FedEx® Small Pak",
                "active": true
            },
            {
                "id": 7,
                "name": "FedEx® Large Pak",
                "active": true
            },
            {
                "id": 8,
                "name": "FedEx® Pak",
                "active": true
            },
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 11,
        "courier": "FEDEX",
        "name": "FedEx First Overnight®",
        "active": true,
        "service_packages": [
            {
                "id": 1,
                "name": "FedEx® Small Box",
                "active": true
            },
            {
                "id": 2,
                "name": "FedEx® Medium Box",
                "active": true
            },
            {
                "id": 3,
                "name": "FedEx® Large Box",
                "active": true
            },
            {
                "id": 4,
                "name": "FedEx® Envelope",
                "active": true
            },
            {
                "id": 5,
                "name": "FedEx® Tube",
                "active": true
            },
            {
                "id": 6,
                "name": "FedEx® Small Pak",
                "active": true
            },
            {
                "id": 7,
                "name": "FedEx® Large Pak",
                "active": true
            },
            {
                "id": 8,
                "name": "FedEx® Pak",
                "active": true
            },
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 12,
        "courier": "FEDEX",
        "name": "FedEx Standard Overnight®",
        "active": true,
        "service_packages": [
            {
                "id": 1,
                "name": "FedEx® Small Box",
                "active": true
            },
            {
                "id": 2,
                "name": "FedEx® Medium Box",
                "active": true
            },
            {
                "id": 3,
                "name": "FedEx® Large Box",
                "active": true
            },
            {
                "id": 4,
                "name": "FedEx® Envelope",
                "active": true
            },
            {
                "id": 5,
                "name": "FedEx® Tube",
                "active": true
            },
            {
                "id": 6,
                "name": "FedEx® Small Pak",
                "active": true
            },
            {
                "id": 7,
                "name": "FedEx® Large Pak",
                "active": true
            },
            {
                "id": 8,
                "name": "FedEx® Pak",
                "active": true
            },
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 13,
        "courier": "FEDEX",
        "name": "FedEx 2Day®",
        "active": true,
        "service_packages": [
            {
                "id": 1,
                "name": "FedEx® Small Box",
                "active": true
            },
            {
                "id": 2,
                "name": "FedEx® Medium Box",
                "active": true
            },
            {
                "id": 3,
                "name": "FedEx® Large Box",
                "active": true
            },
            {
                "id": 4,
                "name": "FedEx® Envelope",
                "active": true
            },
            {
                "id": 5,
                "name": "FedEx® Tube",
                "active": true
            },
            {
                "id": 6,
                "name": "FedEx® Small Pak",
                "active": true
            },
            {
                "id": 7,
                "name": "FedEx® Large Pak",
                "active": true
            },
            {
                "id": 8,
                "name": "FedEx® Pak",
                "active": true
            },
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 14,
        "courier": "FEDEX",
        "name": "FedEx 2Day® A.M.",
        "active": true,
        "service_packages": [
            {
                "id": 1,
                "name": "FedEx® Small Box",
                "active": true
            },
            {
                "id": 2,
                "name": "FedEx® Medium Box",
                "active": true
            },
            {
                "id": 3,
                "name": "FedEx® Large Box",
                "active": true
            },
            {
                "id": 4,
                "name": "FedEx® Envelope",
                "active": true
            },
            {
                "id": 5,
                "name": "FedEx® Tube",
                "active": true
            },
            {
                "id": 6,
                "name": "FedEx® Small Pak",
                "active": true
            },
            {
                "id": 7,
                "name": "FedEx® Large Pak",
                "active": true
            },
            {
                "id": 8,
                "name": "FedEx® Pak",
                "active": true
            },
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 15,
        "courier": "FEDEX",
        "name": "FedEx Express Saver®",
        "active": true,
        "service_packages": [
            {
                "id": 1,
                "name": "FedEx® Small Box",
                "active": true
            },
            {
                "id": 2,
                "name": "FedEx® Medium Box",
                "active": true
            },
            {
                "id": 3,
                "name": "FedEx® Large Box",
                "active": true
            },
            {
                "id": 4,
                "name": "FedEx® Envelope",
                "active": true
            },
            {
                "id": 5,
                "name": "FedEx® Tube",
                "active": true
            },
            {
                "id": 6,
                "name": "FedEx® Small Pak",
                "active": true
            },
            {
                "id": 7,
                "name": "FedEx® Large Pak",
                "active": true
            },
            {
                "id": 8,
                "name": "FedEx® Pak",
                "active": true
            },
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 16,
        "courier": "FEDEX",
        "name": "FedEx Ground®",
        "active": true,
        "service_packages": [
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 17,
        "courier": "FEDEX",
        "name": "FedEx Home Delivery®",
        "active": true,
        "service_packages": [
            {
                "id": 9,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 18,
        "courier": "UPS",
        "name": "UPS Next Day Air®",
        "active": true,
        "service_packages": [
            {
                "id": 10,
                "name": "UPS Express Box",
                "active": true
            },
            {
                "id": 11,
                "name": "UPS Letter",
                "active": true
            },
            {
                "id": 12,
                "name": "UPS Pak",
                "active": true
            },
            {
                "id": 13,
                "name": "UPS Tube",
                "active": true
            },
            {
                "id": 14,
                "name": "UPS Small Box",
                "active": true
            },
            {
                "id": 15,
                "name": "UPS Medium Box",
                "active": true
            },
            {
                "id": 16,
                "name": "UPS Large Box",
                "active": true
            },
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 19,
        "courier": "UPS",
        "name": "UPS 2nd Day Air®",
        "active": true,
        "service_packages": [
            {
                "id": 10,
                "name": "UPS Express Box",
                "active": true
            },
            {
                "id": 11,
                "name": "UPS Letter",
                "active": true
            },
            {
                "id": 12,
                "name": "UPS Pak",
                "active": true
            },
            {
                "id": 13,
                "name": "UPS Tube",
                "active": true
            },
            {
                "id": 14,
                "name": "UPS Small Box",
                "active": true
            },
            {
                "id": 15,
                "name": "UPS Medium Box",
                "active": true
            },
            {
                "id": 16,
                "name": "UPS Large Box",
                "active": true
            },
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 20,
        "courier": "UPS",
        "name": "UPS® Ground",
        "active": true,
        "service_packages": [
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 21,
        "courier": "UPS",
        "name": "UPS Next Day Air® Early",
        "active": true,
        "service_packages": [
            {
                "id": 10,
                "name": "UPS Express Box",
                "active": true
            },
            {
                "id": 11,
                "name": "UPS Letter",
                "active": true
            },
            {
                "id": 12,
                "name": "UPS Pak",
                "active": true
            },
            {
                "id": 13,
                "name": "UPS Tube",
                "active": true
            },
            {
                "id": 14,
                "name": "UPS Small Box",
                "active": true
            },
            {
                "id": 15,
                "name": "UPS Medium Box",
                "active": true
            },
            {
                "id": 16,
                "name": "UPS Large Box",
                "active": true
            },
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 22,
        "courier": "UPS",
        "name": "UPS Next Day Air Saver®",
        "active": true,
        "service_packages": [
            {
                "id": 10,
                "name": "UPS Express Box",
                "active": true
            },
            {
                "id": 11,
                "name": "UPS Letter",
                "active": true
            },
            {
                "id": 12,
                "name": "UPS Pak",
                "active": true
            },
            {
                "id": 13,
                "name": "UPS Tube",
                "active": true
            },
            {
                "id": 14,
                "name": "UPS Small Box",
                "active": true
            },
            {
                "id": 15,
                "name": "UPS Medium Box",
                "active": true
            },
            {
                "id": 16,
                "name": "UPS Large Box",
                "active": true
            },
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 23,
        "courier": "UPS",
        "name": "UPS 2nd Day Air A.M.®",
        "active": true,
        "service_packages": [
            {
                "id": 10,
                "name": "UPS Express Box",
                "active": true
            },
            {
                "id": 11,
                "name": "UPS Letter",
                "active": true
            },
            {
                "id": 12,
                "name": "UPS Pak",
                "active": true
            },
            {
                "id": 13,
                "name": "UPS Tube",
                "active": true
            },
            {
                "id": 14,
                "name": "UPS Small Box",
                "active": true
            },
            {
                "id": 15,
                "name": "UPS Medium Box",
                "active": true
            },
            {
                "id": 16,
                "name": "UPS Large Box",
                "active": true
            },
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 24,
        "courier": "UPS",
        "name": "UPS 3 Day Select®",
        "active": true,
        "service_packages": [
            {
                "id": 10,
                "name": "UPS Express Box",
                "active": true
            },
            {
                "id": 11,
                "name": "UPS Letter",
                "active": true
            },
            {
                "id": 12,
                "name": "UPS Pak",
                "active": true
            },
            {
                "id": 13,
                "name": "UPS Tube",
                "active": true
            },
            {
                "id": 14,
                "name": "UPS Small Box",
                "active": true
            },
            {
                "id": 15,
                "name": "UPS Medium Box",
                "active": true
            },
            {
                "id": 16,
                "name": "UPS Large Box",
                "active": true
            },
            {
                "id": 17,
                "name": "Customer Supplied Package",
                "active": true
            }
        ]
    },
    {
        "id": 25,
        "courier": "USPS",
        "name": "Priority Mail Express (2 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 26,
        "courier": "USPS",
        "name": "Priority Mail (1-3 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 27,
        "courier": "USPS",
        "name": "Priority Mail Int. (6-10 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 28,
        "courier": "USPS",
        "name": "Priority Mail Int.Exp. (3-5)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 29,
        "courier": "USPS",
        "name": "First-Class Mail (1-5 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 30,
        "courier": "USPS",
        "name": "First-Class Package (1-3 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 31,
        "courier": "USPS",
        "name": "USPS Retail Ground (2-8 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 32,
        "courier": "USPS",
        "name": "Media Mail (2-8 Days)",
        "active": false,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 33,
        "courier": "USPS",
        "name": "Economy (2-8 days)",
        "active": true,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 34,
        "courier": "USPS",
        "name": "Expedited (1-3 days)",
        "active": true,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 35,
        "courier": "USPS",
        "name": "International Canada (4-6 days)",
        "active": true,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    },
    {
        "id": 36,
        "courier": "USPS",
        "name": "International Europe (4-7 days )",
        "active": true,
        "service_packages": [
            {
                "id": 18,
                "name": "Your Packaging",
                "active": true
            }
        ]
    }
]
