import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    GET_ACCOUNTS,
    getAccountsSuccess,
    GET_AUTOCOMPLETE_LIST,
    getAutocompleteListSuccess,
    UPDATE_ACCOUNT
} from "../actions/accounts.actions";
import {disableOverlay, enableOverlay} from "../actions/ui.actions";
import {genericError} from "../actions/error.actions";
import {getAccounts, updateAccount} from "../../services/accountsService";

/**
 * getAccountsEffect
 */
function* getAccountsEffect({payload}) {
    try {
        yield put(enableOverlay('Loading accounts...'));
        const accountsList = yield call(getAccounts, payload.page, payload.limit, payload.filter, payload.order, payload.sort);
        yield put(getAccountsSuccess(accountsList));
        yield put(disableOverlay());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * getAutocompleteListEffect
 */
 function* getAutocompleteListEffect({payload}) {
    try {
        const accountsList = yield call(getAccounts, payload.page, payload.limit, payload.filter, payload.order, payload.sort);
        yield put(getAutocompleteListSuccess(accountsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * updateAccountEffect
 */
 function* updateAccountEffect({payload}) {
    try {
        const account = yield call(updateAccount, payload);
        // yield put(updateContactSuccess(account));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

export function* watchGetAccounts() {
    yield takeEvery(GET_ACCOUNTS, getAccountsEffect);
}

export function* watchGetAutocompleteList() {
    yield takeEvery(GET_AUTOCOMPLETE_LIST, getAutocompleteListEffect);
}

export function* watchUpdateAccount() {
    yield takeEvery(UPDATE_ACCOUNT, updateAccountEffect);
}

function* accountsSaga() {
    yield all([
        fork(watchGetAccounts),
        fork(watchGetAutocompleteList),
        fork(watchUpdateAccount),
    ]);
}

export default accountsSaga;
