import React, {useContext, useState} from 'react';
import {AppContext} from '../shipment/Provider';
import {Chips} from 'primereact/chips';
import {Checkbox} from 'primereact/checkbox';


export const ShippingUpdates = (props) => {
    const [valuesEmails, setValuesEmails] = useState([]);
    const [stato, setStato] = useContext(AppContext);
    const [checkedSD, setCheckedSD] = useState(false);
    const [checkedCr, setCheckedCR] = useState(false);
    const [checkedRmail, setCheckedEmail] = useState(false);
    const [checkedRmail2, setCheckedEmail2] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [message, setMessage] = useState('');

    const emailRegex = /\S+@\S+\.\S+/;

    const validateEmail = (event) => {
        const email = event.target.value;
        if (emailRegex.test(email)) {
            setIsValid(true);
            setValuesEmails(email)
            console.log('email. ok');
            //   setMessage('Your email looks good!');
        } else {
            setIsValid(false);
            //   setMessage('Please enter a valid email!');
            console.log('email. false');
        }
    };


    return (
        <>
            <div className="mt-5">
                <div className="mt-4 h4 font-weight-bold col-sm row">Shipping updates</div>
                <form className="mt-4 gray">
                    <div className="row mt-2">
                        <div className="col-sm">
                            <label htmlFor="sDnotification" className="gray"> Send
                                ShipDistrict notification</label>
                        </div>
                        <div className="col-sm justify-content-end">
                            <Checkbox inputId="sDnotificationSD" value="SDnotification"
                                      className="float-right"
                                      onChange={e => setCheckedSD(e.checked)} checked={checkedSD}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm">
                            <label htmlFor="sDnotification" className="gray"> Send Fedex ®
                                notification</label>
                        </div>
                        <div className="col-sm justify-content-end">
                            <Checkbox inputId="sDnotificationCr" value="CRnotification"
                                      className="float-right"
                                      onChange={e => setCheckedCR(e.checked)} checked={checkedCr}/>
                        </div>

                    </div>
                    <div className="row mt-2">
                        <div className="col-sm">
                            <label htmlFor="sDnotification" className="gray"> Send
                                notification email to jay.whitley@me.com</label>
                        </div>
                        <div className="col-sm justify-content-end">
                            <Checkbox inputId="sDnotificationEmail" value="EMAILnotification"
                                      className="float-right"
                                      onChange={e => setCheckedEmail(e.checked)} checked={checkedRmail}/>
                        </div>

                    </div>
                    <div className="row mt-2">
                        <div className="col-sm">
                            <label htmlFor="sDnotification" className="gray"> Send
                                notification email to chris.wulf@wkgsolutions.com</label>
                        </div>
                        <div className="col-sm justify-content-end">
                            <Checkbox inputId="sDnotificationEmail2" value="EMAIL2notification"
                                      className="float-right"
                                      onChange={e => setCheckedEmail2(e.checked)} checked={checkedRmail2}/>
                        </div>
                    </div>
                    <hr></hr>
                    <label htmlFor="inputMessage">Email</label>
                    <div className="card p-fluid ">
                        <Chips value={valuesEmails} onChange={validateEmail} max={3}/>
                        <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-12">
                            <label htmlFor="inputMessage"> Message</label>
                            <textarea className="form-control" defaultValue="Hi Chris , your package has arrived!"
                                      id="inputMessage" placeholder="Message" rows="3"></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <button className="ship-secondary-custom-button w-100" type="button"
                                    onClick={props.cancel}>Cancel
                            </button>
                        </div>
                        <div className="col-sm">
                            <button className="ship-primary-custom-button w-100">Save Changes</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );

}
export default ShippingUpdates;

