export const formatCurrency = (value) => {
  if (!value) {
    value = 0;
  }
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const convertlengthToInches = (measure) => {
  return roundToTwoDecimal((measure/2.54))
}

export const convertlengthToCm = (measure) => {
  return roundToTwoDecimal((measure*2.54))
}

export const convertWeightToLbs = (measure) => {
  return roundToTwoDecimal((measure / 2.205))
}

export const convertWeightToKg = (measure) => {
  return roundToTwoDecimal((measure * 2.205))
}

export function roundToTwoDecimal(num) {
  return +(Math.round(num + "e+2")  + "e-2");
}

export const phoneRegExp = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const domesticDIMShipmentFactor = 166;