import {createAction} from "@reduxjs/toolkit";

// Couriers action types
export const GET_LIST_COURIERS = '[COURIERS] Getting list couriers';
export const GET_LIST_COURIERS_SUCCESS = '[COURIERS] Getting couriers success!';
export const UPDATE_COURIER = '[COURIER] Updating courier';

// Couriers actions
export const getCouriers = createAction(GET_LIST_COURIERS);
export const getCouriersSuccess = createAction(GET_LIST_COURIERS_SUCCESS);

export const updateCourier = (courierId, bodyRequest) => ({
    type: UPDATE_COURIER,
    id: courierId,
    bodyRequest: bodyRequest
});

