import axios from "axios";
import {API_BASE_URL, COURIERS} from "./APICONSTANTS";

export const getCouriers = () => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('sd-access');
            // do db call or API endpoint axios call here and return the promise.
            axios.get(API_BASE_URL + COURIERS, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((res) => {
                    console.log("Couriers loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get couriers error: ", err);
                    reject("Get couriers error!");
                });
        } catch (error) {
            console.error("Get couriers error!==", error);
            reject("Get couriers error!");
        }
    });
};

export const updateCourier = (courierId, courierActivationState) => {
    console.log("SS:: PrivateRoute > useProviderAuth > signin() called...");
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('sd-access');
            // do db call or API endpoint axios call here and return the promise.
            axios.put(API_BASE_URL + COURIERS + '/' + courierId, courierActivationState, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((res) => {
                    console.log("Courier updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Put courier error: ", err);
                    reject("Put courier error!");
                });
        } catch (error) {
            console.error("Put courier error!==", error);
            reject("Put courier error!");
        }
    });
};