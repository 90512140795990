import {FormGroup, UncontrolledTooltip} from 'reactstrap';
import {Control, Errors, Form} from 'react-redux-form';
import '../../App.scss';
import '../../styles/OnBoarding.css';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useSelector} from "react-redux";


export const ConfirmResetPasswordComponent = (props) => {

    let passwordChecked;

    const maxLength = (len) => (val) => !(val) || (val.length <= len);
    const minLength = (len) => (val) => val && (val.length >= len);

    const passwordIsValid = useSelector(state => state.forms.resetPasswordConfirmation.new_password.valid);
    const confirmPasswordIsValid = useSelector(state => state.forms.resetPasswordConfirmation.confirm_new_password.valid)
    const passwordField = useSelector(state => state.forms.resetPasswordConfirmation.new_password.value);
    const confirmPasswordField = useSelector(state => state.forms.resetPasswordConfirmation.confirm_new_password.value);

    const onSubmit = (data, event) => {
        event.preventDefault();
        props.onSubmit(data, event);
    }

    passwordChecked = passwordField === confirmPasswordField;

    return (
        <div className="mt-5">
            <Form className="mt-5 justify-content-center" model="resetPasswordConfirmation"
                  onSubmit={(formModelData, event) => onSubmit(formModelData, event)}>
                <FormGroup className="row justify-content-center">
                    <Control.input model=".new_password" id="new_password" name="new_password"
                                   placeholder="New password"
                                   className="form-control ship-input custom-input-margin"
                                   type="password"
                                   validators={{
                                       minLength: minLength(8),
                                       maxLength: maxLength(128)
                                   }}
                    />
                    <span>
                        <InfoOutlinedIcon fontSize="small" id="newPasswordTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="newPasswordTooltip">
                        Please enter new password for account
                    </UncontrolledTooltip>
                    <Errors
                        className="ship-input-error text-center"
                        model=".new_password"
                        show="touched"
                        messages={{
                            minLength: 'Password must be greater than 8 characters',
                            maxLength: 'Password be 150 characters or less'
                        }}
                    />
                </FormGroup>
                <FormGroup className="row justify-content-center">
                    <Control.input model=".confirm_new_password" id="confirm_new_password" name="confirm_new_password"
                                   placeholder="Confirm new password"
                                   className="form-control ship-input custom-input-margin"
                                   type="password"
                                   validators={{
                                       minLength: minLength(8),
                                       maxLength: maxLength(128)
                                   }}
                    />
                    <span className="cursor-pointer">
                        <InfoOutlinedIcon fontSize="small" id="newPasswordConfirmTooltip" className="info-icon"/>
                    </span>
                    <UncontrolledTooltip placement="top" target="newPasswordConfirmTooltip">
                        Reinsert the password. Must equals to previous one
                    </UncontrolledTooltip>
                    <Errors
                        className="ship-input-error text-center"
                        model=".confirm_new_password"
                        show="touched"
                        messages={{
                            minLength: 'Password must be greater than 8 characters',
                            maxLength: 'Password be 150 characters or less'
                        }}
                    />
                </FormGroup>
                <div className="row justify-content-center">
                    <button className="w-100 ship-primary-custom-button"
                            disabled={!passwordIsValid || !confirmPasswordIsValid || !passwordChecked}
                            type="submit"
                            style={{maxWidth: "333px"}}>
                        Save
                    </button>
                </div>
            </Form>
        </div>

    )
}


export default ConfirmResetPasswordComponent;
